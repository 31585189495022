import queryString from 'query-string';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { APP_ANALYTICS_URL } from 'utils/constants';
import { getDefaultHeaders, getFormattedDateTime, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { selectQuery } from './selector';
import { actions } from './slice';

export function* getTotalNumberOfUsers() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/dashboard/total-users-stats?${queries}`,
      options,
    );
    if (response) {
      console.log(response, 'data', 'mobile response');
      yield put(
        actions.setTotalNumberOfUsers(response),
      );
    } else {
      actions.toggleSnackbar({
        open: true,
        message: 'Unable to fetch stats',
        variant: 'error',
      });
    }
  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getAvgTimeStats() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/dashboard/avg-time-stats?${queries}`,
      options,
    );
    if (response) {
      console.log(response, 'data', 'mobile response');
      yield put(
        actions.setDashboardGraph({ key: 'avgTimeStats', value: response}),
      );
    } else {
      actions.toggleSnackbar({
        open: true,
        message: 'Unable to fetch stats',
        variant: 'error',
      });
    }
  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getCTAStats() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/dashboard/cta-stats?${queries}`,
      options,
    );
    if (response) {
      console.log(response, 'data', 'mobile response');
      yield put(
        actions.setDashboardGraph({ key: 'ctaStats', value: response }),
      );
    } else {
      actions.toggleSnackbar({
        open: true,
        message: 'Unable to fetch stats',
        variant: 'error',
      });
    }
  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getWhishlistStats() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/dashboard/wishlist-stats?${queries}`,
      options,
    );
    if (response) {
      console.log(response, 'data', 'mobile response');
      yield put(
        actions.setDashboardGraph({ key: 'wishlistStats', value: response }),
      );
    } else {
      actions.toggleSnackbar({
        open: true,
        message: 'Unable to fetch stats',
        variant: 'error',
      });
    }
  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getMostViewedStats() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/dashboard/most-viewed-post-stats?${queries}`,
      options,
    );
    if (response) {
      console.log(response, 'data', 'mobile response');
      yield put(
        actions.setDashboardGraph({ key: 'mostViewedStats', value: response }),
      );
    } else {
      actions.toggleSnackbar({
        open: true,
        message: 'Unable to fetch stats',
        variant: 'error',
      });
    }
  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getTagStats() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/dashboard/tag-stats?${queries}`,
      options,
    );
    if (response) {
      console.log(response, 'data', 'mobile response');
      yield put(
        actions.setDashboardGraph({ key: 'tagStats', value: response }),
      );
    } else {
      actions.toggleSnackbar({
        open: true,
        message: 'Unable to fetch stats',
        variant: 'error',
      });
    }
  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getPostsListGenerator() {
  yield delay(500);
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(`${APP_ANALYTICS_URL}/dashboard/post-impressions-list?${queries}`, options);
    if (response && response.items) {
      yield put(actions.getPostListSuccess(response));
    } else {
      yield put(actions.getPostListFailure(response));
    }
  } catch (e: any) {
    yield put(actions.getPostListFailure(e));
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  }
}


export function* getDashboardStats() {
  try {
    yield getTotalNumberOfUsers();
    yield getAvgTimeStats();
    yield getCTAStats();
    yield getWhishlistStats();
    yield getMostViewedStats();
    yield getTagStats();
    yield getPostsListGenerator();

  } catch (e) {
    yield put(
      actions.toggleSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* useDashboardSaga() {
  yield takeLatest(actions.getDashboardStats.type, getDashboardStats);
}
