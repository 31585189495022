import React from 'react';

import { Box, BoxProps } from '@mui/material';

const Page = (props: BoxProps) => {
  return (
    <Box mx={6} {...props} className={props?.className}>
      {props?.children}
    </Box>
  );
};

export default Page;
