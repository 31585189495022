import { makeStyles } from '@mui/styles';

export const useCategoryRowStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    border: '1px solid #CCCDCF',
    margin: '8px 0',
    padding: 8,
    position: 'relative',
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  categoryNameLabel: {
    color: '#3D3F47',
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
  },
}));
