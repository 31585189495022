import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  ml1: {
    marginLeft: "10px"
  },
  topDiv: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    padding: '14px 0px 0px 0px',
    // border: "1px solid blue",
    margin: "10px 0"
  },
  dataTableDiv: {
    borderRadius: 6,
    maxHeight: 'calc(100vh - 172px)',
    border: "1px solid #D5D8DE",
    overflowY: "auto",
    marginTop: '14px',
    // borderTop:'0.5px solid #9CA2B0',
    "&::-webkit-scrollbar": {
      width: 4,
      height: 5,
      padding: '5px 0px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#D9D9D9',
      // marginTop:500,
      // boxShadow: 'inset 4px 4px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#959595',
      borderRadius: 20,
      // border: '1px solid #3C4858',
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#959595',
    }
  },
  statusMenu: {
    marginLeft: 0,
    "& .MuiOutlinedInput-root": {
      background: "#FFFFFF",
      width: 184,
      borderRadius: "6px",
      height: '48px'
    }
  },
})