import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    // display: 'flex',
    // flexDirection: 'column',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30%',
    // maxHeight:'500px',
    maxWidth: '464px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    // border: '2px solid #000',
    boxShadow: '24px',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#FAA61A',
    fontFamily: 'Poppins',
  }
});
