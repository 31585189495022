import { PermissionGroupItem } from '../Merchants/redux/types';
import { FormElement } from '../types';

export type LoginForm = {
  username: FormElement;
  password: FormElement;
};
/* --- STATE --- */
export interface AuthState {
  username: string;
  password: string;
  loading: boolean;
  error?: LoginErrorType | any;
  loginForm: LoginForm;
  token: string;
  user: ShopiTvUser;
  authenticateUser: boolean;
  forgotPasswordForm: {
    email: string;
    error: string;
  };
  resetForm: {
    password: string;
    confirm_password: string;
    error: string;
  };
}

export enum LoginErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/

export type ShopiTvUser = {
  acceptedPolicyAt: null | string;
  acceptedTermsAt: null | string;
  merchantUsers: Array<any>;
  blockedUntil: any;
  countryCode: any;
  createdAt: Date | null;
  dob: any;
  email: string | null;
  fcmToken: string | null;
  firstName: string | null;
  id: number | null;
  image: any;
  ip: string | null;
  isActive: boolean | null;
  key: string | null;
  language: string | null;
  lastLogin: Date | null;
  lastName: string | null;
  loginAttempts: number | null;
  mac: string | null;
  message?: string | null;
  role: string | null;
  mobileNumber: string | null;
  permissionGroups: Array<PermissionGroupItem>;
  token: string | null;
};

export const defaultShopiTVUser: ShopiTvUser = {
  acceptedTermsAt: null,
  acceptedPolicyAt: null,
  merchantUsers: [],
  blockedUntil: null,
  countryCode: null,
  createdAt: null,
  dob: null,
  email: null,
  fcmToken: null,
  firstName: null,
  id: null,
  image: null,
  ip: null,
  isActive: false,
  key: null,
  language: null,
  lastLogin: null,
  lastName: null,
  loginAttempts: null,
  mac: null,
  mobileNumber: null,
  permissionGroups: [],
  message: null,
  role: null,
  token: localStorage.getItem('sessionToken'),
};

export type ContainerState = AuthState;
