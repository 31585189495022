import { Box, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';

type Props = {
  label: string;
  style?: any;
};

const SectionHeading = ({ label, style }: Props) => {
  const styles = useStyles();
  return (
    <Box style={style}>
      <Typography className={styles.root}>{label}</Typography>
    </Box>
  );
};

export default SectionHeading;
