import clsx from 'clsx';
import React from 'react';

import { Box, Button, ButtonProps } from '@mui/material';

import { useStyles } from './styles';

type CustomProps = {
  withPadding?: boolean;
  btnVariant?: 'medium'| 'default'
};
type Props = ButtonProps & CustomProps;

const GradientButton = ({ withPadding, btnVariant, ...props }: Props) => {
  const styles = useStyles();
  const wrapStyles = withPadding ? styles.wrapWithSpacing : styles.wrap;
  return (
    <Box className={clsx(wrapStyles, styles.buttonContainer, btnVariant=='medium'&&styles.mediumBtn)}>
      <Button
        sx={{ p: 1, width:'160px', height: '48px' }}
        {...props}
        className={clsx(styles.buttonRoot, props.className)}
      >
        {props.children}
      </Button>
    </Box>
  );
};

export default GradientButton;
