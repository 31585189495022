import { put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { actions as feedActions } from '../FeedConfiguration/redux/slice';
import { FormError } from '../types';
import { selectSettingsForm } from './selector';
import { actions } from './slice';
import { AppVersionResponse, SystemSettingsForm } from './types';

export function* updateSetting(action) {
  try {
    const { validator, value }: SystemSettingsForm = yield select(
      selectSettingsForm,
    );
    const errors: Array<FormError> = validator ? validator(value.value) : [];
    if (errors.length) {
      yield put(actions.setFormErrors(errors));
      return;
    }

    const options = {
      method: 'PUT',
      body: JSON.stringify(action?.payload),
    };
    const response = yield request(`${API_URL}/app-settings`, options);
    if (response) {
      yield put(feedActions.updateSettingSuccess(response));
      yield put(actions.toggleOpen());
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getAppVersions(action) {
  try {
    const response: AppVersionResponse = yield request(
      `${API_URL}/app-settings/app-versions`,
    );
    if (response && response.web) {
      yield put(actions.getAppVersionsSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* useSystemSettingsSaga() {
  yield takeLatest(actions.updateSetting.type, updateSetting);
  yield takeLatest(actions.getAppVersions.type, getAppVersions);
}
