import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { actions as dashboardActions } from '../Dashboard/slice';
import Form from './components/Form';
import { actions } from './redux/slice';

type Props = {};

const FeedConfiguration = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getConfiguration());
  }, []);

  useEffect(() => {
    // dispatch(dashboardActions.setRoute('Edit Feed Configuration'));
  }, []);

  return <Form />;
};

export default FeedConfiguration;
