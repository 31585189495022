import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';

import { Box, Grid, Typography } from '@mui/material';

import { MAX_HEIGHT, MAX_MARGIN } from '../constants';

const ImageLabel = ({
  total = 1,
  title,
poster  = 'https://picsum.photos/200/300',
}) => {
    const height = (MAX_HEIGHT - MAX_MARGIN * 2) / total
  return (
    <Box sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', display: 'flex', marginTop: `${-5}px`, fontSize: 14, height }}>
              <img style={{ width: '30px', height: '30px', margin: '4px' }} src={poster} alt='post' />
              <Typography  sx={{
                width: '90px', fontSize: 12, fontWeight: 500, overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                lineHeight: '11px',
              }}> {title}</Typography>
            </Box>
  );
};

export default ImageLabel;
