import DataTable from 'app/components/DataTable';
import TableLoader from 'app/components/TableLoader';
import IOSSwitch from 'app/containers/Dashboard/components/IOSSwitch';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { scrollBottom } from 'styles/theme/utils';

import {
    Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { selectImportLogs, selectIsAutoRefresh } from '../redux/selector';
import { actions } from '../redux/slice';
import List from './List';

type Props = {
  id: number | null;
  setId: any;
  dialogProps: DialogProps;
};

const useStyles = makeStyles({
  listContainer: {
    maxHeight: 400,
    overflow: 'auto',
  },
  headerCheckbox: {
    ml: 1, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    marginEnd: '20px'
  }
});

const ImportLogs = (props: Props) => {
  const { items, query } = useSelector(selectImportLogs);
  const autoRefresh = useSelector(selectIsAutoRefresh);
  const dispatch = useDispatch();
  const styles = useStyles();
  const columns = [
    {
      id: 'id',
      label: 'Time',
      sortValue: 'selector.time',
    },
    {
      id: 'message',
      label: 'Message',
      sortValue: 'selector.message',
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  const setQuery = (name: string, value: string) => { };

  useEffect(() => {
    if (props.id) {
      dispatch(actions.getImportLogById({ id: props.id }));
    }
  }, [props.id]);

  useEffect(() => {
    let timer: any = null
    if (autoRefresh &&  props.id) {
      timer = setInterval( () => {
        dispatch(actions.getImportLogById({ id: props.id }));
      }, 3000)
    }else{
      clearInterval(timer)
    }

    return () => clearInterval(timer);
  }, [autoRefresh, props.id]);


  const renderLogs = columnValues => {
    return (
      <>
        <React.Fragment>
          <List logs={items} columnValues={columnValues} />
        </React.Fragment>
      </>
    );
  };

  const resetLogsDialog = () => {
    dispatch(actions.resetLogsDialog());
    props.setId(null);
  };

  const handleChange = () => {
    dispatch(actions.setAutoRefresh());
  }

  return (
    <Dialog {...props.dialogProps} onClose={resetLogsDialog}>

      <Box className={styles.headerCheckbox}>
        <DialogTitle>Import Logs-{props.id}</DialogTitle>
        <Box sx={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
          <Typography sx={{marginRight: 1}}> Auto Refresh</Typography>
          <IOSSwitch
            name="auto_refresh"
            checked={autoRefresh}
            value={autoRefresh ? 'TRUE' : 'FALSE'}
            onChange={handleChange}
          />
        </Box>

      </Box>
      <DialogContent >
        <Box id='default-scroll' className={styles.listContainer}>
          <DataTable
            columns={columns}
            rows={items.flatMap(item => {
              return item.data;
            })}
            orderDirection={query.orderDirection}
            orderField={query.orderField}
            setQuery={setQuery}
            renderRows={renderLogs}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetLogsDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportLogs;
