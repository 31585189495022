import React from 'react';

import {
    FormControl, formControlClasses, FormControlProps, FormHelperText, InputLabel, MenuItem,
    PaperProps, Select, Typography
} from '@mui/material';

type Props = {
  value: any;
  handleChange: any;
  title?: string;
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
  disabled?: boolean;
  lableColor?: string;
  lable?: string;
  width?: string;
  menuItems?: any;
  name?: string;
  placeholder?: string;
  error?: string;
  labelFontWeight?: number;
  defaultValue?: string;
  formControlProps?: FormControlProps;
};

const DropDownComponent = ({
  value,
  handleChange,
  title,
  size,
  fullWidth,
  lableColor,
  lable,
  width,
  menuItems,
  name,
  placeholder,
  error,
  labelFontWeight = 500,
  defaultValue,
  disabled,
  formControlProps = {},
}: Props) => {
  return (
    <>
      {lable && (
        <Typography sx={{ mt: '10px', mb: '8px', fontWeight: labelFontWeight }}>
          {lable}
        </Typography>
      )}
      <FormControl
        size={size ?? 'small'}
        sx={{
          backgroundColor: '#FFF',
          position: 'relative',
          top: '1px',
          width: width,
        }}
        {...formControlProps}
      >
        <InputLabel
          shrink
          sx={{
            color: lableColor ?? '#333333',
            borderColor: '#D5D8DE',
          }}
          id={`select-label${name}`}
        >
          {title}
        </InputLabel>
        <Select
          displayEmpty
          defaultValue={defaultValue}
          name={name}
          disabled={disabled}
          labelId={`select-label${name}`}
          id={`select${name}`}
          value={value || ''}
          label={title}
          onChange={handleChange}
          error={!!error}
          placeholder={placeholder}
          sx={{
            div: {
              color: '#333',
            },
            padding: '8px',
            '& .MuiOutlinedInput-root': {
              background: '#FFF',
              borderRadius: '6px',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: 5,
              },
            },
          }}
        >
          {menuItems?.length < 0 && (
            <>
              <MenuItem value={''}>All</MenuItem>
              <MenuItem value={'TRUE'}>Active</MenuItem>
              <MenuItem value={'FALSE'}>In-Active</MenuItem>
            </>
          )}
          {menuItems}
        </Select>
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default DropDownComponent;
