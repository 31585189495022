import React from 'react';

import { Tooltip, Typography, TypographyProps } from '@mui/material';

import NA from '../NA';

type Props = {
  value: any;
  limit?: number;
  textProps?: TypographyProps;
};

const Truncate = ({ value = '', limit = 15, textProps = {} }: Props) => {
  const truncate = (str, lmt?) => {
    return str && str.substring(0, lmt ? lmt - 5 : 15) + '...';
  };
  const truncated = truncate(value, limit);
  const showTextWithTooltip = value && value.length > limit;
  return showTextWithTooltip ? (
    <Tooltip title={value}>
      <Typography {...textProps} component={'span'}>{truncated}</Typography>
    </Tooltip>
  ) : (
    <NA value={value} />
  );
};

export default Truncate;
