import { FormError } from 'app/containers/types';
import { TagForm } from '../../types';

export const validate = (form: TagForm): Array<FormError> => {
  let errors: Array<FormError> = [];
  if (!form.categoryId.value) {
    errors.push({
      name: 'categoryId',
      error: `Category can't be empty`,
    });
  }
  if (!form.name.value) {
    errors.push({
      name: 'name',
      error: `Name of tag can't be empty`,
    });
  }
  return errors;
};
