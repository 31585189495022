import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.merchantState || initialState;
export const selectMerchantList = createSelector(
  [selectDomain],
  state => state?.list,
);
export const selectMerchantQuery = createSelector(
  [selectDomain],
  state => state.query,
);
export const selectMerchant = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectMerchantLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectPermissionGroup = createSelector(
  [selectDomain],
  state => state.permissionGroup,
);

export const selectPermissionGroupForm = createSelector(
  [selectDomain],
  state => state.permissionGroup.form,
);

export const selectPermissionGroupList = createSelector(
  [selectDomain],
  state => state.permissionGroup.list,
);

export const selectPermissionGroupListQuery = createSelector(
  [selectDomain],
  state => state.permissionGroup.query,
);
export const selectSelectedMerchantsList = createSelector(
  [selectDomain],
  state => state?.merchantUsers.form.merchantUsers,
);

export const selectMerchantUserList = createSelector(
  [selectDomain],
  state => state.merchantUsers.list,
);
export const selectMerchantUserForm = createSelector(
  [selectDomain],
  state => state.merchantUsers.form,
);
export const selectMerchantUserQuery = createSelector(
  [selectDomain],
  state => state.merchantUsers.query,
);
export const selectOpenAddMerchantModal = createSelector(
  [selectDomain],
  state => state.merchantUsers.openAddMerchantModal,
);
export const selectOpenTaxIdForm = createSelector(
  [selectDomain],
  state => state.merchantUsers.openTaxIdForm,
);
export const selectOpenEditMerchantModal = createSelector(
  [selectDomain],
  state => state.merchantUsers.openEditMerchantModal,
);

export const selectBusinessTypeOptions = createSelector(
  [selectDomain],
  state => state.businessTypeOptions,
);
