import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';

type Props = {}

const Card = styled(Box)({
    background: "#F7F7FA",
    borderRadius: "6px",
    border: "1px solid #D5D8DE"
})


export default Card