import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import React, { Dispatch, SetStateAction, useState } from 'react';
import { DateRange } from 'react-date-range';
import { getFormattedDateTime } from 'utils/helpers';

import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { Box, BoxProps, Button, Popover, TextField, Typography } from '@mui/material';

import { useStyles } from './styles';

type Props = {
  title: string;
  value?: any;
  setValue?: Dispatch<SetStateAction<number>>;
  onChange: Function;
  date: Array<{
    startDate: Date | null;
    endDate: Date | null;
    key: string;
  }>;
  clearDates: any;
  onOkayDates: any;
  anyDate?: string
  className?: string;
  classNameStyled?: string;
  label?: string;
};

const PopoverDatePicker = (props: Props) => {
  const styles = useStyles()
  const [date, setDate] = useState<typeof props.date>([
    { startDate: new Date(), endDate: new Date(), key: 'selection' },
  ]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onDateClear = () => {
    setDate([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    props.clearDates();
    handleClose();
  };

  const onCancel = () => {
    if (!props.date[0].startDate) {
      setDate([
        { startDate: new Date(), endDate: new Date(), key: 'selection' },
      ]);
    } else {
      setDate(props.date);
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onOkay = () => {
    props.onChange(date);
    props.onOkayDates();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [{ startDate, endDate }] = props.date;
  const title = !props.title
    ? `${getFormattedDateTime(
        startDate?.toISOString(),
        'dd/MM/yyyy',
      )} - ${getFormattedDateTime(endDate?.toISOString(), 'dd/MM/yyyy')}`
    : props.title;
    const label = !props.title ? props.label : ''
  return (
    <Box style={{ position: 'relative' }}>
      <Button
        className={props.anyDate === "anyDate" ? `${props.classNameStyled}` : `${props.className}`}//{props.className}
        onClick={handleClick}
        >
        {title}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRange
          onChange={item => setDate([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={date}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            backgroundColor: '#fff',
            padding: '10px 20px 30px 0px',
          }}
        >
          <Button sx={{ color: '#333' }} onClick={onDateClear}>
            Clear
          </Button>
          <Button sx={{ color: '#333' }} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#FFA96A', color: '#fff' }}
            onClick={onOkay}
          >
            Ok
          </Button>
        </div>
      </Popover>
    </Box>
  );
};

export default PopoverDatePicker;

// position: 'absolute', bottom: 20, right: 25
