import { IListType } from 'app/containers/types';
import queryString from 'query-string';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { getDefaultHeaders, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { DeviceFilterQueryType } from '../types';
import { selectQuery } from './selectors';
import { actions } from './slice';

const moduleUrl = `${API_URL}/devices`;

export function* handleBlock(action) {
  try {
    const options = { method: 'PATCH', headers: getDefaultHeaders() };
    const response: any = yield request(
      `${moduleUrl}/${action.payload}`,
      options,
    );
    if (response && response.id) {
      yield put(actions.handleBlockSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getList(action) {
  try {
    const options = { method: 'GET', headers: getDefaultHeaders() };
    const queryOptions = yield select(selectQuery);
    const queryRequestOptions = removeBlankProperties(queryOptions);
    const query = queryString.stringify(queryRequestOptions);

    const response: IListType | any = yield request(
      `${moduleUrl}?${query}`,
      options,
    );
    if (response.items) {
      yield put(actions.getListSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNextPage(action) {
  try {
    const {
      q,
      orderField,
      orderDirection,
      status,
      updateMax,
      updateMin,
    }: DeviceFilterQueryType = yield select(selectQuery);
    const query = {
      ...(q && { q }),
      ...(orderField && { orderField }),
      ...(orderDirection && { orderDirection }),
      ...(status && { status }),
      ...(updateMin && { updateMin }),
      ...(updateMax && { updateMax }),
    };
    const requestQuery = queryString.stringify(query);
    const response: IListType = yield request(
      action.payload + `${requestQuery && '&' + requestQuery}`,
    );
    if (response && response.items) {
      yield put(actions.getNextPageSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* deleteDevice(action) {
  try {
    const options = {
      method: 'DELETE',
    };
    const response = yield request(
      `${API_URL}/devices/${action.payload.id}`,
      options,
    );
    console.log(response, 'delete response');
    if (response && response.affected > 0) {
      console.log(response, 'delete response');
      yield put(actions.handleDeleteSuccess(action.payload.id));
      yield call(action.payload.callBack);
    } else {
      yield put(actions.handleDeleteFailed(''));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* useDeviceSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.handleBlock.type, handleBlock);
  yield takeLatest(actions.getNextPage.type, getNextPage);
  yield takeLatest(actions.handleDelete.type, deleteDevice);
}
