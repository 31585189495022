import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.channelState || initialState;
export const selectError = createSelector([selectDomain], state => state.error);
export const selectChannelLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectChannelForm = createSelector(
  [selectDomain],
  channelState => channelState.form.createChannel,
);
export const selectChannelList = createSelector(
  [selectDomain],
  channelState => channelState.list,
);
export const selectChannelsQuery = createSelector(
  [selectDomain],
  channelState => channelState.query,
);
