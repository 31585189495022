import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { Box, BoxProps, TextFieldProps, Typography } from '@mui/material';

import { TextField } from '../TextField';
import { useFormFieldStyles } from './styles';

type Props = {
  label?: string | any;
  inputProps?: TextFieldProps;
  rootClassName?: string | any;
  rootProps?: BoxProps;
  labelClassName?: string | any;
  className?: string | any;
  children?: ReactNode;
  isLabelVisible?: boolean;
  maxLength?: number;
  maxRows?: number;
  isRequired?: boolean;
};
const WordCount = ({ value, maxLength, rightMargin }) => {
  return (
    <Typography sx={{ position: 'absolute', right: rightMargin, top: 0, fontSize: '14px' }}>
      chars count : {value?.length || 0} / {maxLength}
    </Typography>
  )
}

const RowsCount = ({ value, maxLength, rightMargin }) => {
  return (
    <Typography sx={{ position: 'absolute', right: rightMargin, top: 0, fontSize: '14px' }}>
      lines count : {value || 0} / {maxLength}
    </Typography>
  )
}

const FormFieldWithLabel = ({
  inputProps,
  rootProps,
  label,
  rootClassName,
  labelClassName,
  className,
  children = null,
  isLabelVisible = true,
  maxLength,
  maxRows,
  isRequired = false,
}: Props) => {
  const styles = useFormFieldStyles();
  const rows = (inputProps?.value as String || "").split('\n')
  return (
    <Box
      className={clsx(styles.root, rootClassName)}
      {...rootProps}
      sx={{
        p: '20px 7px 0px 7px',
        flexDirection: 'column',
        ...(rootProps?.sx && { ...rootProps.sx }),
      }}
    >{isLabelVisible && (
      <Box sx={{ position: 'relative' }}>
        <Typography className={clsx(styles.label, labelClassName)}>
          {label}{isRequired ? "*" : ""}
        </Typography>
        {maxLength &&
          <WordCount value={inputProps?.value} maxLength={maxLength} rightMargin={0} />
        }
        {maxRows &&
            <RowsCount value={rows.length} maxLength={maxRows} rightMargin={!maxLength ? 0 : 170} />
        }
      </Box>
    )

      }

      {children ? (
        children
      ) : (
          <TextField
            {...inputProps}
            className={clsx(styles.inputField, className)}
          />
      )}
    </Box>
  );
};

export default FormFieldWithLabel;
