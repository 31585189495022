import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  pagePadding: {
    marginLeft: 0,
    marginRight: 0
  },
  pageSpacing: {
    paddingLeft: theme.spacing(1.3),
    paddingRight: theme.spacing(1.3)
  },
  container: {
    marginTop: '18px',
    backgroundColor: 'white',
    borderRadius: "10px",
    boxShadow: '0px 4px 29px rgba(28, 31, 61, 0.1)'
  },
  dateRangeTitle: {
    color: "#3D3F47",
    fontWeight: 400,

    marginTop: '20px'
  },
  dateRangeBox: {
    backgroundColor: 'white',
    borderRadius: '12px',
    marginTop: '20px',
    marginRight: '-8px',
    boxShadow: '0px 4px 29px rgba(28, 31, 61, 0.1)'
  },
  dateRangeButton: {
    borderRadius: '12px',
    color: "black",
    '&:hover': {
      background: '#000000',
      color: "white"
    },
    margin:'5px'
  },
  activeDateRangeButton: {
    borderRadius: '12px',
    background: "#000000",
    color: "#fff",
    '&:hover': {
      background: '#000000',
      color: "white"
    },
    margin: '5px'
  },
  statsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight:'8px'
  },
  dataTable: {
    margin: "0px 15px 0px 15px",
    maxHeight: 'calc(100vh - 240px)',
    overflowY: 'auto',
    marginBottom: '15px',
    borderRadius: 10,
    border: '1px solid rgba(213, 215, 221, 0.8)',
  },
  impressionTitle: {
    margin: '15px',
    fontSize: 17,
    color: '#4B4088',
    fontWeight: 700,
  },
  dropDown: {
    marginTop: '8px',
    marginBottom: '15px',
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      height: '48px',
      borderRadius: '6px',
      width: 185,
    }
  },
  searchField: {
    width: '350px',
    '& .MuiOutlinedInput-root': {
      background: '#F7F7FA',
      height: '48px',
      borderRadius: '8px',
      borderColor: 'rgba(213, 215, 221, 0.8)'
    },
  }
}))