import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
  },
  main: {
    display: 'flex',
    // backgroundColor:'#aba',
    borderBottom: '1px solid #9CA2B0',
    borderBottomWidth: '1px',
    padding: '0 0 10px 0px',
    justifyContent: 'flex-end',
  },
});
