import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';

import { Box, Grid, Typography } from '@mui/material';

import { MAX_HEIGHT, MAX_MARGIN } from '../constants';
import ImageLabel from './ImageLabel';

const HorizontalBarChart = ({
  titleOfChart,
  colors,
  series,
  categories,
}) => {
  const chartOptions = {
    series: [
      {
        data: series,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
        offsetX: -20,
        offsetY:-20

      },
      ...(colors && {
        colors,
      }),
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
          horizontal: true,
          barHeight: '70%'
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: categories.map(r => r.ctaVal !== undefined ? `${r.title}(${r.ctaVal})` : r.title),
        labels: {
          style: {
            color: '#999999',
            fontSize: '12px',
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      noData: {
        text: "No data found",
        align: "center",
        verticalAlign: "middle",
      },
      yaxis: {
        axisTicks: {
          show: true,
        },
        labels: {
          show: false,
        },
      },
      tooltip: {
        x: {
          title: {
            formatter(seriesName) {
              return seriesName
            }
          }
        },
        y: {
          title: {
            formatter(seriesName) {
              return ''
            }
          },
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          }
        }
      }
    },
  };
  return (
    <div style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 4px 29px rgba(28, 31, 61, 0.1)' }} >
      <h4 style={{ margin: 0, paddingTop: '15px', paddingLeft: '15px', fontSize: 17, color: '#4B4088' }}>{titleOfChart}</h4>
      <Grid container flexDirection={'row'} sx={{paddingLeft: 2, paddingRight: 2}}>
          <Grid item xs={4} sx={{ marginTop: `${MAX_MARGIN}px`, marginBottom: `${MAX_MARGIN}px` }}>
          {categories.map((r, index) => {
            return <ImageLabel poster={r.poster} title={r.title} total={categories.length} />
          })}
        </Grid>
        <Grid item xs={8}>
          <Chart
            series={chartOptions.series}
            options={chartOptions.options}
            type="bar"
            height={MAX_HEIGHT}
          />
        </Grid>

      </Grid>

    </div>
  );
};

export default HorizontalBarChart;
