import { makeStyles } from '@mui/styles';

export const useFormFieldStyles = makeStyles({
  root: {
    display: 'flex',
  },
  label: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '10px',
    marginBottom: '12px',
  },
  inputField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      borderColor: '#CCCDCF',
      background: '#FFF',
    },
  },
});
