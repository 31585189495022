import { getSignedUrl, uploadUsingSignedURL } from 'app/components/commonSaga';
import { ShopiTvUser } from 'app/containers/Auth/types';
import { IListType, SignedURLResponse } from 'app/containers/types';
import queryString from 'query-string';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { getDefaultHeaders, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { PayloadAction } from '@reduxjs/toolkit';

import { actions as dashboardActions } from '../../Dashboard/slice';
import {
    selectMerchant, selectMerchantQuery, selectMerchantUserForm, selectMerchantUserQuery,
    selectPermissionGroupListQuery
} from './selector';
import { actions } from './slice';
import {
    BusinessTypeOptions, MerchantForm, MerchantQuery, MerchantUserForm, MerchantUsersFilter,
    PermissionGroupQuery
} from './types';

const moduleUrl = API_URL + '/merchants';

export function* getList() {
  yield delay(500);
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query: MerchantQuery = yield select(selectMerchantQuery);
    const {
      createMax,
      createMin,
      limit,
      orderDirection,
      orderField,
      page,
      q,
      updateMax,
      updateMin,
    } = query;
    const requestData = removeBlankProperties({
      createMax,
      createMin,
      limit,
      orderDirection,
      orderField,
      page,
      q,
      updateMax,
      updateMin,
    });
    const queries = queryString.stringify({
      ...requestData,
    });
    const businessType = queryString.stringify(
      { businessType: query.businessType },
      {
        arrayFormat: 'bracket',
      },
    );
    const response = yield request(
      moduleUrl + `?${queries}${businessType ? '&' + businessType : ''}`,
      options,
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.getListFailed(e.message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}


export function* getNextPage(action) {
  yield delay(500);
  try {
    if (action.payload) {
      const {
        q,
        createMin,
        createMax,
        updateMin,
        updateMax,
        orderDirection,
        orderField,
        businessType: selectedBusiness,
      }: MerchantQuery = yield select(selectMerchantQuery);
      const requestQuery = {
        ...(q && {
          q,
        }),
        ...(createMin && {
          createMin,
        }),
        ...(createMax && {
          createMax,
        }),
        ...(updateMax && {
          updateMax,
        }),
        ...(updateMin && {
          updateMin,
        }),
        ...(orderDirection && {
          orderDirection,
        }),
        ...(orderField && {
          orderField,
        }),
      };
      const query = queryString.stringify(requestQuery);
      const businessType = queryString.stringify(
        { businessType: selectedBusiness },
        {
          arrayFormat: 'bracket',
        },
      );
      const response = yield request(
        action.payload +
          `${query && '&' + query}${businessType && '&' + businessType}`,
      );
      if (response && response.items) {
        yield put(actions.getNextPageSuccess(response));
      }
    }
  } catch (e: any) {
    yield put(actions.getNextPageFailed(e.message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}

export function* createOrUpdateRequest(action) {
  if (action.payload.errors.length) {
    return;
  }
  yield delay(500);
  try {
    const form: MerchantForm = yield select(selectMerchant);
    const id = form.id.value;
    const merchant = {
      businessName: form.businessName.value,
      businessType: form.businessType.value,
      websiteUrl: form.websiteUrl.value,
      description: form.description.value,
      user: {
        firstName: form.user?.firstName.value,
        lastName: form.user?.lastName.value,
        email: form.user?.email.value,
        mobileNumber: form.user?.mobileNumber.value,
        can_see_all: false,
      },
      image: {
        ...form.image,
        fileName: form.image?.fileName,
        fileType: form.image?.fileType,
        thumb: form.image?.thumb,
        file: form.image?.file,
      },
      address: {
        address: form.address.value || '',
      },
      businessAddress: {
        address: form.businessAddress.value || '',
      },
      contactPerson: {
        name: form.contactPerson.name.value,
        phoneNumber: form.contactPerson.phoneNumber.value,
      },
      socialAccountDetails: {
        insta: form.socialAccountDetails.insta.value,
        fb: form.socialAccountDetails.fb.value,
      },
    };
    if (form.logoUrl.value) {
      const { fileName, fileType, file } = form.image;
      const filename = Date.now() + ` ${fileName}`;
      const signedS3Url: SignedURLResponse = yield getSignedUrl(
        filename,
        fileType,
      );
      if (signedS3Url.url) {
        yield uploadUsingSignedURL(signedS3Url.url, fileType, file);
        merchant.image = {
          fileName: filename,
          fileType,
          file: signedS3Url.key,
          thumb: signedS3Url.previewUrl,
        } as any;
      }
    }
    const options = {
      method: id ? 'PUT' : 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(merchant),
    };

    const URL = id ? `${moduleUrl}/${id}` : moduleUrl;
    const response = yield request(URL, options);
    if (response && response.id) {
      yield put(actions.createOrUpdateSuccess(response));
      yield put(action?.payload?.callBack);
    }
    if (!id) {
      yield put(actions.getList());
    } else {
      yield put(actions.updateRecord(response));
    }
  } catch (e: any) {
    console.log(e);
    const error = JSON.parse(e.message);
    console.log(error);
    yield put(actions.createOrUpdateFailed(error.message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: error.message || 'Something went wrong',
        variant: 'error',
      }),
    );
  }
}

export function* getByIdRequest(action) {
  if (!action.payload) {
    return;
  }
  yield delay(500);
  yield put(actions.resetForm());
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const response = yield request(moduleUrl + `/${action.payload}`, options);
    if (response && response.id) {
      yield put(actions.createOrUpdateSuccess(response));
    }
  } catch (e: any) {
    console.error(e, 'Something went wrong hereeeee');
    yield put(actions.createOrUpdateFailed(e.message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}

export function* getPermissionConfig(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/permission-group/defaults`,
      options,
    );
    if (response && response.permissions) {
      yield put(actions.getPermissionConfigSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getPermissionGroupById(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/permission-group/${action.payload.id}`,
      options,
    );
    if (response && response.permissions) {
      yield put(actions.getPermissionDetailsSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getPermissionGroupsList(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectPermissionGroupListQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response: IListType | any = yield request(
      `${API_URL}/permission-group?${queries}`,
      options,
    );
    if (response && response.items) {
      yield put(actions.getPermissionGroupsListSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNextPagePermissionGroups(action) {
  try {
    const { q }: PermissionGroupQuery = yield select(
      selectPermissionGroupListQuery,
    );
    const query = {
      ...(q && { q }),
    };
    const queryParams = queryString.stringify(query);
    const response: IListType | any = yield request(
      `${action.payload}${queryParams && '&' + queryParams}`,
    );
    if (response && response.items) {
      yield put(actions.getNextPagePermissionGroupsSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

function* createPermissionGroup(action) {
  try {
    const localUser = localStorage.getItem('loginResponse');
    const user: ShopiTvUser = JSON.parse(localUser || '');

    const options = {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        ...action.payload,
        name: 'Permission',
        merchantId: user.id,
      }),
    };
    const response = yield request(`${API_URL}/permission-group`, options);
    if (response && response.id) {
      yield call(action.payload.callBack);
    }
  } catch (e) {
    console.log(e);
  }
}

function* updatePermissionGroup(action) {
  try {
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        ...action.payload.permissions,
        name: 'permission',
      }),
    };
    const response = yield request(
      `${API_URL}/permission-group/${action.payload.id}`,
      options,
    );
    if (response && response.id) {
      yield call(action.payload.callBack);
    }
  } catch (e) {
    console.log(e);
  }
}
function* deletePermissionGroup(
  action: PayloadAction<{ id: string | number }>,
) {
  try {
    const options = {
      method: 'DELETE',
      headers: getDefaultHeaders(),
      // body: JSON.stringify({
      //   ...action.payload.permissions,
      //   name: 'permission',
      // }),
    };
    const response = yield request(
      `${API_URL}/permission-group/${action.payload.id}`,
      options,
    );
    yield put(actions.deletePermissionSuccess({ id: action.payload.id }));
    // if (response && response.id) {
    // yield call(action.payload.callBack);
    // }
  } catch (e) {
    yield put(actions.deletePermissionFailure(e));
    console.log(e);
  }
}

export function* deleteRow(action) {
  yield delay(500);
  try {
    const { id, callBack } = action.payload;
    const options = {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${moduleUrl}/${id}`, options);
    if (response && response.message === 'MESSAGES.DELETE_SUCCESS') {
      yield put(actions.deleteSuccess(id));
      yield call(callBack);
    }
  } catch (e: any) {
    yield put(actions.deleteFailed(e.message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}


export function* getMerchantUsers(action) {
  try {
    const options = {
      method: 'GET',
      header: getDefaultHeaders(),
    };
    const {
      groupId: selectedGroups,
      limit,
      page,
      q,
      role,
      orderDirection,
      orderField,
    }: MerchantUsersFilter = yield select(selectMerchantUserQuery);
    const filteredQueries = removeBlankProperties({
      limit,
      page,
      q,
      role,
      orderDirection,
      orderField,
    });
    const queries = queryString.stringify(filteredQueries);
    const groupId = queryString.stringify(
      { groupId: selectedGroups },
      { arrayFormat: 'bracket' },
    );
    console.log({
      queries,
      api: `${API_URL}/users?${queries && '&' + queries}${
        groupId && '&' + groupId
      }`,
    });
    const response: IListType | any = yield request(
      `${API_URL}/users?${queries && '&' + queries}${groupId && '&' + groupId}`,
      options,
    );
    console.log({ queries: response });
    if (response && response.items) {
      yield put(actions.getMerchantUsersSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNextPageMerchants(action) {
  try {
    const {
      q,
      role,
      orderDirection,
      orderField,
      groupId: selectedGroups,
    }: MerchantUsersFilter = yield select(selectMerchantUserQuery);
    const requestQuery = {
      ...(q && { q }),
      ...(role && { role }),
      ...(orderField && { orderField }),
      ...(orderDirection && { orderDirection }),
    };
    const groupId = queryString.stringify(
      { groupId: selectedGroups },
      { arrayFormat: 'bracket' },
    );
    const queries = queryString.stringify(requestQuery);
    const response = yield request(
      `${action.payload}${queries && '&' + queries}${groupId && '&' + groupId}`,
    );
    if (response && response.items) {
      yield put(actions.getNextPageMerchantUserSuccess(response));
    }
  } catch (e) {
    console.log('error', e);
  }
}
export function* createMerchantUser(action) {
  try {
    const form: MerchantUserForm = yield select(selectMerchantUserForm);
    const merchantUser = {
      user: {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        mobileNumber: form.mobileNumber.value,
        // role: form.role.value,
      },
      merchants: form.merchantUsers.value.map((item) => {
        return {
          id: item.id,
          merchantId: item.merchant?.id,
          taxId: item.taxId ,
          assignedRole: item.assignedRole, };
      }),
    };
    const options = {
      method: 'POST',
      header: getDefaultHeaders(),
      body: JSON.stringify(merchantUser),
    };
    const response = yield request(`${API_URL}/merchant-users`, options);
    if (response && response.id) {
      // yield put(actions.createMerchantUsersSuccess(response));
      yield put(actions.getMerchantUsers(''));
      yield call(action.payload.callBack);
    }
  } catch (e) {
    console.log(e);
  }
}
export function* updateMerchantUser(action) {
  try {
    const form: MerchantUserForm = yield select(selectMerchantUserForm);
    const merchantUser = {
      user: {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        mobileNumber: form.mobileNumber.value,
        email: form.email.value,
      },
      merchants: form.merchantUsers.value.map((item: any) => {
        return {
          id: item.id,
          merchantId: item.merchant?.id,
          taxId: item.taxId ,
          assignedRole: item.assignedRole,
          isDeleted: item.deleted === true ? true : false,
        };
      }),
    };
    // return false;
    const options = {
      method: 'PUT',
      header: getDefaultHeaders(),
      body: JSON.stringify(merchantUser),
    };
    const response = yield request(
      `${API_URL}/merchant-users/${action.payload.id}`,
      options,
    );
    if (response && response.id) {
      yield put(
        actions.updateMerchantUsersSuccess({
          ...response,
          mobileNumber: form.mobileNumber.value,
        }),
      );
      yield put(actions.getMerchantUsers(''));
      yield call(action.payload.callBack);
    }
  } catch (e) {
    console.log(e);
  }
}
export function* deleteMerchantUser(
  action: PayloadAction<{ id: string | number }>,
) {
  try {
    const form: MerchantUserForm = yield select(selectMerchantUserForm);
    const merchantUser = {
      user: {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        mobileNumber: form.mobileNumber.value,
        email: form.email.value,
      },
      merchants: form.selectedMerchants.value.map((item: any) => {
        return { id: item.id, isDeleted: item.deleted === true ? true : false };
      }),
    };
    console.log({ merchantUser }, 'merchantUsere');
    // return false;
    const options = {
      method: 'DELETE',
      header: getDefaultHeaders(),
      // body: JSON.stringify(merchantUser),
    };
    const response = yield request(
      `${API_URL}/merchant-users/${action.payload.id}`,
      options,
    );
    yield put(actions.deleteMerchantUserSuccess({ id: action.payload.id }));
    if (response && response.id) {
      // yield put(
      //   actions.deleteMerchantUserSuccess({
      //     id: action.payload.id),
      // );
      // yield call(action.payload.callBack);
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getMerchantUserById(action) {
  try {
    const options = {
      method: 'GET',
      header: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/merchant-users/${action.payload.id}`,
      options,
    );
    if (response && response.id) {
      yield put(actions.getMerchantUserByIdSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getBusinessTypeOptions(action) {
  try {
    const response: BusinessTypeOptions = yield request(
      `${API_URL}/merchants/business/available-business?`,
    );
    if (response && response.length) {
      yield put(actions.getBusinessTypeOptionsSuccess(response));
    } else {
      console.log(response, 'BusinessTypeOptions');
      yield put(actions.getBusinessTypeOptionsFailed(''));
    }
  } catch (e) {
    console.log(e, 'BusinessTypeOptions');
    yield put(actions.getBusinessTypeOptionsFailed(''));
  }
}

export function* useMerchantSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getNextPage);
  yield takeLatest(actions.createOrUpdate.type, createOrUpdateRequest);
  yield takeLatest(actions.getById.type, getByIdRequest);
  yield takeLatest(actions.delete.type, deleteRow);

  // merchant-users
  yield takeLatest(actions.getMerchantUsers.type, getMerchantUsers);
  yield takeLatest(actions.createMerchantUsers.type, createMerchantUser);
  yield takeLatest(actions.updateMerchantUsers.type, updateMerchantUser);
  yield takeLatest(actions.getMerchantUserById.type, getMerchantUserById);
  yield takeLatest(actions.getNextPageMerchantUser.type, getNextPageMerchants);
  yield takeLatest(actions.deleteMerchantUser.type, deleteMerchantUser);

  // permission-groups saga effects
  yield takeLatest(
    actions.getPermissionGroupsList.type,
    getPermissionGroupsList,
  );
  yield takeLatest(actions.getPermissionConfig.type, getPermissionConfig);
  yield takeLatest(actions.getPermissionById.type, getPermissionGroupById);
  yield takeLatest(
    actions.getNextPagePermissionGroups.type,
    getNextPagePermissionGroups,
  );
  yield takeLatest(actions.updatePermissionGroup.type, updatePermissionGroup);
  yield takeLatest(actions.createPermissionGroup.type, createPermissionGroup);
  yield takeLatest(actions.deletePermissionGroup.type, deletePermissionGroup);

  yield takeLatest(actions.getBusinessTypeOptions.type, getBusinessTypeOptions);
}
