import { put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { selectPageForm } from './selector';
import { actions } from './slice';
import { PageForm } from './types';

export function* getContent(action) {
  try {
    const {name} = action.payload;
    const url = `${API_URL}/pages/${name}`;
    const response = yield request(url);
    if (response) {
      yield put(actions.getContentSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* updateContent(action) {
  try {
    const { id, content }: PageForm = yield select(selectPageForm);
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        name: action.payload,
        content: content.value,
      }),
    };
    const response = yield request(`${API_URL}/pages/${id.value}`, options);
    if(response && response.id){
      yield put(actions.updateContentSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* usePageSaga() {
  yield takeLatest(actions.getContent.type, getContent);
  yield takeLatest(actions.updateContent.type, updateContent);
}
