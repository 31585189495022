import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import { Tag } from '../Tags/types';
import { FormError } from '../types';
import { Category, CategoryState, defaultTag, defaultTagForm } from './types';

// The initial state of the GithubRepoForm container
export const initialState: CategoryState = {
  categoryList: {
    items: [],
    links: {
      first: '',
      last: '',
      next: '',
      previous: '',
    },
    meta: {
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      itemCount: 0,
    },
  },
  openTagForm: false,
  form: {
    tag: defaultTag,
    createTag: defaultTagForm,
    createCategory: {
      id: {
        value: new Date().toISOString(),
      },
      name: {
        value: '',
      },
      createdAt: {
        value: '',
      },
      updatedAt: {
        value: '',
      },
      ecommerceId: {
        value: '',
      },
    },
  },
  query: {
    page: 1,
    limit: 10,
    q: '',
    orderField: '',
    orderDirection: '',
    status: '',
  },
  error: '',
  loading: false,
};

const categorySlice = createSlice({
  name: 'categoryState',
  initialState,
  extraReducers: () => {},
  reducers: {
    create: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    createCategorySuccess: (state, action: PayloadAction<Category>) => {
      state.loading = false;
      state.error = '';
      state.categoryList.items = [action.payload, ...state.categoryList.items];
      state.form.createCategory = {
        ...initialState.form.createCategory,
        id: {
          value: new Date().toISOString(),
        },
      };
    },
    resetForm: state => {
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>,
    ) {
      action.payload.errors.forEach(error => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error,
        );
      });
    },
    clearErrorMessage: state => {
      state.error = '';
    },
    createCategoryFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    update: (state, action) => {
      state.loading = true;
    },
    updateSuccess: (state, action) => {
      state.loading = false;
      const { id } = action.payload;
      const index = state.categoryList.items.findIndex(x => x.id === id);
      if (index >= 0) {
        const category = state.categoryList.items[index];
        state.categoryList.items[index] = {
          ...category,
          ...action.payload,
        };
      }
      state.form.createCategory = {
        ...initialState.form.createCategory,
        id: {
          value: new Date().toISOString(),
        },
      };
    },
    delete: (state, action) => {
      state.loading = true;
    },
    deleteSuccess: (state, action) => {
      state.loading = false;
      state.categoryList.items = state.categoryList.items.filter(
        x => x.id !== action.payload,
      );
    },
    getList: state => {
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.categoryList = action.payload;
    },
    getListFailed: (state, payload) => {
      state.loading = false;
    },
    getNextPage: (state, action) => {
      // state.loading = true;
    },
    getNextPageSuccess: (state, action) => {
      state.loading = false;
      state.categoryList.items = state.categoryList.items.concat(
        action.payload.items,
      );
      state.categoryList.meta.currentPage = action.payload.meta.currentPage;
      state.categoryList.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setEditCategory: (state, action: PayloadAction<Category>) => {
      state.form.createCategory.id.value = action.payload.id;
      state.form.createCategory.name.value = action.payload.name;
    },
    setEditCategoryEmpty: state => {
      state.form.createCategory.name.value = '';
    },
    deleteCategory: (state, action: PayloadAction<any>) => {
      state.categoryList.items = state.categoryList.items.filter(
        x => x.id !== action.payload,
      );
    },
    filterStatus: (state = initialState, action) => {},
    setForm: (
      state,
      action: PayloadAction<{ name: string; value: any; form?: string }>,
    ) => {
      const { name, value, form = 'createCategory' } = action.payload;
      set(state.form, `${form}.${name}.value`, value);
      set(state.form, `${form}.${name}.error`, '');
    },
    updateCategory: (state, action: PayloadAction<Category>) => {
      const index = state.categoryList.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (index >= 0) {
        state.categoryList.items[index] = action.payload;
      }
      state.form.createCategory = {
        ...initialState.form.createCategory,
        id: {
          value: new Date().toISOString(),
        },
      };
    },
    setDefaultPage: state => {
      state.query.page = 1;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.categoryList.items = [];
        state.query.page = 1;
      }
      set(state.query, name, value);
    },
    setOpenTagForm: state => {
      state.openTagForm = !state.openTagForm;
    },
    setCategoryIdToTagForm: (state, action) => {
      state.form.createTag.categoryId.value = action.payload;
    },
    deleteTag: (state, action) => {
      state.loading = true;
    },
    deleteTagSuccess: (state, action: PayloadAction<Tag>) => {
      state.loading = false;
      const index = state.categoryList.items.findIndex(
        category => category.id == action.payload.categoryId,
      );
      if (index !== -1) {
        const category = state.categoryList.items[index];
        const tags = category?.tags;
        if (Array.isArray(tags)) {
          state.categoryList.items[index] = {
            ...category,
            tags: tags.filter(tag => tag.id != action.payload.id),
          };
        }
      }
    },
    deleteTagFailed: state => {
      state.loading = false;
    },
    updateTag: (state, action) => {
      state.loading = true;
    },
    updateTagSuccess: (state, action: PayloadAction<Tag>) => {
      state.loading = false;
      const index = state.categoryList.items.findIndex(
        item => item.id == action.payload.categoryId,
      );
      if (index !== -1) {
        const category = state.categoryList.items[index];
        const tags = category.tags;
        const tagIndex = tags?.findIndex(x => x.id === action.payload.id);
        if (
          Array.isArray(tags) &&
          tags.length > 0 &&
          tagIndex &&
          tagIndex !== -1 &&
          tags[tagIndex]
        ) {
          tags[tagIndex] = action.payload;
          state.categoryList.items[index] = {
            ...category,
            tags,
          };
        }
      }
      state.openTagForm = !state.openTagForm;
      state.form.createTag = initialState.form.createTag;
    },
    updateTagFailed: state => {
      state.loading = false;
    },
    createTag: (state, action) => {
      state.loading = true;
    },
    createTagSuccess: (state, action: PayloadAction<Tag>) => {
      state.loading = false;
      const index = state.categoryList.items.findIndex(
        item => item.id == action.payload.categoryId,
      );
      if (index !== -1) {
        const category = state.categoryList.items[index];
        const tags = category.tags ? category.tags : [];
        state.categoryList.items[index] = {
          ...category,
          ...(tags && { tags: [action.payload, ...tags] }),
        };
      }
      state.form.createTag = initialState.form.createTag;
      state.openTagForm = !state.openTagForm;
    },
    createTagFailed: (state, action) => {
      state.loading = false;
    },
    setEditTag: (state, action: PayloadAction<Tag>) => {
      state.openTagForm = !state.openTagForm;
      state.form.tag = action.payload;
      const tagForm = state.form.createTag;
      tagForm.id.value = action.payload.id;
      tagForm.categoryId.value = action.payload.categoryId;
      tagForm.name.value = action.payload.name;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    getTagByIdSuccess: (state, action) => {
      const items = state.categoryList.items;
    },
    getCategoryById: (state, action) => {
      state.loading = true;
    },
    getCategoryByIdSuccess: (state, action: PayloadAction<Category>) => {
      const items = state.categoryList.items;
      const index = items.findIndex(
        category => category.id === action.payload.id,
      );
      state.categoryList.items[index] = action.payload;
    },
    resetTagForm: state => {
      state.form.createTag = defaultTagForm;
    },
    resetCategoryForm: state => {
      state.form.createCategory = initialState.form.createCategory;
    },
  },
});

export const { actions, reducer, name: sliceKey } = categorySlice;
