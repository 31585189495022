import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import '@toast-ui/editor/dist/toastui-editor.css';

// import Editor from 'app/components/Editor';
import GradientButton from 'app/components/GradientButton';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import { Editor } from '@toast-ui/react-editor';

import { actions as dashboardActions } from '../../Dashboard/slice';
import { selectPageForm, selectPageLoading } from '../selector';
import { actions } from '../slice';

const PrivacyPolicyEdit = () => {
  const navigate = useNavigate();
  const markdownRef = useRef<any>();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectPageLoading);
  const { content } = useSelector(selectPageForm);
  const pageMatch = useMatch(
    `/pages/:name`,
  );
  const pageName=  pageMatch?.params.name


  useEffect(() => {
    dispatch(actions.resetPage());
    dispatch(actions.getContent({ name: pageName }));
    if(pageName == 'terms-of-use') {
      dispatch(dashboardActions.setRoute('Terms of Services'));
    }else if (pageName == 'privacy-policy') {
      dispatch(dashboardActions.setRoute('Privacy Policy'));
    }else{
      dispatch(dashboardActions.setRoute('CMS Pages'));
    }


  }, [pageName]);

  const handleSave = () => {
    dispatch(actions.updateContent(pageName));
  };

  return (
    <Box
      className="left_side"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <GradientButton withPadding={true} onClick={() =>
            navigate(`/${pageName}`)
          }>View</GradientButton>
        <GradientButton onClick={handleSave}>Save Changes</GradientButton>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ flex: 1, px: 2 }}>
          <Editor
            initialValue={content.value}
            usageStatistics={false}
            previewStyle="vertical"
            onChange={e => {
              // @ts-ignore
              const data = markdownRef.current.getInstance().getMarkdown();
              dispatch(actions.setForm({ name: 'content', value: data }));
            }}
            // @ts-ignore
            ref={markdownRef}
            height="100%"
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            plugins={[colorSyntax]}
          />
        </Box>
      )}
    </Box>
  );
};

export default PrivacyPolicyEdit;
