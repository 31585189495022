import Card from 'app/components/Card';
import DataTable from 'app/components/DataTable';
import Page from 'app/components/Page';
import { Select } from 'app/components/Select';
import TableLoader from 'app/components/TableLoader';
import Truncate from 'app/components/Truncate';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'utils/constants';
import { getFormattedDateTime } from 'utils/helpers';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CloudDownload, Info, PlayCircleFilled } from '@mui/icons-material';
import { Box, Button, IconButton, MenuItem, Tooltip } from '@mui/material';

import { selectToken } from '../Auth/selectors';
import { actions as dashboardActions } from '../Dashboard/slice';
import CreateImport from './CreateImport';
import ImportLogs from './ImportLogs';
import { useImportsSaga } from './redux/saga';
import {
    selectImportsList, selectImportsLoading, selectIsFormOpen, selectIsOpenLogsDialog
} from './redux/selector';
import { actions, reducer, sliceKey } from './redux/slice';
import { useImportsStyles } from './styles';

type Props = {};

const ImportsModule = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useImportsSaga });
  const token = useSelector(selectToken);
  const [downloadType, setDownloadType] = useState<string>('');
  const [id, setId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const styles = useImportsStyles();
  const { items, query, links } = useSelector(selectImportsList);
  const isLoading = useSelector(selectImportsLoading);
  const isFormOpen = useSelector(selectIsFormOpen);
  const isLogsDialogOpened = useSelector(selectIsOpenLogsDialog);
  const { orderField, orderDirection, q } = query;
  const columns = [
    {
      id: 'id',
      label: 'ID',
      sortValue: 'selector.id',
    },
    {
      id: 'name',
      label: 'Name',
      sortValue: 'doc.originalName',
      format: value => <Truncate value={value?.doc?.originalName} />,
      minWidth: 100,
    },
    {
      id: 'Owner',
      label: 'Owner',
      sortValue: 'selector.user.name',
      format: value => <Truncate limit={40} value={value?.user.email} />,
      minWidth: 100,
    },
    {
      id: 'progress',
      label: 'Progress',
      sortValue: 'selector.currentProgress',
      format: value => (
        <Truncate value={`${value?.currentProgress}/${value?.totalRows}`} />
      ),
      minWidth: 20,
    },
    {
      id: 'type',
      label: 'Type',
      sortValue: 'selector.type',
      minWidth: 30,
    },
    {
      id: 'processStatus',
      label: 'Status',
      sortValue: 'selector.processStatus',
      minWidth: 40,
      format: value => (
        <Truncate limit={15} value={`${value?.processStatus}`} />
      ),
    },

    {
      id: 'createdAt',
      label: 'Creation Date',
      sortValue: 'selector.createdAt',
      format: value => getFormattedDateTime(value?.createdAt),
      minWidth: 50,
    },
    {
      id: 'action',
      label: 'Action',
      format: value => (
        <Box>
          <Tooltip title="Download Excel File">
            <IconButton onClick={() => window.open(value?.doc?.url, '_blank')}>
              <CloudDownload sx={{ color: '#FAA61A' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Logs">
            <IconButton
              onClick={() => {
                setId(value.id);
                dispatch(actions.toggleLogsDialog());
              }}
            >
              <Info sx={{ color: '#FAA61A' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Process Import">
            <IconButton
              onClick={() => dispatch(actions.processImport(value.id))}
            >
              <PlayCircleFilled />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(dashboardActions.setRoute('Imports'));
  }, []);
  useEffect(() => {
    dispatch(actions.getList(''));
  }, [orderField, orderDirection, q]);

  useEffect(() => {
    if (downloadType) {
      const params = {};
      params['authToken'] = token;
      params['type'] = downloadType;
      window.open(
        `${API_URL}/imports/samples?${new URLSearchParams(params).toString()}`,
        '_blank',
      );
      setDownloadType('');
    }
  }, [downloadType]);

  const setQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const hasMoreItems = !!links.next;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getNextPageItems(links.next));
    }
  };

  const handleDownloadTypeChange = e => {
    const { value } = e.target;
    setDownloadType(value);
  };

  const handleFormToggle = () => {
    dispatch(actions.toggleFormOpen());
  };

  const handleLogsDialogToggle = () => {
    dispatch(actions.toggleLogsDialog());
  };

  return (
    <Page className={styles.root}>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ minWidth: 200 }}>
            <Select
              fullWidth
              value={downloadType}
              size="small"
              placeholder="Download Samples"
              width={240}
              onChange={handleDownloadTypeChange}
              menuItems={['PRODUCT', 'MERCHANT', 'POST'].map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            />
          </Box>
          <Button
            onClick={handleFormToggle}
            sx={{ ml: 1 }}
            variant="outlined"
            size="large"
          >
            New Import
          </Button>
        </Box>
      </Box>
      <Card>
        <Box className={styles.tableContainer}>
          <InfiniteScroll
            loader={<TableLoader />}
            hasMore={hasMoreItems}
            loadMore={getNextPage}
            useWindow={false}
          >
            <DataTable
              loading={isLoading}
              columns={columns}
              rows={items}
              setQuery={setQuery}
              orderDirection={query.orderDirection}
              orderField={query.orderField}
            />
          </InfiniteScroll>
        </Box>
        <CreateImport
          dialogProps={{ open: isFormOpen, onClose: handleFormToggle }}
        />
        <ImportLogs
          id={id}
          setId={setId}
          dialogProps={{
            open: isLogsDialogOpened,
            onClose: handleLogsDialogToggle,
          }}
        />
      </Card>
    </Page>
  );
};

export default ImportsModule;
