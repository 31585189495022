import React, { ReactNode } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';

import { useSettingSectionTableStyles } from './styles';

type Props = {
  children?: ReactNode;
  headings: Array<any>;
};

const SettingsSectionTable = (props: Props) => {
  const styles = useSettingSectionTableStyles();
  return (
    <Table className={styles.table}>
      <TableHead className={styles.tableHead}>
        <TableRow>
          {props.headings.map((heading, index) => (
            <TableCell key={heading.name + index}>{heading.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{props.children}</TableBody>
    </Table>
  );
};

export default SettingsSectionTable;
