import { put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { getDefaultHeaders } from 'utils/helpers';
import { request } from 'utils/request';

import { actions as dashboardActions } from '../../Dashboard/slice';
import { selectFeedSettingList } from './selector';
import { actions } from './slice';
import { IFeedConfigResponse } from './types';

export function* updateConfiguration(action) {
  const items = yield select(selectFeedSettingList);
  try {
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify({items}),
    };
    const response = yield request(`${API_URL}/app-settings/update-many`, options);
    if (response === true) {
      yield put(
        dashboardActions.toggleSnackbar({
          open: true,
          message: 'Settings Updated',
          variant: 'success',
        }),
      );
    }
  } catch (e) {
    yield put(actions.updateConfigurationFailed());
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
    console.log(e);
  }
}

export function* getConfiguration(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const response: IFeedConfigResponse = yield request(
      `${API_URL}/app-settings?limit=100`,
      options,
    );
    if (response && response.items) {
      yield put(actions.getConfigurationSuccess(response));
    }
  } catch (e) {
    yield put(actions.getConfigurationFailed(e));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
    console.log(e);
  }
}

export function* useFeedConfigurationSaga() {
  yield takeLatest(actions.getConfiguration.type, getConfiguration);
  yield takeLatest(actions.updateConfiguration.type, updateConfiguration);
}
