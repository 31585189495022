import Page from 'app/components/Page';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Box } from '@mui/material';

import { actions as dashboardActions } from '../Dashboard/slice';
import FeedConfiguration from '../FeedConfiguration';
import { actions } from '../FeedConfiguration/redux/slice';
import EditDialog from './components/EditDialog';
import DefaultSettings from './components/Tabs/DefaultSettings';
import SystemParameters from './components/Tabs/SystemParameters';
import VersionManagement from './components/Tabs/VersionManagement';
import TopBar from './components/Topbar';
import { useSystemSettingsSaga } from './saga';
import { selectIsOpen } from './selector';
import { actions as systemSettingActions, reducer, sliceKey } from './slice';

type Props = {};

const SystemSettings = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useSystemSettingsSaga });
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    dispatch(dashboardActions.setRoute('System'));
    dispatch(systemSettingActions.getAppVersions());
    dispatch(actions.getConfiguration());
  }, []);

  const toggleEditDialog = value => {
    dispatch(systemSettingActions.toggleOpen());
    dispatch(systemSettingActions.setFieldValue(value));
  };

  const handleClose = () => {
    dispatch(systemSettingActions.toggleOpen());
  };

  const tabContent = () => {
    switch (active) {
      case 0:
        return <DefaultSettings onEdit={toggleEditDialog} />;
      case 1:
        return <SystemParameters onEdit={toggleEditDialog} />;
      case 2:
        return <VersionManagement onEdit={toggleEditDialog} />;
      case 3:
        return <FeedConfiguration />;
      default:
        return <DefaultSettings onEdit={toggleEditDialog} />;
    }
  };

  return (
    <Page sx={{ pt: 1.75 }}>
      <TopBar active={active} setActive={setActive} />
      <Box>
        <Box sx={{ overflow: 'auto', maxHeight: '100vh', pb: 20 }}>
          {tabContent()}
        </Box>
      </Box>
      <EditDialog onClose={handleClose} dialogProps={{ open: isOpen }} />
    </Page>
  );
};

export default SystemSettings;
