import './style.css';

import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

import { selectPageForm } from '../selector';
import { actions } from '../slice';

type Props = {};

const PrivacyPolicy = (props: Props) => {
  const location = useLocation();
  const [hl, setLang] = useState('en');
  const [markDown, setMarkDown] = useState('# My Stream');
  const { content } = useSelector(selectPageForm);
  const [loaidng, setLoading] = useState(true);
  const pageMatch = useMatch(
    `/:name`,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getContent({ name: pageMatch?.params.name, setLoading }));
    const url = qs.parseUrl(window.location.href);
    const isTV = url?.query?.tv == 'true';
    const hl = url?.query?.hl as string;
    setLang(hl);
    if (isTV) {
      document.body.classList.add('tv-page');
    }
    return function cleanup() {
      document.body.classList.remove('tv-page');
    };
  }, [pageMatch?.params.name]);

  return (
        <div>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew" />
          <div className="center_div">
            <div className="right_side">
              {/* @ts-ignore */}
              <ReactMarkdown children={content.value || '# Loading'} rehypePlugins={[rehypeRaw]} />
            </div>
          </div>
        </div>
  );
};

export default PrivacyPolicy;
