import { selectSettingsForm } from 'app/containers/System/selector';
import { FormError } from 'app/containers/types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SettingsField from '../../SettingsField';
import SettingsSection from '../../SettingsSection';

type Props = {
  onEdit: any;
};

const headings = [{ name: 'Action' }, { name: 'Perameter name' },{name:'Default value'}];

const SystemParameters = ({ onEdit }: Props) => {
  const smsTextValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!value.includes('{CODE}')) {
      console.log(value);
      errors.push({
        name: 'value.error',
        error: `The value must contain '{CODE}'`,
      });
    }
    return errors;
  }, []);

  const timeLimitValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];

    if (!value) {
      errors.push({
        name: 'value.error',
        error: `The value must present`,
      });
    }

    if (parseInt(value) < 1) {
      console.log(value);
      errors.push({
        name: 'value.error',
        error: `The value must be more than one`,
      });
    }
    return errors;
  }, []);

  const carouselLimitValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!(parseInt(value) >= 500 && parseInt(value) <= 5000)) {
      console.log(value);
      errors.push({
        name: 'value.error',
        error: `The value must between 500ms to 5000ms`,
      });
    }
    return errors;
  }, []);

  const recommendationTitleLimitValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Title must present`,
      });
    }
    return errors;
  }, []);

  const recommendationLogoLimitValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Logo url must present`,
      });
    }
    return errors;
  }, []);


  return (
    <>
      <SettingsSection
        headings={headings}
        heading="Are you still watching time limit"
        defaultValue={'30.0'}
      >
        <SettingsField
          onEdit={onEdit}
          name="ARE_YOU_STILL_WATCHING_TIME_LIMIT"
          value={'20'}
          label='Time limit (minutes)'
          validator={timeLimitValidator}
        />
      </SettingsSection>
      {/* <SettingsSection
        headings={headings}
        heading="Merchant Parameters"
      >
        <SettingsField
          onEdit={onEdit}
          name="MERCHANT_PAGE_CAROUSEL_SLIDE_TIME"
          value={'1000'}
          label='Time limit (milliseconds)'
          validator={carouselLimitValidator}
        />
      </SettingsSection> */}
      <SettingsSection
        headings={[{ name: 'Action' }, { name: 'Text' },{name:'Default value'}]}
        heading="SMS Text"
      >
        <SettingsField
          onEdit={onEdit}
          name="SMS_TEXT_HEBREW"
          value={'30:00'}
          label={'Sms text in hebrew'}
          fullWidthValue={false}

          validator={smsTextValidator}
        />
         <SettingsField
          onEdit={onEdit}

          name="SMS_TEXT_ENGLISH"
          label={'Sms text in english'}
          value={'30:00'}
          fullWidthValue={false}
          validator={smsTextValidator}
        />
      </SettingsSection>
      <SettingsSection
        headings={headings}
        heading="TV Recommendation Parameters (Swimline)"
      >
        <SettingsField
          onEdit={onEdit}
          name="TV_RECOMMENDATION_TITLE"
          value={'MyStream Recommendation'}
          label='Title'
          validator={recommendationTitleLimitValidator}
        />
        <SettingsField
          onEdit={onEdit}
          name="TV_RECOMMENDATION_LOGO_URL"
            value={''}
            label='Recommendation Logo Url'
            validator={recommendationLogoLimitValidator}
          />
        </SettingsSection>
    </>
  );
};

export default SystemParameters;
