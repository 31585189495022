import { FormError } from 'app/containers/types';
import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { IFeedConfigResponse, initialState, ISettingOptionItem } from './types';

export const feedConfigurationSlice = createSlice({
  name: 'feedConfiguration',
  initialState,
  reducers: {
    setForm: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, '');
    },
    setFormErrors: (
      state,
      action: PayloadAction<{ key; errors: Array<FormError> }>,
    ) => {
      const { errors } = action.payload;
      errors.forEach(({ name, error }) => {
        set(state.form, `${name}.error`, error);
      });
    },
    getConfiguration: state => {
      state.loading = true;
    },
    getConfigurationSuccess: (
      state,
      action: PayloadAction<IFeedConfigResponse>,
    ) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
      action.payload.items.forEach(settings => {
        set(state.form, `${settings.name}.value`, settings.value);
      });
    },
    updateSettingSuccess: (
      state,
      action: PayloadAction<ISettingOptionItem>,
    ) => {
      state.loading = false;
      const item = state.list.items.find( f => f.name == action.payload.name )
      if(item) {
        item.value =  action.payload.value
      }
    },

    setSettings: (
      state,
      action: PayloadAction<{ name: string; value: any }>,
    ) => {
      const { name, value } = action.payload;
      let settings = state.list.items;
      const itemIndex = settings.findIndex(x => x.name === name);
      settings[itemIndex].value = value;
      state.list.items = settings;
    },
    getConfigurationFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateConfiguration: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    updateConfigurationSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = action.payload;
    },
    updateConfigurationFailed: state => {
      state.loading = false;
    },
  },
});
export const { reducer, actions, name: sliceKey } = feedConfigurationSlice;
