import { HeroListItem } from 'app/containers/Heros/types';
import { MerchantObject } from 'app/containers/Merchants/redux/types';
import { FormError } from 'app/containers/types';
import { arrayMoveImmutable } from 'array-move';
import set from 'lodash/set';
import { createDeflate } from 'zlib';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductText } from '../components/BottomTable/TextData/types';
import {
    Channel, Currencies, currencyOptions, defaultSetSrt, defaultTextDataList, Hero, Merchant, Meta,
    ProductChannel, ProductChannelsResponse, ProductFormModeal, ProductResponse, ProductState,
    ProductTag, ProductTagsResponse, ProductVideo, ProductVideosResponse, SrtItem, Tag,
    TextListItem, Video
} from './types';

export const initialState: ProductState = {
  isSingedUrlGenerating: false,
  loading: false,
  error: null,
  errorTabsIndex:[],
  currencyOptions,
  list: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    links: {
      first: '',
      last: '',
      next: '',
      previous: '',
    },
  },
  forUpload: {
    fileSize: '',
    file: '',
    fileName: '',
    fileType: '',
    id: '',
    playlist: '',
    thumb: '',
    thumbUrl: '',
    type: '',
    url: '',
  },
  form: {
    setSrt: defaultSetSrt,
    model: undefined,
    text: {
      id: { value: '' },
      productId: { value: '' },
      label: { value: '' },
      shortText: { value: '' },
      longText: { value: '' },
      createdAt: { value: '' },
    },
    setVideo: {
      checkStatusTime: { value: null },
      isUploading: { value: null },
      index: { value: null },
      assetUrl: { value: '' },
      id: {
        value: '',
      },
      name: {
        value: '',
      },
      applicableTvPost: {
        value: '',
      },
      status: {
        value: '',
      },
      eligibleProductDetails: {
        value: '',
      },
      video: {} as Video,
    },
    setProduct: {
      merchant: {} as Merchant,
      hero: {} as Hero,
      id: {
        value: '',
      },
      merchantId: {
        value: '',
      },
      heroId: {
        value: '',
      },
      productNotes: {
        value: '',
      },
      sku: {
        value: '',
      },
      assetUrl: { value: '' },
      rating: {
        value: '',
      },
      promotionText: {
        value: '',
      },
      createdAt: {
        value: '',
      },
      updatedAt: {
        value: '',
      },
      name: {
        value: '',
      },
      shortDescription: {
        value: '',
      },
      longDescription: {
        value: '',
      },
      starRating: {
        value: '',
      },
      salePrice: {
        value: '',
      },
      salePriceCurrency: { value: '', label: '' },
      priceCurrency: { value: '', label: '' },
      price: {
        value: '',
      },
      salePriceText: {
        value: '',
      },
      priceText: {
        value: '',
      },
      status: {
        value: true,
      },
      productImageUrl: {
        value: '',
      },
      productImage: {
        type: '',
        id: 0,
        fileName: '',
        fileType: '',
        thumb: '',
        file: '',
        url: '',
        value: ''
      },
    },
    videos: {
      items: [] as ProductVideo[],
      meta: {} as Meta,
      links: {},
    } as ProductVideosResponse,
    tags: {
      items: [] as ProductTag[],
      meta: {} as Meta,
      links: {},
    } as ProductTagsResponse,
    channels: {
      items: [] as ProductChannel[],
      meta: {} as Meta,
      links: {},
    } as ProductChannelsResponse,
  },
  query: {
    page: 1,
    limit: 10,
    orderDirection: '',
    orderField: '',
    q: '',
    status: '',
    createMax: '',
    createMin: '',
    maxPrice: '',
    minPrice: '',
    updateMax: '',
    updateMin: '',
  },
};

export const productsSlice = createSlice({
  name: 'productState',
  initialState,
  reducers: {
    getProductsList: state => {
      state.query.page = 1;
      state.list = initialState.list;
      state.loading = true;
    },
    getProductListSuccess: (state, action) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },
    delete: (state, action) => { },
    deleteSuccess: (state, action) => {
      // console.log("state", state)
      // console.log("actoion11", action)
      state.list.items = state.list.items.filter(
        item => item.id !== action.payload,
      );
    },
    deleteFailed: (state, action) => { },
    resetModule: state => {
      state.form = initialState.form;
    },
    getProductListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getNextPage: (state, action) => {
      // state.loading = true;
    },
    getNextPageItemsSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setProductHero: (state, action: PayloadAction<Hero>) => {
      state.form.setProduct.hero = action.payload;
    },
    setProductForm: (
      state,
      action: PayloadAction<{
        formName: string;
        name: string;
        value: any;
      }>,
    ) => {
      const { formName, name, value } = action.payload;
      if (formName === 'videos') {
        // set(state.form.videos, `${name}`, value);
        set(state.form[formName], `${name}`, value);
      } else if (formName === 'tags') {
        // set(state.form.tags, `${name}`, value);
        set(state.form[formName], `${name}`, value);
      } else {
        console.log({ action }, 'VALUE');
        set(state.form[formName], `${name}.value`, value);
        set(state.form[formName], `${name}.error`, '');
      }
    },
    setProductFormDeleteLocalVideo: (state, action) => {
      state.form.videos.items.forEach((item, index) => {
        if (item.video.file === action.payload.item.video.file)
          state.form.videos.items.splice(index, 1);
      });
    },
    setFileDetails: (
      state,
      action: PayloadAction<{ name: string; type: string; file: any }>,
    ) => {
      state.form.setProduct.productImage.fileName = action.payload.name;
      state.form.setProduct.productImage.fileType = action.payload.type;
      state.form.setProduct.productImage.file = action.payload.file;
      state.form.setProduct.productImage.error = '';
    },
    setVideoDetails: (state, action) => {
      state.forUpload.file = action.payload.file;
      state.forUpload.fileName = action.payload.fileName;
      state.forUpload.fileType = action.payload.fileType;
      state.forUpload.thumb = action.payload.thumb;
      state.forUpload.fileSize = action.payload.fileSize;
    },
    getProductDetail: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.loading = true;
      }
      state.form = initialState.form;
    },
    resetVideoForm: state => {
      state.forUpload = initialState.forUpload;
      state.form.setVideo = initialState.form.setVideo;
    },
    resetForm: state => {
      state.forUpload = initialState.forUpload;
      state.form = initialState.form;
    },
    resetAssetForm: state => {
      state.forUpload = initialState.forUpload;
      state.form.setVideo.video = initialState.forUpload;
    },
    setProductDetail: (
      state,
      action: PayloadAction<{ errors: FormError[]; errorTabs?: number[]; callback?: () => void }>,
    ) => {
      state.errorTabsIndex = action.payload.errorTabs
      if (action.payload.errors.length) {
        action.payload.errors.forEach(error => {
          set(state.form, error.name, error.error);
        });
      } else {
        state.loading = true;
      }
    },
    setProductDetailErrors: (
      state,
      action: PayloadAction<{ errors: FormError[] }>,
    ) => {
      if (action.payload.errors.length) {
        action.payload.errors.forEach(error => {
          set(state.form, error.name, error.error);
        });
      }
    },
    openModal: (
      state,
      action: PayloadAction<{
        modal: ProductFormModeal;
        item?: ProductVideo;
        index?: null | number;
      }>,
    ) => {
      state.form.model = action.payload.modal;
      if (action.payload.index) {
        state.form.setVideo.index.value = action.payload.index;
      }
      if (action.payload.item) {
        state.form.setVideo.id.value = action.payload.item.id;
        state.form.setVideo.name.value = action.payload.item?.video?.fileName;
        state.form.setVideo.applicableTvPost.value =
          action.payload.item.applicableTvPost;
        state.form.setVideo.status.value = action.payload.item.status;
        state.form.setVideo.eligibleProductDetails.value =
          action.payload.item.eligibleProductDetails;
        state.form.setVideo.video = action.payload.item.video;
        if (action.payload.item.video.sourceUrl) {
          state.form.setVideo.assetUrl.value =
            action.payload.item.video.sourceUrl;
        }
        state.form.setSrt.list.items = action.payload.item.subtitles || [];
      }
      if (state.form.model == undefined) {
        state.form.setVideo.id.value = '';
        state.form.setVideo.name.value = '';
        state.form.setVideo.applicableTvPost.value = false;
        state.form.setVideo.status.value = false;
        state.form.setVideo.eligibleProductDetails.value = false;
        state.form.setVideo.video = {} as Video;
        state.form.setSrt.list.items = [];
      }
    },
    signedUrlRequest: (state, action: PayloadAction<boolean>) => {
      state.isSingedUrlGenerating = action.payload;
    },
    uploadProductMedia: (state, action) => { },
    setProductDetailSuccess: (
      state,
      action: PayloadAction<ProductResponse>,
    ) => {
      state.loading = false;
      state.form.setProduct.merchantId.value = action.payload.merchant == null ? "" : action.payload.merchantId;
      state.form.setProduct.heroId.value = action.payload.heroId;
      state.form.setProduct.rating.value = action.payload.rating;
      state.form.setProduct.promotionText.value = action.payload.promotionText;
      state.form.setProduct.id.value = action.payload.id;
      state.form.setProduct.createdAt.value = action.payload.createdAt;
      state.form.setProduct.updatedAt.value = action.payload.updatedAt;
      state.form.setProduct.name.value = action.payload.name;
      state.form.setProduct.shortDescription.value =
        action.payload.shortDescription;
      state.form.setProduct.longDescription.value =
        action.payload.longDescription;
      state.form.setProduct.price.value = action.payload.price;
      state.form.setProduct.priceText.value = action.payload.priceText;
      state.form.setProduct.salePrice.value = action.payload.salePrice;
      state.form.setProduct.salePriceText.value = action.payload.salePriceText;
      state.form.setProduct.status.value = action.payload.status;
      state.form.setProduct.starRating.value = action.payload.rating;
      state.form.setProduct.productImageUrl.value = action.payload.image?.url;
      state.form.setProduct.merchant = action.payload.merchant;
      state.form.setProduct.hero = action.payload.hero;
      state.form.setProduct.salePriceCurrency.value =
        action.payload.salePriceCurrency;
      state.form.setProduct.priceCurrency.value = action.payload.priceCurrency;
      state.form.setProduct.productNotes.value = action.payload.productNotes;
      state.form.setProduct.sku.value = action.payload.sku;
      state.form.text.productId.value = action.payload.id;
    },
    updateProductRow: (state, action) => {
      state.loading = false;
      const index = state.list.items.findIndex(
        product => product.id === action.payload.id,
      );
      if (index >= 0) {
        state.list.items[index] = action.payload;
      }
    },
    setProductDetailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearVideos: (state) => {
      state.form.videos = initialState.form.videos;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.list.items = initialState.list.items;
        state.list.links = initialState.list.links;
        state.list.meta = initialState.list.meta;
        state.query.page = 1;
      }
      set(state.query, name, value);
    },
    clearCurrencyErrors: state => {
      set(state.form.setProduct, `priceCurrency.error`, '');
      set(state.form.setProduct, `salePriceCurrency.error`, '');
    },
    createProduct: state => {
      state.loading = true;
    },
    uploadCancelled: (state, action) => {
      state.loading = false;
    },
    uploadError: (state, action) => {
      state.loading = false;
    },
    setProductVideoSuccess: (
      state,
      action: PayloadAction<ProductVideosResponse>,
    ) => {
      state.loading = false;
      state.form.videos = action.payload;
      state.form.videos.items = state.form.videos.items
        .sort((a, b) => a.order - b.order)
        .map((item, index) => {
          item.order = index;
          return item;
        });
    },
    setProductChannel: (
      state,
      action: PayloadAction<{ channel: Channel; type: 'ADD' | 'DELETE' }>,
    ) => {
      if (action.payload.type == 'ADD') {
        const channelIndex = state.form.channels.items.findIndex(
          c => c.channel.id == action.payload.channel.id,
        );
        if (channelIndex > -1) {
          const channel = state.form.channels.items[channelIndex];
          channel.isDeleted = false; //restore
        } else {
          state.form.channels.items.unshift({
            channel: action.payload.channel,
          } as ProductChannel);
        }
      }
      if (action.payload.type == 'DELETE') {
        const channelIndex = state.form.channels.items.findIndex(
          c => c.channel.id == action.payload.channel.id,
        );
        if (channelIndex > -1) {
          const channel = state.form.channels.items[channelIndex];
          if (channel.id) {
            channel.isDeleted = true; //deleted flag
          } else {
            state.form.channels.items.splice(channelIndex, 1); // if recently added can be removed from array
          }
        }
      }
    },
    setVideoFormErrors: (state, action: PayloadAction<Array<FormError>>) => {
      if (action.payload.length) {
        action.payload.forEach(error => {
          set(state.form.setVideo, error.name, error.error);
        });
      }
    },
    setProductTag: (
      state,
      action: PayloadAction<{ tag: ProductTag; type: 'ADD' | 'DELETE' }>,
    ) => {
      if (action.payload.type == 'ADD') {
        const tagIndex = state.form.tags.items.findIndex(
          c => c.tag.id == action.payload.tag.id,
        );
        if (tagIndex > -1) {
          const tag = state.form.tags.items[tagIndex];
          tag.isDeleted = false; //restore
        } else {
          state.form.tags.items.unshift(action.payload.tag as ProductTag);
        }
      }
      if (action.payload.type == 'DELETE') {
        const tagIndex = state.form.tags.items.findIndex(
          c => c.tag.id == action.payload.tag.id,
        );
        if (tagIndex > -1) {
          const tag = state.form.tags.items[tagIndex];
          if (tag.id) {
            tag.isDeleted = true; //deleted flag
          } else {
            state.form.tags.items.splice(tagIndex, 1); // if recently added can be removed from array
          }
        }
      }
    },
    setProductVideo: (
      state,
      action: PayloadAction<{ video: ProductVideo; index?: number }>,
    ) => {
      if (action.payload?.index) {
        const video = state.form.videos.items[action.payload.index];
        video.applicableTvPost = action.payload.video.applicableTvPost;
        video.status = action.payload.video.status;
        video.eligibleProductDetails =
          action.payload.video.eligibleProductDetails;
        video.video = action.payload.video.video;
        video.subtitles = state.form.setSrt.list.items;
        return;
      }
      if (action.payload.video?.id) {
        const video = state.form.videos.items.find(
          f => f.id == action.payload.video.id,
        );
        if (!video) {
          return;
        }
        video.applicableTvPost = action.payload.video.applicableTvPost;
        video.status = action.payload.video.status;
        video.eligibleProductDetails =
          action.payload.video.eligibleProductDetails;
        video.video = action.payload.video.video;
        video.subtitles = state.form.setSrt.list.items;
        return;
      }
      state.form.videos.items.push({ ...action.payload.video, subtitles: state.form.setSrt.list.items });
      state.form.videos.items = state.form.videos.items.map((item, index) => {
        item.order = index;
        return item;
      });
    },

    setProductVideoOrder: (
      state,
      action: PayloadAction<{ newIndex: number; oldIndex: number }>,
    ) => {
      state.form.videos.items = arrayMoveImmutable(
        state.form.videos.items,
        action.payload.oldIndex,
        action.payload.newIndex,
      );
      state.form.videos.items = state.form.videos.items.map((item, index) => {
        item.order = index;
        return item;
      });
    },
    uploadStarted: (state, action) => {
      state.loading = false;
      state.form.setVideo.isUploading.value = action.payload.id;
      state.form.setVideo.checkStatusTime.value = Date.now();
    },
    setProductVideoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setProductTagsSuccess: (
      state,
      action: PayloadAction<ProductTagsResponse>,
    ) => {
      state.loading = false;
      state.form.tags = action.payload;
    },
    setProductTagsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    checkUploadStatus: (state, action) => { },

    setProductChannelsSuccess: (
      state,
      action: PayloadAction<ProductChannelsResponse>,
    ) => {
      state.loading = false;
      state.form.channels = action.payload;
    },
    setProductChannelsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    toggleProduct: (state, action) => {
      console.log(action, 'from slice');
      // state.loading = true
    },
    toggleProductSuccess: (
      state,
      action: PayloadAction<{ id: number | string }>,
    ) => {
      // state.loading = false
      const index = state.list.items.findIndex(x => x.id === action.payload.id);
      state.list.items[index].status = !state.list.items[index].status;
    },
    toggleProductFail: (state, action) => {
      // state.loading = false
    },
    toggleBulk: (
      state,
      action: PayloadAction<{
        ids: string[];
        active: 'TRUE' | 'FALSE';
        callBack: () => void;
      }>,
    ) => { },
    toggleBulkSuccess: (
      state,
      action: PayloadAction<{ ids: string[]; active: 'TRUE' | 'FALSE' }>,
    ) => {
      action.payload.ids.map(item => {
        const index = state.list.items.findIndex(x => x.id === item);
        state.list.items[index].status =
          action.payload.active === 'TRUE' ? true : false;
      });
    },
    toggleBulkFail: (state, action) => { },
    deleteBulk: (state, action) => { },
    deleteBulkSuccess: (state, action: PayloadAction<{ ids: string[] }>) => {
      action.payload.ids.map(item => {
        state.list.items = state.list.items.filter(x => x.id !== item);
      });
    },
    deleteBulkFail: (state, action) => { },
    deleteProduct: (state, action) => { },
    deleteProductSuccess: (
      state,
      action: PayloadAction<{ id: string | number }>,
    ) => {
      state.list.items = state.list.items.filter(
        x => x.id !== action.payload.id,
      );
    },
    deleteProductFail: (state, action) => { },

    startUploadFromUrl: (state, action) => { },
    uploadFromUrlSuccess: (state, action) => {
      state.form.setVideo.assetUrl.value = '';
      state.form.setVideo.isUploading.value = '';
      state.form.setVideo.checkStatusTime.value = '';
    },
    uploadFromUrlFailed: (state, action) => {
      state.loading = false;
      state.form.setVideo.isUploading.value = '';
    },
    setCheckStatusTime: (state, action) => {
      state.form.setVideo.checkStatusTime.value = action.payload;
    },
    getCurrencyOptions: (state, action) => {
      state.loading = true;
    },
    getCurrencyOptionsSuccess: (state, action: PayloadAction<Currencies>) => {
      state.loading = false;
      state.currencyOptions = action.payload;
    },
    getCurrencyOptionsFailed: (state, action) => {
      state.loading = false;
    },
    toggleSrtForm: state => {
      state.form.setSrt.open = !state.form.setSrt.open;
    },
    setSrtToUpload: (state, action: PayloadAction<File | null>) => {
      state.form.setSrt.forUpload = action.payload;
    },
    createSrt: (state, action) => {
      state.loading = true;
    },
    createSrtSuccess: (state, action) => {
      state.loading = false;
    },
    createSrtFailed: (state, action) => {
      state.loading = false;
    },
    deleteSrt: (state, action: PayloadAction<SrtItem>) => {
      state.loading = false;
      state.form.setSrt.list.items.forEach(item => {
        if (item.id == action.payload.id) {
          item.isDeleted = true;
        }
      })
    },
    editSrt: (state, action: PayloadAction<SrtItem & {index: number}>) => {
      state.form.setSrt.editIndex = action.payload.index;
      state.form.setSrt.forUpload.name = action.payload.asset.fileName;
      state.form.setSrt.language.value = action.payload.language;
      state.form.setSrt.id.value = action.payload.id;
      state.form.setSrt.open = true;
    },
    newSrtUploaded: (state, action: PayloadAction<SrtItem>) => {
      state.loading = false;
      const { language, id, ...asset } = action.payload;
      const item = {
        language: language,
        asset,
        id: state.form.setSrt.id.value,
        createdAt: '',
        updatedAt: '',
      } as unknown as  SrtItem
      const index: number = state.form.setSrt?.editIndex as number
      if( index > -1) {
        state.form.setSrt.list.items[index] = item;
      }else{
        state.form.setSrt.list.items.push(item);
      }

    },
    resetSrtForm: state => {
      state.form.setSrt.file = initialState.form.setSrt.file;
      state.form.setSrt.forUpload = initialState.form.setSrt.forUpload;
      state.form.setSrt.language = initialState.form.setSrt.language;
      state.form.setSrt.open = initialState.form.setSrt.open;
      state.isSingedUrlGenerating = false;
    },
    addTextData: state => {
      const { label, shortText, longText } = state.form.text;
      const newText: TextListItem = {
        label: label.value,
        shortText: shortText.value,
        longText: longText.value,
        createdAt: new Date().toISOString(),
      };
      // state.form.text.list.items.push(newText);
    },
    resetTextForm: state => {
      state.form.text.id = initialState.form.text.id;
      state.form.text.label = initialState.form.text.label;
      state.form.text.longText = initialState.form.text.longText;
      state.form.text.shortText = initialState.form.text.shortText;
    },
    setTextFormValues: (state, action: PayloadAction<ProductText>) => {
      const { id, label, shortDescription, text, createdAt } = action.payload;
      state.form.text.id.value = id;
      state.form.text.label.value = label;
      state.form.text.shortText.value = shortDescription;
      state.form.text.longText.value = text;
      state.form.text.createdAt.value = createdAt;
    },
  },
});

export const { actions, name: sliceKey, reducer } = productsSlice;
