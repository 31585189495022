export interface IAuditLogs {
  loading: boolean;
  list: {
    items: Array<any>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  query: {
    limit: number;
    page: number;
    q: string;
    orderField: string;
    orderDirection: string;level: string;
  };
}

export const initialState: IAuditLogs = {
  loading: false,
  query: {
    limit: 10,
    page: 1,
    q: '',
    orderField: '',
    orderDirection: '',
    level: "",
  },
  list: {
    items: [],
    links: {
      first: '',
      last: '',
      next: '',
      previous: '',
    },
    meta: {
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      itemCount: 0,
    },
  },
};
