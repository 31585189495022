import { IBaseListFilter, IListType } from 'app/containers/types';

export interface DeviceFilterQueryType extends IBaseListFilter {
  status: string,
  orderField: string,
  orderDirection: string,
  updateMin: string,
  updateMax: string,
}
export interface UserDevicesState {
  loading: boolean,
  list: IListType,
  query: DeviceFilterQueryType,
}

export const initialState: UserDevicesState =  {
  list: {
    items: [],
    links: {
      last: "",
      first: "",
      next: "",
      previous: "",
    },
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0
    },
  },
  loading: false,
  query: {
    updateMax: "",
    updateMin: "",
    limit: 10,
    orderDirection: "",
    orderField: "",
    page: 0,
    q: "",
    status: "",
  }
}