import DataTable from 'app/components/DataTable';
import TableLoader from 'app/components/TableLoader';
import { selectSearch } from 'app/containers/Dashboard/selector';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getFormattedDateTime } from 'utils/helpers';

import { makeStyles } from '@mui/styles';

import { actions as dashboardActions } from '../../../../../Dashboard/slice';
import { selectLoading, selectPostsQuery, selectWatchHistoryList } from '../../redux/selector';
import { actions } from '../../redux/slice';

const useStyles = makeStyles({
  tableRoot: {
    maxHeight: 'calc(100vh - 148px)',
    overflowY: 'auto',
  },
  tableRow: {
    border: '1px solid #00000010 !important',
  },
  tableCell: {
    borderTop: '1px solid #00000010 !important',
    paddingBottom: 0,
  },
});

type Props = {};

const WatchHistory = (props: Props) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const searchText = useSelector(selectSearch);
  const { items, links } = useSelector(selectWatchHistoryList);
  const loading = useSelector(selectLoading);
  const { orderDirection, orderField, q, status } =
    useSelector(selectPostsQuery);

  useEffect(() => {
    dispatch(dashboardActions.setRoute('Watch History'));
    if (id) {
      dispatch(actions.setPostsQuery({ name: 'userId', value: id }));
    }
  }, []);


  useEffect(() => {
    dispatch(actions.setPostsQuery({ name: 'q', value: searchText }));
  }, [searchText]);

  useEffect(() => {
    dispatch(actions.getWatchHistory());
  }, [ q, status, orderField, orderDirection]);

  const columns = [
    {
      id: 'key',
      label: 'ID',
      sortValue: 'selector.id',
      minWidth: 20,
      format: value => (
        value?.key
      ),
    },
    {
      id: 'postId',
      label: 'Post Id',
      sortValue: 'post.id',
      minWidth: 20,
      format: value => (
        <Link target={'_blank'} to={`/tv-post/${value?.postId}/view`}>
          {value?.post?.key}
        </Link>
      ),
    },
    {
      id: 'name',
      label: 'Name',
      sortValue: 'selector.name',
      minWidth: 100,
      format: value => value?.post?.name || '-',
    },
    {
      id: 'createdAt',
      label: 'Created At',
      sortValue: 'selector.createdAt',
      minWidth: 100,
      format: value => getFormattedDateTime(value.createdAt) || '-',
    },
  ];

  const setQuery = (name: string, value: any) => {
    dispatch(actions.setPostsQuery({ name, value }));
  };

  const hasMoreItems = !!links.next;

  const getNextPage = () => {
    //   console.log('reqChangeActions.getNextPageItems(links.next)==');
    if (!loading) {
      dispatch(actions.getNextWatchHistory(links.next));
    }
  };


  const styles = useStyles();

  return (
    <div className={styles.tableRoot}>
      <InfiniteScroll
        loader={<TableLoader />}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        useWindow={false}
      >
        <DataTable
          loading={loading && items.length === 0}
          orderDirection={orderDirection}
          orderField={orderField}
          columns={columns}
          rows={items}
          setQuery={setQuery}
        />
      </InfiniteScroll>
    </div>
  );
};

export default WatchHistory;
