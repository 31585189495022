import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    background: "#000",
    borderRadius: "5px 5px 0px 0px",
    padding: "10px 24px",
    color: "#FFF",
    display: 'inline',
    width: 'auto'
  }
}))