import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    minHeight: '150px',
    borderRadius: "7px 7px 0px 0px",
    paddingRight: 5,
    border: 'none',

    overflow: 'auto',
    "&::-webkit-scrollbar": {
      width: 13,
      height: 8,
      padding: '5px 0px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#3C4858',
      boxShadow: 'inset 4px 4px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#222',
      borderRadius: 20,
      border: '3px solid #3C4858',
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#222',
    }
  },
  tableSort: {
    // backgroundColor: '#919',
    color: '#bdbdbd !important',
    "& svg": {
      // backgroundColor: '#911',
      color: '#bdbdbd !important',
    }
  },
  headerRow: {
    "& th:first-child": {
      borderTopLeftRadius: 6,
    },
    "& th:last-child": {
      borderTopLeftRadius: 6,
    }
  },
  cellHead: {
    position: 'sticky',
    padding: '14px 14px 17px 16px',
    fontSize: 14,
    border: 'none',
    fontWeight: 400,
    lineHeight: "16.71px",
    textAlign: 'unset',
    color: '#8F8E8E',
    background: "#F7F7FA",
    whiteSpace: 'nowrap',
    '&:last-child': {
      borderTopRightRadius: 7,
    },
    '&:first-of-type': {
      borderLeft: "transparent",
    },
    '&.makeStyles-cellHead-33': {
      backgroundColor: 'transparent',
      color: '#BDBDBD',
    },
  },
  tableBody: {
    backgroundColor: '#fff',
    borderRadius: 6,
    border: 'none',
    "& .MuiTableCell-root": {
      border: 'none',
      backgroundColor: 'transparent',
      "& .css-xemq8y-MuiTableRow-root:nth-of-type(odd)": {
        backgroundColor: 'transparent',
      }
    },
  },
  headerCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
    marginRight: 6
  },
  customCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
  }
})