import { FormElement, IBaseListFilter, IListType, RootQuery } from 'app/containers/types';

export enum PermissionValue {
  NO_ACCESS = 'NO_ACCESS',
  READ_ONLY = 'READ_ONLY',
  FULL_ACCESS = 'FULL_ACCESS',
}


export interface MerchantObject {
  id: number | null;
  productCount: number | null;
  userId: number | null;
  businessName: string | null;
  description: string | null;
  businessType: string | null;
  websiteUrl: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  logoUrl: string | null;
  user: {
    id: number | null;
    firstName: string | null;
    lastName: string | null;
    assignedGroup: string | null;
    mobileNumber: string | null;
    countryCode: string | null;
    language: string | null;
    email: string | null;
    isActive: boolean | null;
    role: string | null;
    lastLogin: string | null;
    dob: string | null;
    mac: string | null;
    ip: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  address: { address: string };
  businessAddress: { address: string };
  socialAccountDetails: {
    insta: string;
    fb: string;
  };
  contactPerson: {
    phoneNumber: string;
    name: string;
  };
  image: any;
}

export type Image = {
  type: string;
  id: number;
  fileName: string;
  fileType: string;
  thumb: string;
  file: any;
  url: string;
  error?: any;
};

export interface MerchantForm {
  id: FormElement;
  businessName: FormElement;
  description: FormElement;
  address: FormElement;
  businessType: FormElement;
  logoUrl: FormElement;
  websiteUrl: FormElement;
  businessAddress: FormElement;
  socialAccountDetails: {
    insta: FormElement;
    fb: FormElement;
  };
  contactPerson: {
    phoneNumber: FormElement;
    name: FormElement;
  };
  user: {
    firstName: FormElement;
    lastName: FormElement;
    mobileNumber: FormElement;
    email: FormElement;
    assignedGroup: FormElement;
  };
  createdAt: FormElement;
  updatedAt: FormElement;
  image: Image;
}

export interface MerchantQuery extends RootQuery {
  q: string;
  page: number;
  createMin: string;
  createMax: string;
  updateMin: string;
  updateMax: string;
  businessType: Array<string>;
}

export type Permissions = {
  permissions: any;
  merchantUsers: Array<any>;
  selectedMerchantUsers: Array<any>;
};

export type PermissionGroupItem = {
  id: number;
  merchantId: null | number;
  name: string;
  permissions: {
    DASHBOARD_PERFORMANCE: PermissionValue;
    DASHBOARD_USER_ANALYTICS: PermissionValue;
    DASHBOARD_USER_CONTENT: PermissionValue;
    MERCHANT_ALL: PermissionValue;
    MERCHANT_PERMISSION_GROUP: PermissionValue;
    MERCHANT_USERS: PermissionValue;
    MERCHANT_CHANNELS: PermissionValue;
    MERCHANT_HEROS: PermissionValue;
    MERCHANT_LOGS: PermissionValue;
    MERCHANT_PRIVACY_POLICY: PermissionValue;
    MERCHANT_PRODUCTS: PermissionValue;
    MERCHANT_TAGS: PermissionValue;
    MERCHANT_TERMS_OF_SERVICE: PermissionValue;
    MERCHANT_TV_POST: PermissionValue;
  };
  createdAt: Date;
  updatedAt: Date | null;
};
export interface PermissionGroupQuery extends IBaseListFilter {}

export type PermissionGroup = {
  permissions: Permissions;
  options: any;
  list: IListType;
  form: Permissions;
  query: PermissionGroupQuery;
};

export const defaultPermissionGroup: PermissionGroup = {
  permissions: {
    permissions: {},
    selectedMerchantUsers: [],
    merchantUsers: [],
  },
  options: {},
  query: {
    limit: 10,
    page: 0,
    q: '',
  },
  list: {
    items: [],
    links: { first: '', last: '', next: '', previous: '' },
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
  },
  form: {
    permissions: {},
    merchantUsers: [],
    selectedMerchantUsers: [],
  },
};

// export type PermissionGroupForm = {
//   Object.assign({}, Object.keys(defaultPermissionGroup.form)).keys(defaultPermissionGroup.form).forEach(i => {

//   })
// };
export interface MerchantUsersFilter extends IBaseListFilter {
  role: 'MERCHANT_USER';
  groupId: Array<any>;
  orderField: string;
  orderDirection: string;
}

export type MerchantUserForm = {
  id: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  mobileNumber: FormElement;
  email: FormElement;
  permissionGroupId: FormElement;
  role: FormElement;
  merchantUsers: FormElement;
  selectedMerchants: FormElement;
};

export type  MerchantUserAssociation = {
  taxId: string;
  merchantId: string;
  id: string;
  userId: string;
  assignedGroup: string;
  assignedRole: string
  merchant: MerchantObject;
}
export type MerchantUserAssociationForm = {
  id: string;
  taxId: FormElement;
  merchantId: string;
  userId: string;
  assignedGroup: FormElement;
  assignedRole: FormElement
}

export const defaultMerchantUserForm: MerchantUserForm = {
  id: { value: '' },
  firstName: { value: '' },
  lastName: { value: '' },
  mobileNumber: { value: '' },
  merchantUsers: { value: [] },
  permissionGroupId: { value: '' },
  role: { value: '' },
  email: { value: '' },
  selectedMerchants: { value: [] },
};

export type MerchantUsers = {
  list: IListType;
  query: MerchantUsersFilter;
  form: MerchantUserForm;
  openAddMerchantModal: boolean;
  openEditMerchantModal: boolean;
  openTaxIdForm: MerchantUserAssociationForm;
};

export const defaultMerchantUsers: MerchantUsers = {
  list: {
    items: [],
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 15,
      totalItems: 0,
      totalPages: 0,
    },
    links: { first: '', last: '', next: '', previous: '' },
  },
  openAddMerchantModal: false,
  openEditMerchantModal: false,
  form: defaultMerchantUserForm,
  query: {
    limit: 15,
    page: 0,
    q: '',
    role: 'MERCHANT_USER',
    groupId: [],
    orderField: '',
    orderDirection: '',
  },
  openTaxIdForm: {
    id: '',
    merchantId: '',
    userId: '',
    assignedRole: {value: ''},
    assignedGroup: {value: ''},
    taxId: {value: ''}
  },
};

export type BusinessType = {
  name: string;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type BusinessTypeOptions = Array<BusinessType>;

export const businessTypeOptions: BusinessTypeOptions = [];

export type Merchant = {
  merchantList: MerchantObject[];
  form: MerchantForm;
  loading: boolean;
  error: string | null;
  query: MerchantQuery;
  permissionGroup: PermissionGroup;
  merchantUsers: MerchantUsers;
  businessTypeOptions: BusinessTypeOptions;
  list: {
    items: Array<MerchantObject> | Array<any>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      next: string;
      previous: string;
    };
  };
};
