import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Box } from '@mui/material';

import { actions as dashboardActions } from '../../../Dashboard/slice';
import TvDevicesDetail from './Detail';
import TvDevicesHome from './Home';
import { useDeviceSaga } from './redux/saga';
import { reducer, sliceKey } from './redux/slice';

type Props = {};

const TvDeviceRoutes = (props: Props) => {

    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({key: sliceKey, saga: useDeviceSaga})
    const match = useMatch('/tv-users/devices/:id/:action');
    const createMatch = useMatch('/tv-users/devices/new');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(dashboardActions.setRoute('Devices'));
    }, []);

    // console.log('From route hook', match?.params.id, createMatch);
    // useEffect(() => {
    //     if (createMatch && !match) {
    //         // dispatch(
    //         //     dashboardActions.setRoute(<RouteTitle name={'Create New TV Post'} />),
    //         // );
    //         return;
    //     }
    //     if (!createMatch && !match?.params.id) {
    //         // dispatch(dashboardActions.setRoute('TV Posts'));
    //         return;
    //     }
    //     if (match?.params.id) {
    //         if (match.params.action === 'view') {
    //             // dispatch(
    //             //     dashboardActions.setRoute(<RouteTitle name={'View TV Post'} />),
    //             // );
    //             return;
    //         }
    //         if (match.params.action === 'edit') {
    //             // dispatch(
    //             //     dashboardActions.setRoute(<RouteTitle name={'Edit TV Post'} />),
    //             // );
    //             return;
    //         }
    //     }
    // }, [match, createMatch]);
    return (
        <>
            <Box sx={{ display: match?.params.id || createMatch ? 'none' : 'block' }}>
                <TvDevicesHome />
            </Box>
            {(match?.params.id || createMatch) && (
                <>
                    <TvDevicesDetail />
                </>
            )}
        </>
    );
};

export default TvDeviceRoutes;
