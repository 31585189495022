import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './types';

export const pageSlice = createSlice({
  name: 'pageState',
  initialState,
  reducers: {
    setForm: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, '');
    },
    getContent: (state, action) => {
      state.loading = true;
    },
    getContentSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.form.content.value = action.payload.content;
      state.form.id.value = action.payload.id;
    },
    getContentFailed: (state, action) => {
      state.loading = false;
    },
    resetPage: state => {
      state.form.content.value = '';
    },
    updateContent: (state, action) => {
      state.loading = true;
    },
    updateContentSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    updateContentFailed: (state, action) => {
      state.loading = false;
    },
  },
});
export const { actions, reducer, name: sliceKey } = pageSlice;
