import { FormError } from 'app/containers/types';

import { IFeedConfigurationForm } from '../../redux/types';

export function validate(form: IFeedConfigurationForm): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.ALL_ADDED_FREQUENCY_BY_CTA.value) {
    errors.push({
      name: 'ALL_ADDED_FREQUENCY_BY_CTA',
      error: 'Field is required',
    });
  }
  if (!form.ALL_ADDED_FREQUENCY_BY_VIEW.value) {
    errors.push({
      name: 'ALL_ADDED_FREQUENCY_BY_VIEW',
      error: 'Field is required',
    });
  }
  if (!form.ALL_ADDED_FREQUENCY_BY_WISHLIST.value) {
    errors.push({
      name: 'ALL_ADDED_FREQUENCY_BY_WISHLIST',
      error: 'Field is required',
    });
  }
  if (!form.FEED_ENABLED.value) {
    errors.push({
      name: 'FEED_ENABLED',
      error: 'Field is required',
    });
  }

  if (!form.FEED_PAGING_SIZE.value) {
    errors.push({
      name: 'FEED_PAGING_SIZE',
      error: 'Field is required',
    });
  }
  if (!form.FEED_SIZE.value) {
    errors.push({
      name: 'FEED_SIZE',
      error: 'Field is required',
    });
  }
  if (!form.FEED_TTL.value) {
    errors.push({
      name: 'FEED_TTL',
      error: 'Field is required',
    });
  }
  if (!form.PCT_ENGAGED.value) {
    errors.push({
      name: 'PCT_ENGAGED',
      error: 'Field is required',
    });
  }
  if (!form.PCT_NEW.value) {
    errors.push({
      name: 'PCT_NEW',
      error: 'Field is required',
    });
  }
  if (!form.TIME_TO_WATCH_TO_IMPLY_LIKE.value) {
    errors.push({
      name: 'TIME_TO_WATCH_TO_IMPLY_LIKE',
      error: 'Field is required',
    });
  }
  if (!form.USER_ADDED_FREQUENCY_BY_CTA.value) {
    errors.push({
      name: 'USER_ADDED_FREQUENCY_BY_CTA',
      error: 'Field is required',
    });
  }
  if (!form.USER_ADDED_FREQUENCY_BY_VIEW.value) {
    errors.push({
      name: 'USER_ADDED_FREQUENCY_BY_VIEW',
      error: 'Field is required',
    });
  }
  if (!form.USER_ADDED_FREQUENCY_BY_WISHLIST.value) {
    errors.push({
      name: 'USER_ADDED_FREQUENCY_BY_WISHLIST',
      error: 'Field is required',
    });
  }
  if (!form.VIDEO_WATCHED_TO_IMPLY_ENGAGED.value) {
    errors.push({
      name: 'VIDEO_WATCHED_TO_IMPLY_ENGAGED',
      error: 'Field is required',
    });
  }

  return errors;
}
