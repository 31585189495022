import queryString from 'query-string';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { getDefaultHeaders, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { actions as dashboardActions } from '../Dashboard/slice';
import { Tag } from '../Tags/types';
import { selectCategoryQuery, selectForm } from './selector';
import { actions } from './slice';
import { Category, CategoryForm, CategoryPageForm } from './types';

const apiUrl = `${API_URL}/categories`;

// import { request } from 'utils/request';

/**
 * Github repos request/response handler
 */

export function* deleteRow(action) {
  try {
    const options = {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${apiUrl}/${action.payload.id}`, options);
    console.log('delete response', response);
    if (response && response.message === 'MESSAGES.DELETE_SUCCESS') {
      yield put(actions.deleteSuccess(action.payload.id));
      yield call(action.payload?.callBack);
    }
  } catch (e) {
    console.log(e, 'Something went wrong');
  }
}

export function* update(action) {
  try {
    const { createCategory } = yield select(selectForm);
    const { name } = createCategory;
    const category = {
      name: name.value,
      categoryId: action.payload.id,
    };
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify(category),
    };
    const response = yield request(`${apiUrl}/${action.payload.id}`, options);
    console.log('updated', response);
    yield put(actions.updateSuccess(response));
    yield put(actions.resetCategoryForm());
    yield call(action.payload?.callBack);
  } catch (e: any) {
    try {
      const error = JSON.parse(e.message);
      yield put(actions.createCategoryFailure(error.message));
    } catch (error) {
      yield put(actions.createCategoryFailure('Something Went Wrong'));
    }

    console.log(e);
  }
}

export function* create(action) {
  yield delay(500);
  try {
    const { createCategory } = yield select(selectForm);
    const { name } = createCategory;
    const category: any = {
      name: name.value,
      // categoryId:
    };
    const options = {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(category),
    };
    const response = yield request(apiUrl, options);
    console.log('create hero response', response);
    yield put(actions.createCategorySuccess(response));
    yield put(actions.resetCategoryForm());
    yield call(action.payload?.callBack);
  } catch (e: any) {
    try {
      console.log(e);
      const error = JSON.parse(e.message);
      yield put(actions.createCategoryFailure(error.message));
    } catch (error) {
      yield put(actions.createCategoryFailure('Something Went Wrong'));
    }
  }
}

export function* createTag() {
  try {
    const { createTag }: CategoryPageForm = yield select(selectForm);
    const { categoryId, name } = createTag;
    const tag = {
      name: name.value,
      categoryId: categoryId.value,
      status: true,
    };
    const options = {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(tag),
    };
    const response = yield request(`${API_URL}/tags`, options);
    if (response && response.id) {
      yield put(actions.getCategoryById(response.categoryId));
      yield put(actions.setOpenTagForm());
      yield put(actions.resetTagForm());
      // yield put(actions.createTagSuccess(response));
    }
    // console.log(response, 'tag response');
  } catch (e) {
    // console.log(e, 'Something went wrong');
    yield put(actions.createTagFailed(''));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  }
}

export function* deleteTag(action) {
  try {
    const options = {
      method: 'DELETE',
    };
    const response = yield request(
      `${API_URL}/tags/${action.payload.id}`,
      options,
    );
    console.log(action.payload)
    if (response.message === 'MESSAGES.DELETE_SUCCESS') {
      yield put(actions.getCategoryById(action.payload.categoryId));
      // yield put(actions.deleteTagSuccess(action.payload));
      yield call(action.payload.callBack);
    }
    // console.log(response, 'Delete Success');
  } catch (e) {
    yield put(actions.deleteTagFailed());
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  }
}

export function* getCategoryById(action) {
  try {
    const response: Category = yield request(
      `${API_URL}/categories/${action.payload}`,
    );
    if (response && response.id) {
      yield put(actions.getCategoryByIdSuccess(response));
      // yield put(actions.setOpenTagForm());
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* getTagById(action) {
  try {
    const response: Tag = yield request(`${API_URL}/tags/${action.payload}`);
    if (response && response.id) {
      yield put(actions.getTagByIdSuccess(response));
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateTag() {
  try {
    const { createTag, tag }: CategoryPageForm = yield select(selectForm);
    const { categoryId: previousCategoryId } = tag as any;
    const { id, name, categoryId, status } = createTag as any;
    if (id?.value) {
      const tag = {
        name: name.value,
        categoryId: categoryId.value,
        status: true,
      };
      const options = {
        method: 'PUT',
        headers: getDefaultHeaders(),
        body: JSON.stringify(tag),
      };
      const response: Tag = yield request(
        `${API_URL}/tags/${id?.value}`,
        options,
      );
      if (response && response.id) {
        // yield put(actions.getList());
        yield put(actions.getCategoryById(previousCategoryId.id));
        yield put(actions.getCategoryById(response.categoryId));
        yield put(actions.setOpenTagForm());
        yield put(actions.resetTagForm());
        // yield put(actions.updateTagSuccess(response));
      }
      // console.log(response, 'tag response');
    }
  } catch (e) {
    // console.log(e, 'Something went wrong');
    yield put(actions.updateTagFailed());
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  } finally {
  }
}

export function* getList() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectCategoryQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(`${apiUrl}?${queries}`, options);
    if (response && response.items) {
      yield put(actions.getListSuccess(response));
    } else {
      yield put(actions.getListFailed('Something went wrong'));
    }
  } catch (e) {
    yield put(actions.getListFailed('Something went wrong'));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  }
}

export function* getNextPage(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const { orderField, orderDirection, q, status } = yield select(
      selectCategoryQuery,
    );
    const requestQuery = {
      ...(status && {
        status,
      }),
      ...(q && {
        q,
      }),
      ...(orderField && {
        orderField,
      }),
      ...(orderDirection && {
        orderDirection,
      }),
    };
    const queries = queryString.stringify({
      ...requestQuery,
    });
    const response = yield request(
      action?.payload + `${queries && '&' + queries}`,
      options,
    );
    if (response.items) {
      yield put(actions.getNextPageSuccess(response));
    }
    console.log(response, 'Response here');
  } catch (e) {
    console.log(e, 'error here');
  }
}

export function* createCategoryRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getNextPage);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.createTag.type, createTag);
  yield takeLatest(actions.updateTag.type, updateTag);
  yield takeLatest(actions.deleteTag.type, deleteTag);
  yield takeEvery(actions.getCategoryById.type, getCategoryById);
}
