import React, { ReactNode } from 'react';

import { Box, BoxProps, Typography } from '@mui/material';

import SettingsSectionTable from '../SettingsSectionTable';
import { useSettingsSectionStyles } from './styles';

interface SettingSectionProps extends BoxProps {
  heading?: ReactNode;
  headings: Array<any>;
}

const SettingsSection = (props: SettingSectionProps) => {
  const styles = useSettingsSectionStyles();
  return (
    <Box pt={3} {...props}>
      {props.heading && (
        <Typography className={styles.heading}>{props.heading}</Typography>
      )}
      <SettingsSectionTable headings={props.headings}>
        {props.children}
      </SettingsSectionTable>
    </Box>
  );
};

export default SettingsSection;
