import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { FormError } from '../types';
import { Tag, TagState } from './types';

export const initialState: TagState = {
  loading: false,
  error: '',
  category: {
    meta: {},
    links: {},
    items: [],
  },
  query: {
    categoryId: '',
    limit: 10,
    orderDirection: '',
    orderField: '',
    page: 1,
    q: '',
  },
  list: {
    items: [],
    links: { first: '', last: '', next: '', previous: '' },
    meta: {
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      itemCount: 0,
    },
  },
  form: {
    categoryId: {
      value: '',
    },
    id: {
      value: '',
    },
    name: {
      value: '',
    },
    status: {
      value: '',
    },
  },
  openTagForm: false,
  openDelete: false,
};

export const tagsSlice = createSlice({
  name: 'tagsState',
  initialState,
  reducers: {
    setOpenTagForm: (state, action: PayloadAction<boolean>) => {
      state.openTagForm = action.payload;
      state.form = initialState.form;
    },
    setTagForm: (
      state,
      action: PayloadAction<{ name: string; value: any }>,
    ) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, '');
    },
    setTagQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.list = initialState.list;
        state.query.page = 1;
      }
      set(state.query, name, value);
    },
    getList: (state, action) => {
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      // console.log(action.payload, 'list here');
      state.loading = false;
      console.log(action.payload);
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },
    getListFailed: (state, action) => {
      state.loading = false;
    },
    getNextPage: (state, action) => {
      // state.loading = true;
    },
    getNextPageSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>,
    ) {
      action.payload.errors.forEach(error => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state, action: PayloadAction<Tag>) => {
      state.loading = false;
      state.list.items = [action.payload, ...state.list.items];
      state.openTagForm = false;
    },
    createFailed: state => {
      state.loading = false;
    },
    toggleEditForm: (state, action: PayloadAction<boolean>) => {
      state.openTagForm = action.payload;
    },
    setEditData: (state, action) => {
      state.form.id.value = action.payload.id;
      state.form.categoryId.value = action.payload.category;
      state.form.name.value = action.payload.name;
      state.form.status.value = action.payload.status;
    },
    update: (state, action) => {
      state.loading = true;
    },
    updateSuccess: (state, action: PayloadAction<{ item: Tag }>) => {
      state.loading = false;
      const itemIndex = state.list.items.findIndex(
        tag => tag.id === action.payload.item.id,
      );
      if (itemIndex > -1) {
        state.list.items[itemIndex] = action.payload.item;
      }
      state.openTagForm = false;
    },
    updateFailed: state => {
      state.loading = false;
    },
    toggleDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.openDelete = action.payload;
      if (action.payload === false) {
        state.form = initialState.form;
      }
    },
    delete: (state, action) => {
      state.loading = true;
    },
    deleteSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.filter(
        item => item.id !== action.payload,
      );
      state.openDelete = false;
      state.form = initialState.form;
    },
    deleteFailed: state => {
      state.loading = false;
    },
    getCategory: (state, action) => {},
    getCategorySuccess: (state, action) => {
      state.category.items = action.payload.items;
      state.category.meta = action.payload.meta;
      state.category.links = action.payload.links;
    },
    getCategoryFailed: state => {
      state.loading = false;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.list = initialState.list;
        state.query.page = 1;
      }
      set(state.query, name, value);
    },
  },
});

export const { actions, reducer, name: sliceKey } = tagsSlice;
