import { Tag } from 'app/containers/Tags/types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Add, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material';

import { actions } from '../../slice';
import { Category } from '../../types';
import CategoryTag from '../CategoryTag';
import { useCategoryRowStyles } from './styles';

export type TagDeleteHandler = (tag: Tag) => void;
interface CategoryRowProps extends Category {
  onEdit: (category: Category) => void;
  onTagDelete: TagDeleteHandler;
  onCategoryDelete: (category: Category) => void;
}

const CategoryRow = (props: CategoryRowProps) => {
  const { id, name, tags, onEdit } = props;
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(open => !open);
  const styles = useCategoryRowStyles();
  const dispatch = useDispatch();

  const openAddTag = () => {
    dispatch(actions.setOpenTagForm());
    dispatch(actions.setCategoryIdToTagForm(props));
  };
  return (
    <Box className={styles.root}>
      <Box className={styles.categoryContainer}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={toggleOpen}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <Typography className={styles.categoryNameLabel}>
            {name}

            <Typography component={'span'} sx={{ ml: 1 }}>{`(${
              Array.isArray(tags) ? tags.length : 0
            } tags)`}</Typography>
          </Typography>
        </Box>
        <Box>
          {Array.isArray(tags) && tags.length === 0 && (
            <IconButton
              sx={{ p: 0, color: '#FF666E' }}
              onClick={() => props.onCategoryDelete(props as any)}
            >
              <Delete />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              onEdit(props);
            }}
            sx={{ color: '#FAA61A' }}
          >
            <Edit />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ ml: 6 }}>
          {Array.isArray(tags) && tags.length > 0 ? (
            <ul>
              {tags.map(tag => (
                <CategoryTag
                  key={`tag-${id}-${tag.id}`}
                  {...tag}
                  category={props}
                  onDelete={() => props.onTagDelete(tag)}
                />
              ))}
            </ul>
          ) : (
            <Box sx={{ pt: 5 }}></Box>
          )}
          <Button
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 16,
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={openAddTag}
          >
            <Box
              sx={{
                color: '#FFF',
                background: '#000',
                p: 0,
                borderRadius: '50%',
              }}
            >
              <Add />
            </Box>
            <Typography sx={{ marginLeft: 2, color: '#000' }}>
              Add Tags To This Category
            </Typography>
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CategoryRow;
