import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { initialState } from '../types';

export const auditLogSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    getList: state => {
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },
    getListFailed: state => {
      state.loading = false;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.query.page = 1;
        state.list = initialState.list;
      }
      set(state.query, name, value);
    },
    getNextPage: (state, action) => {
      state.loading = true;
    },
    getNextPageSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
  },
});
export const { actions, reducer, name: sliceKey } = auditLogSlice;
