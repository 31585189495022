import { getSignedUrl, uploadUsingSignedURL } from 'app/components/commonSaga';
import queryString from 'query-string';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { getDefaultHeaders, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { actions as dashboardActions } from '../Dashboard/slice';
import { SignedURLResponse } from '../types';
import { selectForm, selectHerosQuery } from './selector';
import { actions } from './slice';

const apiUrl = `${API_URL}/heros`;

// import { request } from 'utils/request';

/**
 * Github repos request/response handler
 */

export function* deleteRow(action) {
  try {
    const options = {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${apiUrl}/${action.payload.id}`, options);
    console.log('delete response', response);
    if (response && response.message === 'MESSAGES.DELETE_SUCCESS') {
      yield put(actions.deleteSuccess(action.payload.id));
      yield call(action.payload?.callBack);
    }
  } catch (e) {
    console.log(e, 'Something went wrong');
  }
}

export function* update(action) {
  try {
    const { createHero } = yield select(selectForm);
    const { image, viewOrder, heroName, status, logoUrl } = createHero;
    let signedURLResponse: SignedURLResponse;
    let filename = image.fileName;
    let file = image.file;
    let thumb = image.thumb;
    let fileType = image.fileType;
    let hero: any = {
      name: heroName.value,
      description: 'test',
      status: status.value,
      image: {
        fileName: filename,
        fileType,
        file,
        thumb,
      },
    };
    if (logoUrl.value !== '') {
      if (logoUrl.value !== image.thumb) {
        const { fileName } = image;
        signedURLResponse = yield getSignedUrl(filename, image.fileType);
        filename = new Date().toISOString() + ` ${fileName}`;
        file = signedURLResponse.key;
        fileType = image.fileType;
        thumb = signedURLResponse.previewUrl;
        if (signedURLResponse.url) {
          yield uploadUsingSignedURL(
            signedURLResponse.url,
            image.fileType,
            image.file,
          );
        }
      }
    }
    hero = {
      name: heroName.value,
      viewOrder: viewOrder.value,
      description: 'test',
      status: status.value,
      image: {
        fileName: filename,
        fileType,
        file,
        thumb,
      },
    };
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify(hero),
    };
    const response = yield request(`${apiUrl}/${action.payload.id}`, options);
    console.log('updated', response);
    yield put(actions.updateSuccess(response));
    yield call(action.payload?.callBack);
  } catch (e: any) {
    try {
      const error = JSON.parse(e.message);
      yield put(actions.createHeroFailure(error.message));
    } catch (error) {
      yield put(actions.createHeroFailure('Something Went Wrong'));
    }

    console.log(e);
  }
}

export function* create(action) {
  yield delay(500);
  try {
    const { createHero } = yield select(selectForm);
    const { image, heroName, description, status, logoUrl } = createHero;
    const { fileName, fileType, file, thumb } = image;
    const filename = new Date().toISOString() + ` ${fileName}`;
    const hero: any = {
      name: heroName.value,
      description: 'test',
      status: status.value,
    };
    let signedURL: SignedURLResponse;
    if (logoUrl.value !== '') {
      signedURL = yield getSignedUrl(filename, fileType);
      if (signedURL.url) {
        // pushes the selected file to the cloud;
        yield uploadUsingSignedURL(signedURL.url, fileType, file);
        hero.image = {
          fileName: filename,
          fileType,
          thumb: signedURL.previewUrl,
          file: signedURL.key,
        };
      }
    }
    console.log(hero, createHero);
    const options = {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(hero),
    };
    const response = yield request(apiUrl, options);
    console.log('create hero response', response);
    yield put(actions.createHeroSuccess(response));
    yield call(action.payload?.callBack);
  } catch (e: any) {
    try {
      const error = JSON.parse(e.message);
      yield put(actions.createHeroFailure(error.message));
    } catch (error) {
      yield put(actions.createHeroFailure('Something Went Wrong'));
    }
  }
}

export function* getList() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectHerosQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(`${apiUrl}?${queries}`, options);
    if (response && response.items) {
      yield put(actions.getListSuccess(response));
    } else {
      yield put(actions.getListFailed('Something went wrong'));
    }
  } catch (e) {
    yield put(actions.getListFailed('Something went wrong'));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  }
}

export function* getNextPage(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const { orderField, orderDirection, q, status } = yield select(
      selectHerosQuery,
    );
    const requestQuery = {
      ...(status !== '' && {
        status,
      }),
      ...(q !== '' && {
        q,
      }),
      ...(orderField !== '' && {
        orderField,
      }),
      ...(orderDirection !== '' && {
        orderDirection,
      }),
    };
    const queries = queryString.stringify({
      ...requestQuery,
    });
    const response = yield request(
      action?.payload + `${queries && '&' + queries}`,
      options,
    );
    if (response.items) {
      yield put(actions.getNextPageSuccess(response));
    }
    console.log(response, 'Response here');
  } catch (e) {
    console.log(e, 'error here');
  }
}

export function* createHeroRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getNextPage);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
}
