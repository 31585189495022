import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  highlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  firstHighlight: {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  textFieldStyle: {
    "& .MuiOutlinedInput-root": {
      // background: "#FFF",
      width: 210,
      minHeight: 45
      // borderRadius: "6px"
    }
  },
  textExtendedDate: {
    "& .MuiOutlinedInput-root": {
      // background: "#FFF",
      width: 240,
      minHeight: 45
      // borderRadius: "6px"
    }
  }
}))