import Icon from 'app/components/Icons';
import { Logo } from 'app/components/Logo';
import { selectLoading } from 'app/containers/Auth/selectors';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ENV, APP_VERSION } from 'utils/constants';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, CircularProgress, Fade, IconButton, Typography } from '@mui/material';

import { selectMenuOpen } from '../../selector';
import { actions } from '../../slice';
import NestedLink from '../NestedLink';
import { useStyles } from './styles';

// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
type Props = {
  setShowSidebar: Function;
  showSidebar: Boolean;
};

const links = [
  {
    parent: 0,
    name: 'Dashboard',
    displayName: 'Dashboard',
    path: '/dashboard',
    icon: <Icon name="dashboard.svg" />,
    id: 20,
  },
  {
    parent: 0,
    name: 'TV users',
    displayName: 'TV users',
    path: '/tv-users',
    icon: <Icon name="tv-users.svg" />,
    permissionName: 'TV_USERS_SETTINGS',
    id: 24,
  },
  {
    parent: 24,
    name: 'All users',
    displayName: 'All users',
    path: '/tv-users/users',
    permissionName: 'TV_USERS_SETTINGS',
    id: 25,
  },
  {
    parent: 24,
    name: 'Devices',
    displayName: 'Devices',
    path: '/tv-users/devices',
    permissionName: 'TV_USERS_SETTINGS',
    id: 26,
  },
  // {
  //   parent: 0,
  //   name: 'Users',
  //   displayName: 'Users List',
  //   path: '/user-management',
  //   icon: <Dashboard sx={{color: "#8F8E8E"}} />,
  //   id: 1,
  // },
  // {
  //   parent: 0,
  //   name: 'Users',
  //   displayName: 'Users List',
  //   path: '/user-management',
  //   icon: <Dashboard sx={{color: "#8F8E8E"}} />,
  //   id: 1,
  // },
  {
    parent: 0,
    id: 2,
    name: 'Merchants',
    displayName: 'Merchants',
    path: '/merchants',
    icon: <Icon name="merchants.svg" />,
  },
  {
    id: 6,
    parent: 2,
    name: 'Merchants',
    displayName: 'Merchants',
    path: '/merchants/all',
    permissionName: 'MERCHANT_ALL',
  },
  {
    id: 4,
    parent: 2,
    name: 'Merchant Users',
    displayName: 'Merchant Users',
    path: '/merchants/merchant-users',
    permissionName: 'MERCHANT_USERS',
  },
  {
    id: 5,
    parent: 2,
    name: 'Permission Groups',
    displayName: 'Permission-groups',
    path: '/merchants/permission-groups',
    permissionName: 'MERCHANT_PERMISSION_GROUP',
  },
  {
    id: 9,
    parent: 0,
    name: 'Settings',
    path: '/settings',
    icon: <SettingsIcon sx={{ color: '#3D3F47' }} />,
    toggle: true,
  },
  {
    id: 27,
    parent: 9,
    name: 'TV Streaming',
    displayName: 'TV Streaming',
    path: '/settings',
  },
  {
    id: 32,
    parent: 27,
    name: 'Tags & Categories',
    displayName: 'Tags & Categories',
    path: '/settings/categories',
    permissionName: 'SETTINGS_TAGS',
  },
  {
    id: 28,
    parent: 9,
    name: 'CMS',
    displayName: 'CMS',
    path: '/settings/cms',
    permissionName: "SETTINGS_PRIVACY_POLICY",
  },
  {
    id: 281,
    parent: 9,
    name: 'System',
    displayName: 'System',
    path: '/settings/system',
    permissionName: "SETTINGS_SYSTEM",
  },
  {
    id: 30,
    parent: 28,
    name: 'Terms of service',
    displayName: 'Terms of service',
    path: '/pages/terms-of-use',
    permissionName: "SETTINGS_TERMS_OF_SERVICE",
    // icon: <Icon name="terms-privacy.svg" />,
    // target: '_blank',
  },
  {
    id: 31,
    parent: 28,
    name: 'Privacy Policy',
    displayName: 'Privacy Policy',
    path: '/pages/privacy-policy',
    permissionName: "SETTINGS_PRIVACY_POLICY",
    // icon: <Icon name="terms-privacy.svg" />,
    // target: '_blank',
  },
  {
    id: 10,
    parent: 27,
    name: 'Channels',
    displayName: 'Channels',
    path: '/settings/channels',
    permissionName: 'SETTINGS_CHANNELS',
  },
  {
    id: 11,
    parent: 27,
    name: 'Heros',
    displayName: 'Heros',
    path: '/settings/heros',
    permissionName: 'SETTINGS_HEROS',
  },
  // {
  //   id: 12,
  //   parent: 27,
  //   name: 'Tags',
  //   displayName: 'Tags',
  //   path: '/settings/tags',
  //   permissionName: 'SETTINGS_TAGS',
  // },
  {
    id: 13,
    parent: 9,
    name: 'Logs',
    displayName: 'Logs',
    path: '/settings/audit-logs',
    permissionName: 'SETTINGS_LOGS',
  },
  {
    id: 14,
    parent: 9,
    name: 'Imports',
    displayName: 'Imports',
    path: '/imports',
    permissionName: 'SETTINGS_IMPORTS',
  },
  {
    id: 7,
    parent: 0,
    name: 'Products',
    displayName: 'Product catalog',
    path: '/products',
    icon: <Icon name="products.svg" />,
    permissionName: 'SETTINGS_PRODUCTS',
  },
  {
    id: 8,
    parent: 0,
    name: 'TV Post',
    displayName: 'My TV Posts',
    path: '/tv-post',
    icon: <Icon name="tv-posts.svg" />,
    permissionName: 'SETTINGS_TV_POST',
  },
];

// const IconOnlyLinks = () => <Box>
//   {
//     links.map(link => <IconOnlyLink key={link.name} {...link} />)
//   }
// </Box>

const Links = () => {
  const isLoading = useSelector(selectLoading);
  return (
    <Box sx={{}}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {links
            .filter(x => x.parent === 0)
            .map(link => (
              // <>{link.name}</>
              <NestedLink key={link.name} {...link} data={links} />
            ))}
        </>
      )}
    </Box>
  );
};

// console.log("links" , links)

const BottomLink = () => (
  <Box>
    {/* {bottomLink.map(x => (
      <DashboardLink key={x.name} {...x} />
    ))} */}
  </Box>
);

const Sidebar = ({ setShowSidebar, showSidebar }: Props) => {
  const dispatch = useDispatch();
  // const [showSidebar, setShowSidebar] = useState(true)

  const isMenuOpen = useSelector(selectMenuOpen);

  const handleToggle = () => {
    dispatch(actions.toggleMenu());
  };

  const version = `App Version Name: ${APP_VERSION}-${APP_ENV}`;
  const closeSideBar = () => {
    setShowSidebar(!showSidebar);
  };
  const styles = useStyles();
  return (
    <>
      <Box
        sx={{ boxShadow: 3 }}
        className={clsx({
          [styles.root]: true,
          [styles.menuOpen]: isMenuOpen,
          [styles.menuClose]: !isMenuOpen,
        })}
        style={{
          display: showSidebar ? 'block' : 'none',
          // transition: "visibility 0.6s all"
        }}
      >
        <Box display={'flex'} justifyContent="center" mt={3.5}>
          <Logo style={{ width: 60, height: 60 }} />
        </Box>
        <Typography
          component={'span'}
          mb={3}
          sx={{
            marginTop: '20px',
            marginBottom: '6px',
            fontSize: '12px',
            color: '#9CB0BA',
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
          }}
        >
          {version}
        </Typography>
        <Box className={styles.menuWrapper}>
          <Fade in={isMenuOpen} timeout={{ enter: 300, exit: 200 }}>
            <span className={styles.abovelinksWrap}>
              {isMenuOpen && <Links />}
            </span>
          </Fade>
          <Box className={styles.copyRightText}>
            <BottomLink />
          </Box>
          <Box className={styles.copyRightContainer}>
            <Box sx={{ px: 2.5, py: 3.5 }}>
              <Typography className={styles.copyText}>
                Copyright © 2022 Shopi®.
              </Typography>
              <Typography className={styles.copyText}>
                {' '}
                All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <IconButton
        onClick={closeSideBar}
        sx={{
          position: 'fixed',
          top: '2px',
          left: showSidebar ? '220px' : 0,
          zIndex: 20,
          color: '#CCCDCF',
        }}
      >
        {showSidebar ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </IconButton>
    </>
  );
};

export default Sidebar;
