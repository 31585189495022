import clsx from 'clsx';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
    Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel
} from '@mui/material';
import { styled } from '@mui/system';

import { LoadingIndicator } from '../LoadingIndicator';
import { useStyles } from './styles';

export const StyledTableCell = styled(TableCell)({
  fontSize: 15,
  lineHeight: '20px',
  backgroundColor: 'transparent',
  color: '#3C4858',
  borderRight: '1px solid #C4C4C4',
  textAlign: 'unset',
  borderRadius: 6,
  '&:first-of-type': {
    borderLeft: '1px solid #C4C4C4',
  },
  whiteSpace: 'nowrap',
  padding: '14px 16px',
});

export const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#F5F5F540',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F7F7FA',
  },
});

const SortIcon = () => <ArrowDownwardIcon sx={{ height: 24, width: 24 }} />;
const SortIcon2 = () => <ArrowUpwardIcon sx={{ height: 24, width: 24 }} />;

export const TableSortIcon = ({ order, orderBy, sortValue }) => {
  if (!order && !orderBy) {
    return null;
  }
  return (
    <>
      {orderBy === sortValue && order === 'ASC' ? <SortIcon /> : <SortIcon2 />}
    </>
  );
};

export type Order = 'asc' | 'desc';
export const TableHeader = ({
  columnValues,
  classes,
  order,
  orderBy,
  Actions,
  onRequestSort,
  showSelect,
  selectLabel,
  showSelectAll,
  actionsAt,
  actions,
  selectAllHandler,
  isAllSelected,
}) => {
  const [arrowToggle, setArrowToggle] = useState<any>('');

  // useEffect(() => {
  //   console.log({ order, orderBy });
  // }, [order, orderBy]);

  const createSortHandler = property => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const styles = useStyles();

  return (
    <TableHead>
      <TableRow className={styles.headerRow}>
        {showSelect && (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHead,
            )}
            style={{
              minWidth: 97,
            }}
          >
            {showSelect && (
              <Checkbox
                value={isAllSelected}
                checked={isAllSelected}
                onChange={e => {
                  // console.log(e.target.value, e.target.checked)
                  selectAllHandler(e.target.checked);
                }}
                className={classes.headerCheckbox}
                disableRipple
              />
            )}
            {selectLabel}
          </TableCell>
        )}
        {columnValues.map((headCell, index) => (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHead,
            )}
            key={headCell.id + '_' + index}
            align={headCell.align}
            style={{
              minWidth: headCell.minWidth,
              maxWidth: headCell.maxWidth ? headCell.maxWidth : 'auto',
              ...(headCell.centeredHeader && {
                textAlign: 'center',
              }),
            }}
          >
            {headCell.sortValue && headCell.sortValue !== '' && (
              <TableSortLabel
                hideSortIcon={false}
                className={classes.tableSort}
                active={headCell.sortValue === orderBy}
                sx={{
                  '& .MuiButtonBase-root-MuiTableSortLabel-root.Mui-active': {
                    color: '#bdbdbd',
                  },
                }}
                direction={
                  orderBy === headCell.sortValue
                    ? order?.toLocaleLowerCase() || ''
                    : 'asc'
                }
                onClick={createSortHandler(headCell.sortValue)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
            {!(headCell.sortValue && headCell.sortValue !== '') &&
              headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

var columnValues: any = [];
export const CustomTable = ({ rows, ...props }) => {
  const [selected, setSelected] = React.useState<any>('');
  const styles = useStyles();
  // console.log("row", rows)
  let { actionsAt } = props;
  const {
    id,
    order,
    doubleClickEvent,
    setOrderEvent,
    setOrderByEvent,
    orderBy,
    columns,
    Actions,
    singleClickEvent,
    selectAllHandler,
    isAllSelected,
    // editComponent,
    showSelect = false,
    selectLabel = 'Select',
    showSelectAll = true,
    actions,
    selectHandler,
    isChecked,
    loading,
    renderRows,
  } = props;

  if (!columns) {
    columnValues = [];
    if (rows.length > 0) {
      Object.keys(rows[0]).map(value => {
        let obj = {
          id: value,
          label: value,
          sortValue: '',
          format: valuee => valuee[value],
        };
        columnValues.push(obj);
        return <span key={'uniquefield-' + id}>value</span>;
      });
    }
  } else {
    columnValues = columns;
  }

  const selectRow = value => (event: React.MouseEvent<unknown>) => {
    if (value.Id === selected) {
      setSelected(null);
      if (singleClickEvent) {
        singleClickEvent(null);
      }
    } else {
      setSelected(value.Id);
      if (singleClickEvent) {
        singleClickEvent(value);
      }
    }
  };
  const handleRequestSort = (event: React.MouseEvent<unknown>, property) => {
    const isAsc: any = orderBy === property && order === 'ASC';

    if (orderBy === property && order === 'DESC') {
      setOrderByEvent('');
      setOrderEvent('');
    } else {
      setOrderEvent(isAsc ? 'DESC' : 'ASC');
      setOrderByEvent(property);
    }
    localStorage.setItem('toggleArrow', isAsc);
  };

  actionsAt = actionsAt ? actionsAt - 1 : columnValues.length;

  return (
    <Paper id={id} className={styles.root} elevation={0}>
      {/* <TableContainer className={clsx(styles.container, className, scrollClass)}> */}
      <Table stickyHeader>
        <TableHeader
          isAllSelected={isAllSelected}
          columnValues={columnValues}
          classes={styles}
          Actions={Actions}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          showSelect={showSelect}
          selectLabel={selectLabel}
          showSelectAll={showSelectAll}
          actionsAt={actionsAt}
          actions={actions}
          selectAllHandler={selectAllHandler}
          // toggleArrow={toggleArrow}
        />
        <TableBody className={styles.tableBody}>
          {loading && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={columnValues.length}
                sx={{ textAlign: 'center' }}
              >
                <LoadingIndicator />
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!loading && rows.length === 0 && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={columnValues.length + 1}
                style={{ textAlign: 'center' }}
              >
                No items found
              </StyledTableCell>
            </StyledTableRow>
          )}

          {renderRows
            ? renderRows(columnValues)
            : rows.map((row: any, rowIndex) => {
                let checked = (isChecked && isChecked(row)) || false;
                return (
                  <React.Fragment key={`${rowIndex}`}>
                    <StyledTableRow
                      tabIndex={-1}
                      key={`F-${rowIndex}`}
                      onClick={() => singleClickEvent(row)}
                      onDoubleClick={() => doubleClickEvent(row)}
                    >
                      {showSelect && (
                        <StyledTableCell>
                          <Checkbox
                            checked={checked}
                            className={styles.customCheckbox}
                            disableRipple
                            onClick={() => selectHandler(row)}
                          />
                        </StyledTableCell>
                      )}
                      {columnValues.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <React.Fragment key={column.id + '_' + index}>
                            <StyledTableCell
                              dir={column.ltr}
                              key={column.id + '_' + index}
                              align={column.align}
                              style={{
                                ...(column.maxWidth && {
                                  maxWidth: column.maxWidth,
                                }),
                                textAlign: column?.rowAlign ?? 'left',
                                color:
                                  typeof column.label === 'string' &&
                                  column.label.includes('status') &&
                                  row.status === 'inActive'
                                    ? '#B3B3B3'
                                    : '#333333',
                                ...(column.minWidth && {
                                  minWidth: column.minWidth,
                                }),
                              }}
                            >
                              {column.format
                                ? column.format({ ...row, index: rowIndex })
                                : value}
                            </StyledTableCell>
                          </React.Fragment>
                        );
                      })}
                    </StyledTableRow>
                  </React.Fragment>
                );
              })}
        </TableBody>
      </Table>
      {/* </TableContainer > */}
    </Paper>
  );
};
