import { CustomTable } from 'app/components/Table';
import React from 'react';

export type DataTableProps = {
  rows: Array<any>;
  columns: Array<any>;
  Actions?: any;
  editComponent?: any;
  editAction?: any;
  actions?: Array<any>;
  scrollClass?: any;
  onRowClick?: any;
  onRowDoubleClick?: any;
  setQuery: (name: any, value: string) => void;
  orderField?: any;
  orderDirection?: any;
  loading?: boolean;
  renderRows?: (columnValues?: any) => JSX.Element;
  showSelect?: boolean;
  onSelect?: Function;
};

const DataTable = ({
  rows,
  columns,
  Actions,
  editComponent,
  editAction,
  actions,
  scrollClass,
  onRowClick = () => {},
  onRowDoubleClick = () => {},
  setQuery = (name, value) => {},
  orderField,
  orderDirection,
  loading = false,
  renderRows,
  showSelect = false,
  onSelect = () => {},
}: DataTableProps) => {
  const doubleClickEvent = row => {
    onRowDoubleClick(row);
  };
  // const { orderField, orderDirection } = useSelector(selectQuery);
  const onRowTap = e => {
    onRowClick && onRowClick(e);
  };

  const setOrderByEvent = (event: any) => {
    setQuery('orderField', event);
  };

  const setOrderEvent = (event: any) => {
    setQuery('orderDirection', event);
  };

  return (
    <CustomTable
      loading={loading}
      Actions={Actions}
      editComponent={editComponent}
      doubleClickEvent={doubleClickEvent}
      singleClickEvent={onRowTap}
      rows={rows}
      onRowDoubleClick={onRowDoubleClick}
      editAction={editAction}
      order={orderDirection}
      orderBy={orderField}
      columns={columns}
      actions={actions}
      scrollClass={scrollClass}
      setOrderByEvent={setOrderByEvent}
      setOrderEvent={setOrderEvent}
      renderRows={renderRows}
      // showSelect={showSelect}
      // onSelect={onSelect}
    />
  );
};

export default DataTable;
