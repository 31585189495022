import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  labelRoot: {
    display: 'flex',
    // alignItems: 'center',
  },
  fieldLabel: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    marginRight: 6,
  },
  descriptionLabel: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#8F8E8E',
    marginLeft: 6,
  },
  input: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',

    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
      // width: '245px',
      maxWidth: '132px',
      maxHeight: '39px'
    },
    backgroundColor: '#F7F7FA',

  },
}));
