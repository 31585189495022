import { RootState } from 'types';
import { ctaValueFinder, round } from 'utils/constants';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.dashboardState || initialState
export const selectMenuOpen = createSelector([selectDomain], state => state.menuOpen);
export const selectQuery = createSelector([selectDomain],  state => state.query);
export const selectSnacbar = createSelector([selectDomain], state => state.snackbar);
export const getTotalNumberOfUsers = createSelector([selectDomain], state => state.totalNumberOfUsers);
export const selectDashboardGraphs = createSelector([selectDomain], state => state.graphs);
export const selectCurrentRoute = createSelector([selectDomain], state => state.currentRoute);
export const selectSearch = createSelector([selectDomain], state => state.search);
export const selectPostsList = createSelector([selectDomain], state => state.list);
export const selectPostLoading = createSelector([selectDomain], state => state.loading);

export const selectAvgTimeStateCat = createSelector(
  [selectDomain], state => { return state.graphs.avgTimeStats.avgSpentTimeByDate.map((d) => {
    return d._id
  })}
)

export const selectAvgTimeStateUserCount = createSelector(
  [selectDomain], state => {
    return state.graphs.avgTimeStats.avgSpentTimeByDate.map((d) => {
      return round(d.usersCount)
    })
  }
)

export const selectAvgTimeStateAverage = createSelector(
  [selectDomain], state => {
    return state.graphs.avgTimeStats.avgSpentTimeByDate.map((d) => {
      return round(d.avg)
    })
  }
)

export const selectCTAName = createSelector(
  [selectDomain], state => {
    return state.graphs.ctaStats.map((d: any) => {
      const ctaValue = ctaValueFinder(d.cta)
      return { title: d.name, poster: d?.video?.productVideo?.thumbUrl, ctaVal: ctaValue?.value }
    })
  }
)

export const selectCTACount = createSelector(
  [selectDomain], state => {
    return state.graphs.ctaStats.map((d) => {
      return d.count
    })
  }
)

export const selectWhishlistName = createSelector(
  [selectDomain], state => {
    return state.graphs.wishlistStats.map((d: any) => {
      return { title: d.name, poster: d?.video?.productVideo?.thumbUrl }
    })
  }
)

export const selectWhishlistCount = createSelector(
  [selectDomain], state => {
    return state.graphs.wishlistStats.map((d) => {
      return d.count
    })
  }
)

export const selectMostViewName = createSelector(
  [selectDomain], state => {
    return state.graphs.mostViewedStats.map((d: any) => {
      return { title: d.name, poster: d?.video?.productVideo?.thumbUrl }
    })
  }
)

export const selectMostViewCount = createSelector(
  [selectDomain], state => {
    return state.graphs.mostViewedStats.map((d) => {
      return d.count
    })
  }
)

export const selectTagName = createSelector(
  [selectDomain], state => {
    return state.graphs.tagStats.map((d) => {
      return d.name
    })
  }
)

export const selectTagCount = createSelector(
  [selectDomain], state => {
    return state.graphs.tagStats.map((d) => {
      return d.count || 0;
    })
  }
)