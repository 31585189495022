import React, { useCallback, useEffect, useRef, useState } from 'react';
import { request } from 'utils/request';

import { Close } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';

type Props = {
  url: string;
  onChange: any;
  sx?: any;
  name: string;
  label: string;
  filterId?: string | null;
  filterField?: string | null;
  optionMapper?: any;
  mapOptions?: any;
  load?: boolean;
  placeholder?: string;
  error?: string;
  selected: any;
  onClear?: any;
  dependent?: any;
  width?: any;
  disabled?: boolean;
  lightPlaceholder?: string;
  allowLoading?: boolean;
};

export const DynamicAutoComplete = ({
  url,
  onChange,
  name,
  label,
  optionMapper,
  mapOptions,
  load = true,
  error,
  selected,
  onClear,
  width,
  sx,
  lightPlaceholder,
  disabled = false,
  allowLoading = false,
}: Props) => {
  const id = `autoCompleteTextField${label}`;
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = React.useState<any | null>(null);
  const previousController = useRef<any>();
  const optionValues = optionMapper
    ? optionMapper
    : {
        name: 'name',
        value: 'value',
      };

  const onInputChange = (event, value, reason) => {
    getData(value);
    if (event) {
      const optionIndex = event.target.dataset?.optionIndex;
      console.log('optionIndex', optionIndex, reason);
      const item = options[optionIndex];
    }
  };

  useEffect(() => {
    if (load === true && !disabled) {
      getData(value);
    }
  }, [load, disabled]);

  useEffect(() => {
    // console.log("check1")
    if (selected?.id) {
      setValue(selected as any);
      setOptions([selected as any]);
      // console.log("check2")
    } else {
      setValue(null);
      getData('');
      // console.log("check3")
    }
  }, [selected?.id, url]);

  const getData = useCallback(
    searchTerm => {
      if (previousController.current) {
        previousController.current.abort();
      }
      if (load) {
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        const URL = `${url}&limit=10${searchTerm ? '&q=' + searchTerm : ''}`;

        request(URL, { signal }).then(function (myJson: any) {
          if (mapOptions) {
            mapOptions(setOptions, myJson.items);
          } else {
            setOptions(myJson.items);
          }
        });
      }
    },
    [load],
  );

  const clearValues = () => {
    setValue('');
    if (onClear) {
      onClear();
    }
  };
  return (
    <Autocomplete
      clearIcon={<Close fontSize="small" onClick={clearValues} />}
      id={`${name}-autocomplete`}
      size="small"
      fullWidth={true}
      options={options}
      disabled={disabled}
      sx={{
        ...(width && { width }),
        ...sx,
      }}
      onInputChange={onInputChange}
      value={value}
      onChange={(event: any, newValue: any | null) => {
        setValue(newValue);

        if (newValue) {
          onChange({
            name,
            value: newValue.id,
            item: newValue,
          });
        } else {
          onChange({
            name,
            value: '',
            item: {},
          });
        }
      }}
      isOptionEqualToValue={(option, newValue) => {
        return option.id === newValue.id;
      }}
      getOptionLabel={(option: any) => option[optionValues.name] || 'N/A'}
      // style={{ width: 480 }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={label}
          disabled={disabled}
          label={label}
          onChange={e => {
            console.log(e.target.value);
          }}
          InputLabelProps={{
            style: {
              color: lightPlaceholder ?? '#333333',
            },
          }}
          sx={{
            label: {
              color: '#CCCDCF !important',
              // border: "1px solid red"
            },
            '& .MuiOutlinedInput-root': {
              background: '#FFF',
              borderRadius: '6px',
              padding: '16.5px 14px!important',
            },
          }}
          variant="outlined"
          id={id}
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};
