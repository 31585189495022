import { FormError } from 'app/containers/types';
import React, { useCallback } from 'react';

import { Box } from '@mui/material';

import SettingsField from '../../SettingsField';
import SettingsSection from '../../SettingsSection';

const headings = [
  {
    name: 'Action',
  },
  {
    name: 'Features',
  },
  {
    name: 'Default Value',
  },
];

const DefaultSettings = ({ onEdit }: any) => {

  const productOpensAfterValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Product Info opens after (seconds) must be present`,
      });
    }
    return errors;
  }, []);

  const productCloseAfterValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Product Info closes after (seconds) must be present`,
      });
    }
    return errors;
  }, []);

  const thankyouBtnValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];

    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Thankyou button text must be present`,
      });
    }

    if (value.length > 12) {
      errors.push({
        name: 'value.error',
        error: `Thankyou button text lenght must be below 12 charactor`,
      });
    }

    return errors;
  }, []);

  const productInfoOpensBeforeVideoEndsValidator = useCallback((value: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Product Info opens before the video ends (seconds) must be present`,
      });
    }
    return errors;
  }, []);

  const FirstLineValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `First line must be present`,
      });
    }
    return errors;
  }, []);

  const SecondLineValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Second line must be present`,
      });
    }
    return errors;
  }, []);

  const ThirdLineValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Third line must be present`,
      });
    }
    return errors;
  }, []);


  const WiggleIntensityValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Wiggle intensity must be present`,
      });
    }
    return errors;
  }, []);

  const IntervalBetweentheAnimationsValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Interval between the animations must be present`,
      });
    }
    return errors;
  }, []);


  const LightIntensityValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Light intensity must be present`,
      });
    }
    return errors;
  }, []);


  const IntervalBetweenTheAnimationsValidator = useCallback((value: string, caseStr: string) => {
    const errors: Array<FormError> = [];
    if (!value) {
      errors.push({
        name: 'value.error',
        error: `Interval between the animations must be present`,
      });
    }
    return errors;
  }, []);


  return (
    <Box sx={{ overflowY: 'auto' }}>
      <SettingsSection heading="TV Post Default Values" headings={headings}>
        <SettingsField
          name="PRODUCT_INFO_OPENS_AFTER"
          label="Product Info opens after (seconds)"
          onEdit={onEdit}
          validator={productOpensAfterValidator}
        />
        <SettingsField
          name="PRODUCT_INFO_CLOSES_AFTER"
          label="Product Info closes after (seconds)"
          onEdit={onEdit}
          validator={productCloseAfterValidator}
        />
        <SettingsField
          name="PRODUCT_INFO_OPENS_BEFORE_THE_VIDEO_ENDS"
          label="Product Info opens before the video ends (seconds)"
          onEdit={onEdit}
          validator={productInfoOpensBeforeVideoEndsValidator}
        />
        <SettingsField name="FOMO_SCORE" label="Fomo Score" onEdit={onEdit} />
        <SettingsField name="TV_POST_THANK_YOU_BUTTON" label="Thankyou Button Text" onEdit={onEdit} validator={thankyouBtnValidator} />

      </SettingsSection>
      <SettingsSection heading="Promotions Default Values" headings={headings}>
        <SettingsField onEdit={onEdit} name="FIRST_LINE" label="First line" validator={FirstLineValidator} />
        <SettingsField onEdit={onEdit} name="SECOND_LINE" label="Second line" validator={SecondLineValidator} />
        <SettingsField onEdit={onEdit} name="THIRD_LINE" label="Third sentence" validator={ThirdLineValidator} />
      </SettingsSection>
      <SettingsSection heading="Promotion line" headings={headings}>
        <SettingsField
          onEdit={onEdit}
          name="WIGGLE_INTENSITY"
          label="Wiggle intensity"
          validator={WiggleIntensityValidator}
        />
        <SettingsField
          onEdit={onEdit}
          name="PROMOTION_LINE_INTERVAL_BETWEEN_THE_ANIMATIONS"
          label="Interval between the animations"
          validator={IntervalBetweentheAnimationsValidator}
        />
      </SettingsSection>
      <SettingsSection heading="Price" headings={headings}>
        <SettingsField
          onEdit={onEdit}
          name="LIGHT_INTENSITY"
          label="Light intensity"
          validator={LightIntensityValidator}
        />
        <SettingsField
          onEdit={onEdit}
          name="PRICE_INTERVAL_BETWEEN_THE_ANIMATIONS"
          label="Interval between the animations"
          validator={IntervalBetweenTheAnimationsValidator}
        />
      </SettingsSection>
    </Box>
  );
};

export default DefaultSettings;
