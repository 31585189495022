import DataTable from 'app/components/DataTable';
import DeleteModal from 'app/components/DeleteModal';
import DropDownComponent from 'app/components/DropDownComponent';
import ExportExcellButton from 'app/components/ExportExcellButton';
import NA from 'app/components/NA';
import PopoverDateRangePicker from 'app/components/PopoverDateReangePicker';
import TableLoader from 'app/components/TableLoader';
import Truncate from 'app/components/Truncate';
import Switch from 'app/containers/Dashboard/components/Switch';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedDateTime } from 'utils/helpers';

import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';

import { selectDeviceList, selectLoading, selectQuery } from '../redux/selectors';
import { actions } from '../redux/slice';
import { useStyles } from './styles';

type Props = {};

const Devices = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { status, orderDirection, orderField, updateMin, updateMax } =
    useSelector(selectQuery);
  const [statusValue, setStatusValue] = useState("All");
  const [creationDateState, setCreationDateState] = useState<
    Array<{ startDate: Date | null; endDate: Date | null; key: string }>
  >([{ startDate: null, endDate: null, key: 'selection' }]);
  const [value, setValue] = useState(0);
  const { items, meta, links } = useSelector(selectDeviceList);
  const loading = useSelector(selectLoading);
  const [openDelete, setDelete] = useState<any>(null);

  const [editedDateState, setEditedDateState] = useState<
    Array<{ startDate: Date | null; endDate: Date | null; key: string }>
  >([{ startDate: null, endDate: null, key: 'selection' }]);
  const handleCreationDateClear = () => {};
  const handleChange = e => {
    const { name, value } = e.target;
    setStatusValue(value)

    dispatch(actions.setQuery({ name: 'status', value: value === "All" ? "" : value }));
    // console.log(status, 'name, value status')
  };

  useEffect(() => {
    dispatch(actions.getList());
  }, [orderField, orderDirection, status, updateMin, updateMax]);

  useEffect(() => {
    const [{ startDate, endDate }] = editedDateState;
    const maxUpdateDate = getFormattedDateTime(
      endDate?.toISOString(),
      'yyyy-MM-dd',
    );
    const minUpdateDate = getFormattedDateTime(
      startDate?.toISOString(),
      'yyyy-MM-dd',
    );
    // console.log(maxUpdateDate, minUpdateDate);
    if (startDate && endDate) {
      dispatch(
        actions.setQuery({
          name: 'updateMin',
          value: minUpdateDate,
        }),
      );
      dispatch(
        actions.setQuery({
          name: 'updateMax',
          value: maxUpdateDate,
        }),
      );
    } else {
      dispatch(
        actions.setQuery({
          name: 'updateMin',
          value: '',
        }),
      );
      dispatch(
        actions.setQuery({
          name: 'updateMax',
          value: '',
        }),
      );
    }
  }, [editedDateState]);

  const handleDeviceBlock = value => {
    dispatch(actions.handleBlock(value.id));
  };

  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(links.next));
    }
  };

  const hasMoreItems = !!links.next;
  const setQuery = (name: string, value: string) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const handleDelete = () => {
    dispatch(
      actions.handleDelete({ id: openDelete.id, callBack: handleDeleteClose }),
    );
  };

  const handleDeleteClose = () => {
    setDelete(null);
  };

  const handleDateClear = () => {
    // console.log({ updateMin, updateMax })
    if (updateMin !== '') {
      setEditedDateState([
        { startDate: null, endDate: null, key: 'selection' },
      ]);
      dispatch(actions.setQuery({ name: 'updateMin', value: '' }));
    }
    if (updateMax !== '') {
      setEditedDateState([
        { startDate: null, endDate: null, key: 'selection' },
      ]);
      dispatch(actions.setQuery({ name: 'updateMax', value: '' }));
    }
  };

  const columns = [
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'right',
      format: value => (
        <>
          <Box>
            {/* <Tooltip title="Edit user">
              <IconButton
                sx={{ p: 0, mr: 1.25 }}
                size="small"
                onClick={() => {}}
              >
                <EditIcon sx={{ color: '#FAA61A' }} />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Delete user">
              <IconButton
                sx={{ p: 0, mr: 1.25 }}
                size="small"
                onClick={() => setDelete(value)}
              >
                <Delete sx={{ color: '#FF666E' }} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={value.isBlocked ? 'Unblock Device' : 'Block Device'}
            >
              <Switch
                checked={value.isBlocked}
                onClick={() => handleDeviceBlock(value)}
              />
            </Tooltip>
          </Box>
        </>
      ),
    },
    {
      id: 'key',
      label: 'Device ID',
      minWidth: 134,
      format: value => <NA value={value?.key} />,
    },
    {
      id: 'mac',
      label: 'MAC address',
      sortValue: 'selector.mac',
      minWidth: 135,
      format: value => <NA value={value?.mac} />,
    },
    {
      id: 'ip',
      label: 'IP Address',
      sortValue: 'selector.ip',
      minWidth: 135,
      format: value => <NA value={value?.ip} />,
    },
    {
      id: 'userId',
      label: 'Logged in User ID',
      // minWidth: 10,
      maxWidth: 118,
      format: value => value?.user?.id,
    },
  ];

  return (
    <div>
      <DeleteModal
        loading={loading}
        open={openDelete}
        handleClose={handleDeleteClose}
        onSuccess={handleDelete}
        title={`Are you sure you want to remove ${openDelete?.key}`}
        buttonText="Yes, remove"
      />
      <Box className={styles.topDiv}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            gap: '20px',
            height: '48px',
          }}
        >
          <ExportExcellButton type="devices" />
          {/* <Select
                        size="small"
                        className={styles.statusMenu}
                        width={185}
                        menuItems={[
                            <MenuItem value="">All</MenuItem>,
                            <MenuItem value="enable">Enable</MenuItem>,
                            <MenuItem value="disable">Disable</MenuItem>,
                        ]}
                    /> */}
          <DropDownComponent
            width="15%"
            title="Status"
            value={statusValue}
            name="status"
            formControlProps={{ className: styles.statusMenu }}
            menuItems={[
              { label: 'All', value: 'All' },
              { label: 'Enable', value: 'TRUE' },
              { label: 'Disable', value: 'FALSE' },
            ].map(filter => (
              <MenuItem value={filter.value} key={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
            handleChange={handleChange}
          />
        </div>
        {/* <PopoverDateRangePicker title={''} onChange={() => { }} date={[]} clearDates={undefined} /> */}
        <PopoverDateRangePicker
          date={editedDateState}
          clearDates={handleDateClear}
          title={'Filter by Date Edited'}
          label={'Filter by Date Edited'}
          value={value}
          className={styles.ml1}
          onChange={setEditedDateState}
          setValue={setValue}
        />
      </Box>
      <div className={styles.dataTableDiv}>
        <InfiniteScroll
          loader={<TableLoader />}
          hasMore={hasMoreItems}
          loadMore={getNextPage}
          useWindow={false}
        >
          <DataTable
            loading={loading}
            orderDirection={orderDirection}
            orderField={orderField}
            rows={items}
            columns={columns}
            setQuery={setQuery}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Devices;
