import { FormError, IListType } from 'app/containers/types';
import set from 'lodash/set';
import { API_URL, DEFAULT_PAGE_LIMIT } from 'utils/constants';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    BusinessTypeOptions, businessTypeOptions, defaultMerchantUsers, defaultPermissionGroup, Image,
    Merchant, MerchantObject, MerchantUserAssociation
} from './types';

export const initialState: Merchant = {
  merchantList: [],
  loading: false,
  error: null,
  businessTypeOptions,
  list: {
    items: [],
    meta: {
      itemsPerPage: DEFAULT_PAGE_LIMIT,
      itemCount: 0,
      totalItems: 0,
      currentPage: 1,
      totalPages: 0,
    },
    links: {
      last: '',
      first: '',
      previous: '',
      next: ``,
    },
  },
  permissionGroup: defaultPermissionGroup,
  merchantUsers: defaultMerchantUsers,
  form: {
    id: { value: '' },
    logoUrl: { value: '' },
    businessName: { value: '' },
    businessType: { value: '' },
    websiteUrl: { value: '' },
    address: { value: '' },
    businessAddress: { value: '' },
    socialAccountDetails: {
      insta: { value: '' },
      fb: { value: '' },
    },
    contactPerson: {
      phoneNumber: { value: '' },
      name: { value: '' },
    },
    user: {
      mobileNumber: { value: '' },
      email: { value: '' },
      firstName: { value: '' },
      lastName: { value: '' },
      assignedGroup: { value: '' },
    },
    description: { value: '' },
    image: {} as Image,
    createdAt: { value: '' },
    updatedAt: { value: '' },
  },
  query: {
    page: 1,
    limit: 100,
    q: '',
    businessType: [],
    orderDirection: '',
    orderField: '',
    updateMax: '',
    updateMin: '',
    createMax: '',
    createMin: '',
  },
};

export const merchantSlice = createSlice({
  name: 'merchantState',
  initialState,
  reducers: {
    getList: state => {
      state.query.page = 1;
      state.list = initialState.list;
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    getListFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getNextPage: (state, action) => {
      // state.loading = true;
    },
    getNextPageSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    getNextPageFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createOrUpdate: (
      state,
      action: PayloadAction<{ errors: FormError[]; callBack?: () => void }>,
    ) => {
      if (action.payload.errors.length) {
        action.payload.errors.forEach(error => {
          set(state.form, error.name, error.error);
        });
      } else {
        state.loading = true;
      }
    },
    createOrUpdateSuccess: (state, action: PayloadAction<MerchantObject>) => {
      state.form.id.value = action.payload.id;
      //state.form.logoUrl.value =  action.payload.image.url
      state.form.businessName.value = action.payload.businessName;
      state.form.businessType.value = action.payload.businessType;
      state.form.description.value = action.payload.description;
      state.form.websiteUrl.value = action.payload.websiteUrl;

      state.form.user.firstName.value = action.payload.user?.firstName;
      state.form.user.lastName.value = action.payload.user?.lastName;
      state.form.user.assignedGroup.value = action.payload.user?.assignedGroup;
      state.form.user.email.value = action.payload.user?.email;
      state.form.user.mobileNumber.value = action.payload.user?.mobileNumber;
      state.form.createdAt.value = action.payload.createdAt;
      state.form.updatedAt.value = action.payload.updatedAt;
      state.form.address.value = action.payload.address.address;
      state.form.businessAddress.value =
        action.payload.businessAddress?.address;
      state.form.socialAccountDetails.insta.value =
        action.payload.socialAccountDetails?.insta;
      state.form.socialAccountDetails.fb.value =
        action.payload.socialAccountDetails?.fb;
      state.form.contactPerson.phoneNumber.value =
        action.payload.contactPerson?.phoneNumber;
      state.form.contactPerson.name.value = action.payload.contactPerson?.name;
      state.form.image = action.payload.image || {};
      state.loading = false;
    },
    createOrUpdateFailed: (state, action) => {
      state.loading = false;
    },
    getById: (state, action) => {
      if (action.payload) {
        state.loading = true;
      } else {
        state.form = initialState.form;
        //state.
      }
    },
    setMerchantValue: (
      state,
      action: PayloadAction<{ name: string; value: any; form?: string }>,
    ) => {
      const form = action.payload.form ? action.payload.form : 'form';
      const { name, value } = action.payload;
      set(state, `${form ? `${form}.` : ''}${name}.value`, value);
      set(state, `${form ? `${form}.` : ''}${name}.error`, '');
    },
    setSelectedMerchantForPermissionGroup: (state, action) => {
      state.permissionGroup.form.merchantUsers = action.payload.map(mu => {
        if (mu.user) {
          return mu;
        }
        return { userId: mu.id, user: mu };
      });
    },
    removeMerchantForPermission: (state, action) => {
      const { userId } = action.payload;
      state.permissionGroup.form.merchantUsers.forEach(item => {
        if (item.userId == userId) {
          item.isDeleted = true;
        }
      });
    },
    delete: (state, action) => {},
    deleteSuccess: (state, action) => {
      state.list.items = state.list.items.filter(
        item => item.id !== action.payload,
      );
    },
    resetForm: state => {
      state.form = initialState.form;
    },
    resetMerchantForm: state => {
      state.permissionGroup.form.merchantUsers =
        initialState.permissionGroup.form.merchantUsers;
    },
    deleteFailed: (state, action) => {},
    resetModule: state => {
      state.form = initialState.form;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.list.items = [];
        // state.query.page = 1;
      }
      // state.list.items = [];
      set(state.query, name, value);
    },
    setFileDetails: (
      state,
      action: PayloadAction<{ name: string; type: string; file: any }>,
    ) => {
      state.form.image = {
        fileName: action.payload.name,
        fileType: action.payload.type,
        file: action.payload.file,
      } as Image;
    },
    resetImage: state => {
      state.form.image = {} as Image;
    },
    updateRecord: (state, action: PayloadAction<MerchantObject>) => {
      const index = state.list.items.findIndex(
        merchant => merchant.id === action.payload.id,
      );
      if (index >= 0) {
        state.list.items[index] = action.payload;
      }
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
        form?: string;
      }>,
    ) {
      const form = action.payload.form ? action.payload.form : 'form';
      action.payload.errors.forEach(error => {
        console.log({ form, error }, 'errors');
        if (action.payload.key === 'merchantUsers') {
          set(state.merchantUsers.form, `${error.name}`, error.error);
          return;
        }
        set(state[form], `${error.name}`, error.error);
      });
    },
    getPermissionGroupsList: (state, action) => {
      state.loading = true;
    },
    getPermissionGroupsListSuccess: (state, action) => {
      state.loading = false;
      state.permissionGroup.list = action.payload;
    },
    getPermissionGroupsListFailed: (state, action) => {
      state.loading = false;
    },
    getPermissionConfig: (state, action) => {
      state.loading = false;
    },
    getPermissionConfigSuccess: (state, action) => {
      state.loading = false;
      state.permissionGroup.permissions = action.payload;
      Object.keys(action.payload.permissions).forEach(key => {
        console.log(key, 'key here');
        set(
          state.permissionGroup.form,
          `permissions.${key}.value`,
          action.payload.permissions[key],
        );
      });
      state.permissionGroup.form.merchantUsers = [];
    },
    getNextPagePermissionGroups: (state, action) => {
      state.loading = true;
    },
    getNextPagePermissionGroupsSuccess: (
      state,
      action: PayloadAction<IListType>,
    ) => {
      state.loading = false;
      state.permissionGroup.list.items =
        state.permissionGroup.list.items.concat(action.payload.items);
      state.permissionGroup.list.meta.currentPage =
        action.payload.meta.currentPage;
      state.permissionGroup.list.links = action.payload.links;
      // state.permissionGroup.query.page = action.payload.meta.currentPage;
    },
    getNextPagePermissionGroupsFailed: (state, action) => {
      state.loading = false;
    },

    getPermissionConfigFailed: state => {
      state.loading = false;
    },

    getPermissionById: (state, action) => {
      state.loading = true;
    },
    getPermissionDetailsSuccess: (state, action) => {
      state.loading = false;
      state.permissionGroup.permissions = action.payload.permissions;
      Object.keys(action.payload.permissions).forEach(key => {
        // console.log(key, 'key here');
        set(
          state.permissionGroup.form,
          `permissions.${key}.value`,
          action.payload.permissions[key],
        );
      });
      // console.log(state.permissionGroup.form);
      if (action.payload && action.payload.userPermissions) {
        state.permissionGroup.form.merchantUsers =
          action.payload.userPermissions;
      }
    },

    updatePermissionGroup: (state, action) => {
      state.loading = false;
    },
    updatePermissionGroupSuccess: (state, action) => {
      state.loading = false;
    },
    updatePermissionGroupFailed: (state, action) => {
      state.loading = false;
    },
    createPermissionGroup: (state, action) => {
      state.loading = false;
    },
    createPermissionGroupSuccess: (state, action) => {
      state.loading = false;
    },
    createPermissionGroupFailed: (state, action) => {
      state.loading = false;
    },
    getMerchantUsers: (state, action) => {
      state.loading = false;
    },
    getMerchantUsersSuccess: (state, action) => {
      state.loading = false;
      state.merchantUsers.list = action.payload;
    },
    setMerchantUserQuery: (
      state,
      action: PayloadAction<{ name: string; value: any }>,
    ) => {
      const { name, value } = action.payload;
      state.merchantUsers.list.items = defaultMerchantUsers.list.items;
      state.list.links = defaultMerchantUsers.list.links;
      state.list.meta = defaultMerchantUsers.list.meta;
      set(state.merchantUsers.query, name, value);
    },
    getNextPageMerchantUser: (state, action) => {
      state.loading = true;
    },
    getNextPageMerchantUserSuccess: (state, action) => {
      state.loading = false;
      state.merchantUsers.list.items = state.merchantUsers.list.items.concat(
        action.payload.items,
      );
      state.merchantUsers.list.meta.currentPage =
        action.payload.meta.currentPage;
      state.merchantUsers.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    getNextPageMerchantUserFailed: (state, action) => {
      state.loading = false;
    },
    getMerchantUsersFailed: (state, action) => {
      state.loading = false;
    },
    createMerchantUsers: (state, action) => {
      state.loading = false;
    },
    createMerchantUsersSuccess: (state, action) => {
      state.loading = false;
      state.list.items = [action.payload, ...state.list.items];
    },
    createMerchantUsersFailed: (state, action) => {
      state.loading = false;
    },
    updateMerchantUsers: (state, action) => {
      state.loading = true;
    },
    updateMerchantUsersSuccess: (state, action) => {
      state.loading = false;
    },
    updateMerchantUsersFailed: (state, action) => {
      state.loading = false;
    },

    getMerchantUserById: (state, action) => {
      state.loading = false;
    },
    getMerchantUserByIdSuccess: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.merchantUsers.form.id.value = payload.id;
      state.merchantUsers.form.firstName.value = payload.firstName;
      state.merchantUsers.form.lastName.value = payload.lastName;
      state.merchantUsers.form.email.value = payload.email;
      state.merchantUsers.form.mobileNumber.value = payload.mobileNumber;
      state.merchantUsers.form.role.value = payload.role;
      const mappedMerchants = payload.merchantUsers.map(item => {
        return { ...item, deleted: false };
      });
      state.merchantUsers.form.merchantUsers.value = mappedMerchants;
      state.merchantUsers.form.selectedMerchants.value = mappedMerchants;
    },
    getMerchantUserByIdFailed: (state, action) => {
      state.loading = false;
    },

    toggleAddMerchantModal: (state, action) => {
      state.merchantUsers.openAddMerchantModal =
        !state.merchantUsers.openAddMerchantModal;
    },

    resetMerchantUsersForm: state => {
      state.loading = false;
      state.merchantUsers.form = initialState.merchantUsers.form;
    },

    deletePermissionGroup: (
      state,
      action: PayloadAction<{ id: string | number }>,
    ) => {
      state.loading = true;
    },
    deletePermissionSuccess: (state, action) => {
      state.loading = false;
      state.permissionGroup.list.items =
        state.permissionGroup.list.items.reduce(
          (previous, current, index) => [
            ...previous,
            { ...current, isDeleted: true },
          ],
          [],
        );
      let index = state.permissionGroup.list.items.findIndex(
        d => d.id === action.payload.id,
      );
      state.permissionGroup.list.items.splice(index, 1);
    },
    deletePermissionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBusinessTypeOptions: (state, action) => {
      state.loading = true;
    },
    getBusinessTypeOptionsSuccess: (
      state,
      action: PayloadAction<BusinessTypeOptions>,
    ) => {
      state.loading = false;
      state.businessTypeOptions = action.payload;
    },
    deleteMerchantUser: (
      state,
      action: PayloadAction<{ id: string | number }>,
    ) => {
      state.loading = true;
    },
    deleteMerchantUserSuccess: (
      state,
      action: PayloadAction<{ id: string | number }>,
    ) => {
      state.loading = false;
      state.merchantUsers.list.items = state.merchantUsers.list.items.filter(
        user => user.id !== action.payload.id,
      );
    },
    setUserMerchantForm: state => {
      const { merchantId, taxId, assignedRole } =
        state.merchantUsers.openTaxIdForm;
      state.merchantUsers.form.merchantUsers.value.forEach(mu => {
        if (mu.merchantId === merchantId) {
          mu.taxId = taxId.value;
          mu.assignedRole = assignedRole.value;
        }
      });
      state.merchantUsers.openEditMerchantModal = false;
    },
    deleteMerchantUserFailure: state => {
      state.loading = false;
    },
    getBusinessTypeOptionsFailed: (state, action) => {
      state.loading = false;
    },
    toggleTaxIdForm: (
      state,
      action: PayloadAction<MerchantUserAssociation | undefined>,
    ) => {
      if (action?.payload) {
        state.merchantUsers.openTaxIdForm.id = action.payload.id;
        state.merchantUsers.openTaxIdForm.merchantId =
          action.payload.merchantId;
        state.merchantUsers.openTaxIdForm.assignedRole.value =
          action.payload.assignedRole;
        state.merchantUsers.openTaxIdForm.taxId.value = action.payload.taxId;
        state.merchantUsers.openEditMerchantModal = true;
      } else {
        state.merchantUsers.openTaxIdForm =
          initialState.merchantUsers.openTaxIdForm;
        state.merchantUsers.openEditMerchantModal = false;
      }
    },
  },
});

export const { reducer, actions, name: sliceKey } = merchantSlice;
