import { DASHBOARD_MENU_CLOSE_WIDTH, DASHBOARD_MENU_WIDTH } from 'utils/constants';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '10px 56px',
  },
  main: {
    display: 'flex',
    // backgroundColor:'#aba',
    padding: '10px 0px',
    margin: '10px 10px',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #9CA2B0',
    borderBottomWidth: '1px',

  },
  heroImage: {
    width: '100%',
    // height: '100%',
    objectFit: 'cover',
    // padding: 4,
    "&:hover": {
      filter: "grayscale(100%) sepia() saturate(9987%) hue-rotate(322deg)",
    }

  },
  herosButton: {
    border: '1px solid',
    color: '#222',
    padding:'5px 15px',
    // height:'46px',
    // width:'150px',
    margin: '0px 0px 0px 26px'
  },
  dataTableDiv: {
    maxHeight: 'calc(100vh - 230px)',
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 4,
      height: 5,
      padding: '5px 0px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#D9D9D9',
      marginTop:30,
      // boxShadow: 'inset 4px 4px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#959595',
      borderRadius: 20,
      // border: '1px solid #3C4858',
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#959595',
    }
  },

}))