import { Select } from 'app/components/Select';
import { FormError } from 'app/containers/types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogProps, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { selectFileForUpload, selectImportForm } from '../redux/selector';
import { actions } from '../redux/slice';
import Form from './components/Form';
import { validate } from './components/Form/validator';

type Props = {
  dialogProps: DialogProps;
};

const CreateImport = (props: Props) => {
  const form = useSelector(selectImportForm);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState<number>(0);
  const fileForUpload = useSelector(selectFileForUpload);
  const [error, setError] = useState<string>('');
  const handleImportSubmit = () => {
    const errors: Array<FormError> = validate(form);
    if (!fileForUpload) {
      setError('Please select an excel file');
      return false;
    }
    if (errors.length) {
      dispatch(actions.setFormErrors(errors));
      return false;
    }
    dispatch(
      actions.createImport({
        uploadProgress,
      }),
    );
  };

  const uploadProgress = e => {
    const percentCompleted = Math.round((e.loaded * 100) / e.total);
    console.log(percentCompleted, 'uploaded');
    setProgress(percentCompleted);
  };

  return (
    <Dialog {...props.dialogProps}>
      <DialogTitle>Create New Import</DialogTitle>
      <Form
        setError={setError}
        error={error}
        form={form}
        uploadProgress={progress}
      />

      <DialogActions>
        <Button
          onClick={e =>
            props?.dialogProps?.onClose &&
            props.dialogProps.onClose(e, 'backdropClick')
          }
        >
          Cancel
        </Button>
        <Button
          sx={{ color: '#FFF' }}
          variant="contained"
          onClick={handleImportSubmit}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateImport;
