// import { TextField } from 'app/components/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Theme
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { selectError, selectLoading, selectLoginForm } from '../selectors';
import { actions } from '../slice';
import { LoginErrorType } from '../types';
import { validate } from './validator';

const useStyles = makeStyles((theme: Theme) => ({
  loginButtonWrapper: {},
  signUpWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#333333',
  },
  signUpText: {
    marginLeft: '8px',
    color: 'skyblue',
    textDecoration: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
    // fontFamily: 'Roboto',
  },
  loginText: {
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '40px',
    color: '#333333',
    textAlign: 'center',
    marginBottom: 40,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    // width: '70%', // Fix IE 11 issue.
  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  keepSignedin: {
    marginTop: 0,
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  submit: {
    width: '414px',
    height: 56,
    fontSize: 22,
    fontWeight: 500,
    borderRadius: 6,
    color: '#fff',
  },
  languageSwitch: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  email: {
    marginTop: 0,
    marginBottom: 23,
    borderRadius: 6,
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7FA inset !important",
      backgroundColor: "transparent",
      backgroundImage: "none !important",
      color: "#000000 !important",
    },
    '& .MuiFormControl-root': {},
    '& .MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-root': {
      width: '414px',
      backgroundColor: "#F7F7FA"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px',
    },
  },
  password: {
    margin: 0,
    borderRadius: 6,
    "& input:-internal-autofill-selected": {
      backgroundColor: "#F7F7FA"
    },
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7FA inset !important",
      backgroundColor: "transparent",
      backgroundImage: "none !important",
      color: "#000000 !important",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px',
    },
    '& .MuiFormControl-root': {},
    '& .MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-root': {
      width: '414px',
      backgroundColor: "#F7F7FA"
    },
  },
  labelKeepSignedIn: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginLeft: '5px',
  },
  dontHaveAccountText: {
    // fontFamily: 'Roboto',
  },
  phoneIcon: {
    position: 'absolute',
    top: -18,
    right: -45,
  },
  forgotPassword: {
    color: '#faa619',
    fontSize: 14,
    lineHeight: '16.71px',
    fontWeight: 700,
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '24px 0px 50px 0px',
  },
  copyRightText: {
    fontSize: 14,
    color: '#00000062',
    textAlign: 'center',
    position: 'absolute',
  },
}));

export function LoginForm({ className }) {
  const classes = useStyles();
  const form = useSelector(selectLoginForm);
  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();
  // const user = localStorage.getItem('loginResponse');
  const [visible, setVisible] = useState(false);
  const onLoginSuccess = () => {
    navigate('/dashboard');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(actions.setForm({ key: `loginForm.${name}`, value }));
  };

  const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
    try {
      /* istanbul ignore next  */
      evt?.preventDefault();
      setErrorMsg('');
      const errors = validate(form);
      if (errors.length > 0) {
        dispatch(actions.setFormErrors({ key: 'loginForm', errors }));
        return;
      }

      dispatch(actions.login({ callback: onLoginSuccess }));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (error) setErrorMsg(error);
    // eslint-disable-next-line
  }, [error]);

  const errorText = errorMsg;
  return (
    <form className={classes.form} noValidate onSubmit={onSubmitForm}>
      {errorText && (
        <Alert className={classes.error} severity="error">
          {errorText}
        </Alert>
      )}
      <Typography className={classes.loginText} gutterBottom>
        Login
      </Typography>
      <div dir="ltr">
        <Typography>Email</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          placeholder="Email Id"
          name="username"
          autoComplete={"off"}
          error={!!form.username.error}
          helperText={form.username?.error}
          autoFocus
          value={form.username.value}
          onChange={onChange}
          className={classes.email}
        />
        <Typography>Password</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="Password"
          type={visible ? 'text' : 'password'}
          id="password"
          value={form.password.value}
          onChange={onChange}
          error={!!form.password.error}
          helperText={form.password?.error}
          autoComplete={"off"}
          className={classes.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  onClick={() => setVisible(!visible)}
                  style={{ cursor: 'pointer' }}
                >
                  <IconButton sx={{ color: '#D1D0D0' }}>
                    {visible ? (
                      <VisibilityOff sx={{ color: '#D1D0D0' }} />
                    ) : (
                      <Visibility sx={{ color: '#D1D0D0' }} />
                    )}
                  </IconButton>
                </span>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Box className={classes.textWrapper} mt={1}>
        {/* <FormControlLabel
          sx={{ mb: 0, ml: 0 }}
          control={<Checkbox sx={{ p: 0, mr: 0 }} />}
          label={
            <Typography className={classes.labelKeepSignedIn} component="span">
              Remember me
            </Typography>
          }
        /> */}
        <span></span>
        <Link to="/login/forgot-password" className={classes.forgotPassword}>
          Forgot Password ?
        </Link>
      </Box>
      {/* <Box className={classes.keepSignedin}>
      </Box> */}
      <div className={classes.loginButtonWrapper}>
        <Button
          variant="contained"
          // color="#FAA61A"
          className={classes.submit}
          // disabled={isLoading}
          type="submit"
        >
          {isLoading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : t('login.button_text')}

        </Button>

      </div>
      {/* <Box className={classes.signUpWrapper}>
        <Typography className={classes.dontHaveAccountText}>
          Don't have an account yet?
        </Typography>
        <Typography className={classes.signUpText}>SIGN UP</Typography>
      </Box> */}
    </form>
  );
}

export const loginError = (error: LoginErrorType | undefined | null) => {
  if (!error) return null;
  switch (error) {
    case LoginErrorType.ERROR:
      return {
        message: 'An error has occurred! 😞',
        username: true,
        password: true,
      };
    case LoginErrorType.USERNAME_EMPTY:
      return { message: 'Please enter username', username: true };
    case LoginErrorType.PASSWORD_EMPTY:
      return { message: 'Please enter password', password: true };
    default:
      return { message: error, password: true, username: true };
  }
};
export default LoginForm;
