import { ImportForm } from 'app/containers/Imports/redux/types';
import { FormError } from 'app/containers/types';

export const validate = (form: ImportForm): Array<FormError> => {
  const errors: Array<FormError> = [];
  if (!form.type.value) {
    errors.push({
      name: 'type',
      error: "Type can't be empty",
    });
  }
  return errors;
};
