import { FormElement } from '../types';

export type PageForm = {
  id: FormElement;
  name: FormElement;
  content: FormElement;
};
export const initialPageForm: PageForm = {
  content: { value: '' },
  id: { value: '' },
  name: { value: '' },
};
export type PageState = {
  form: PageForm;
  loading: boolean;
};
export const initialState: PageState = {
  form: initialPageForm,
  loading: false,
};
