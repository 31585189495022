import clsx from 'clsx';
import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Icon, InputAdornment, MenuItem, withStyles } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

import { useStyles } from './styles';

export const Select = ({
  width = 0,
  className = '',
  SelectProps = {},
  menuItems,
  placeholderValue = '',
  noDefaults = false,
  placeholder = placeholderValue ,
  labelClass = '',
  ...props
}) => {
  const classes = useStyles({ width });
  // console.log("menuItems", menuItems)

  const iconStyles = {
    selectIcon: {
      color: "green"
    }
  };
  const CustomExpandMore = (
    ({ className, classes, ...rest }) => {
      return (
        <ExpandMore
          {...rest}
          className={clsx(className, classes.selectIcon)}
        />
      );
    }
  );

  return (
    <MuiTextField
      {...props}
      className={clsx(['SelectInput', classes.SelectInput, className])}
      select
      InputLabelProps={{
        shrink: props.value ? true : false,
        className: labelClass ? labelClass : classes.labelClass,

      }}
      label={placeholder}
      placeholder={placeholder}
      // variant="standard"
      // }}
      InputProps={{
        // startAdornment: (
        //   <InputAdornment position="end" sx={{
        //     position:'relative',
        //     left: 100
        //   }}>
        //     <KeyboardArrowDownIcon />
        //   </InputAdornment>
        // ),
        endAdornment: (
            <InputAdornment position="end" sx={{
              // position:'relative',
              // left: 100
            }}>
            </InputAdornment>
          ),
        style:{
          position: "relative",
          left:"20px",
        }
      }}
      SelectProps={{
        ...SelectProps,
        style: {
          maxHeight: 200,
        },

        // endAdornment: (
        //   <AccessAlarm />
        // ),

        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
            // color: "red"
          },

          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',

          },
          PaperProps: {
            style: {
              maxHeight: 200,
              marginTop: 5,
              width: width
            },
          },

        },
        // IconComponent: () =>
        // <ExpandMore
        //   className="MuiSvgIcon-root MuiSelect-icon"
        //   style={{ pointerEvents: "none",height:20, width:20, marginRight:-10, }}
        // />

      }}
      >
      {/* {!noDefaults && <MenuItem value={''} sx={{}}>‎</MenuItem>} */}

      {menuItems}

    </MuiTextField>
  );
};
