import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useUserSaga } from './redux/saga';
import { sliceKey, reducer } from './redux/slice';
import { useStyles } from './styles';

type Props = {};

const UserManagement = (props: Props) => {
  const styles = useStyles();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUserSaga });
  return (
    <Box className={styles.root}>
      <Outlet />
    </Box>
  );
};

export default UserManagement;
