import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.categoryState || initialState;

export const selectForm = createSelector([selectDomain], state => state.form);
export const selectCategoryList = createSelector(
  [selectDomain],
  state => state.categoryList,
);

export const selectCategoryQuery = createSelector(
  [selectDomain],
  state => state.query,
);

export const selectCategoryLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectCategoryError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectOpenTagForm = createSelector(
  [selectDomain],
  state => state.openTagForm,
);
