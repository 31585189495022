/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { AuthPage } from 'app/containers/Auth';
import { userRepoSaga } from 'app/containers/Auth/saga';
import { reducer, sliceKey } from 'app/containers/Auth/slice';
import {
    reducer as categoryReducer, sliceKey as categorySliceKey
} from 'app/containers/Categories/slice';
import { createChannelRepoSaga } from 'app/containers/Channels/saga';
import {
    reducer as channelReducer, sliceKey as channelSliceKey
} from 'app/containers/Channels/slice';
import { createHeroRepoSaga } from 'app/containers/Heros/saga';
import { reducer as heroReducer, sliceKey as heroSliceKey } from 'app/containers/Heros/slice';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, redirect, Route, Routes } from 'react-router-dom';
import { selectDirection, themeActions } from 'styles/theme/slice';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import PrivateRoute from 'utils/PrivateRoute';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { AuditLogsPage } from './containers/AuditLogs/Loadable';
import CreatePassword from './containers/Auth/CreatePassword';
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword';
import LoginForm from './containers/Auth/LoginPage/form';
import ResetPassword from './containers/Auth/ResetPassword';
import { selectAuthenticUser, selectUser } from './containers/Auth/selectors';
import { actions as authActions } from './containers/Auth/slice';
import Categories from './containers/Categories';
import { createCategoryRepoSaga } from './containers/Categories/saga';
import { ChannelsPage } from './containers/Channels/Loadable';
// import PrivateRoute from '../utils/PrivateRoute';
import DashboardPage from './containers/Dashboard';
import DashboardHome from './containers/Dashboard/components/Home';
import { HerosPage } from './containers/Heros/Loadable';
import ImportsModule from './containers/Imports';
import { MerchantBrandsRoutes } from './containers/Merchants/components/Loadable';
import MerchantModule from './containers/Merchants/routes';
import MerchantUsers from './containers/MerchantUsers/Loadable';
import EditPage from './containers/Pages/EditPage';
import { usePageSaga } from './containers/Pages/saga';
import { reducer as pageReducer, sliceKey as pageSliceKey } from './containers/Pages/slice';
import ViewPage from './containers/Pages/ViewPage';
import PermissionGroups from './containers/PermissionGroups/Loadable';
import { ProductRoutes } from './containers/Products/Loadable';
import Settings from './containers/Settings';
import SystemSettings from './containers/System';
import { TagsPage } from './containers/Tags/Loadable';
import { TvPostRoutes } from './containers/TvPost/Loadable';
import UserManagement from './containers/TvUsers/components/AllUsers';
import FeedLogs from './containers/TvUsers/components/AllUsers/components/FeedLogs';
import Impressions from './containers/TvUsers/components/AllUsers/components/Impressions';
import WatchHistory from './containers/TvUsers/components/AllUsers/components/WatchHistory';
import { UserManagementHome } from './containers/TvUsers/components/AllUsers/Loadable';
import Devices from './containers/TvUsers/components/Devices';
import { UnsupportedScreen } from './containers/UnsupportedScreen';

export function App() {
  // auth
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userRepoSaga });

  useInjectReducer({ key: channelSliceKey, reducer: channelReducer });
  useInjectSaga({ key: channelSliceKey, saga: createChannelRepoSaga });

  useInjectReducer({ key: heroSliceKey, reducer: heroReducer });
  useInjectSaga({ key: heroSliceKey, saga: createHeroRepoSaga });

  useInjectReducer({ key: categorySliceKey, reducer: categoryReducer });
  useInjectSaga({ key: categorySliceKey, saga: createCategoryRepoSaga });

  // pages saga for privacy policy & terms of service
  useInjectReducer({ key: pageSliceKey, reducer: pageReducer });
  useInjectSaga({ key: pageSliceKey, saga: usePageSaga });


  const { width } = useWindowDimensions();
  const direction = useSelector(selectDirection);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const authSelector = useSelector(selectAuthenticUser);

  const isTV = window.location.href.includes('?tv=true');
  const selectedLanguage = useMemo(() => {
    return {
      language: localStorage.getItem('i18nextLng'),
      direction,
    };
  }, [direction]);

  const toggleDirection = () => {
    dispatch(themeActions.toggleDirection());
  };

  useEffect(() => {
    dispatch(authActions.authenticateUser());
  }, []);

  useEffect(() => {
    if (selectedLanguage) {
      if (
        selectedLanguage.language === 'hw' &&
        selectedLanguage.direction !== 'rtl'
      ) {
        toggleDirection();
      }
      if (
        selectedLanguage.language === 'en' &&
        selectedLanguage.direction !== 'ltr'
      ) {
        toggleDirection();
      }
    }
    // eslint-disable-next-line
  }, [selectedLanguage]);
  //   {
  //     /*
  // width && width >= 1280 ?
  //               <PrivateRoute>
  //                 <DashboardPage />
  //               </PrivateRoute>
  //               : <UnsupportedScreen />
  //             */
  //   }

  const isAccessible = (name: string): boolean => {
    if (user.role !== 'MERCHANT_USER') {
      return true;
    }
    if (user.role === 'MERCHANT_USER' && name == 'SETTINGS_SYSTEM') {
      return false
    }
    if (user.role === 'MERCHANT_USER' && name == 'SETTINGS_IMPORTS') {
      return false
    }
    if (user.role === 'MERCHANT_USER' && name == 'TV_USERS_SETTINGS') {
      return false
    }
    if (user.role === 'MERCHANT_USER' && name == 'SETTINGS_LOGS') {
      return false
    }
    const hasPermission = user.permissionGroups.filter(
      permission => permission.permissions[name] !== 'NO_ACCESS',
    );
    return user.role === 'MERCHANT_USER' && hasPermission.length > 0;
  };

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - MyStream" defaultTitle="MyStream">
        <meta name="description" content="A MyStream application" />
      </Helmet>
      <div dir={direction}>
        {isTV || (width && width >= 1280) ? (
          <Routes>
            <Route path="/login/*" element={<AuthPage />}>
              <Route index element={<LoginForm className="" />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="create-password" element={<CreatePassword />} />
            </Route>
            <Route
              path=""
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            >
              <Route index element={<Navigate to="/dashboard" />} />
              <Route path="dashboard" element={<DashboardHome />} />
              {isAccessible('TV_USERS_SETTINGS') && (
                <Route path="tv-users" element={<UserManagement />}>
                  <Route path="users/*" element={<UserManagementHome />} />
                  <Route path="feed/:id" element={<FeedLogs />} />
                  <Route path=":id/watch-history" element={<WatchHistory />} />
                  <Route path="devices/*" element={<Devices />} />
                  <Route
                    path="feed/:id/events/:postid"
                    element={<Impressions />}
                  />
                  <Route path="feed/:id/events" element={<Impressions />} />
                </Route>
              )}
              {/* <Route path="feed-config" element={<FeedConfigurationPage />} /> */}
              {/* <Route path="tv-users/users" element={<TvUserRoutes />} > */}
              {/* <Route index element={<UserManagement />} /> */}

              {/* </Route> */}

              <Route path="merchants" element={<MerchantModule />}>
                {isAccessible('MERCHANT_ALL') && (
                  <Route path="all/*" element={<MerchantBrandsRoutes />} />
                )}
                {isAccessible('MERCHANT_USERS') && (
                  <Route path="merchant-users/*" element={<MerchantUsers />} />
                )}
                {isAccessible('MERCHANT_PERMISSION_GROUP') && (
                  <Route
                    path="permission-groups/*"
                    element={<PermissionGroups />}
                  />
                )}
              </Route>
              {/* <Route index element={<MerchantRoutes />} /> */}
              {/* <Route path=":id/:action" element={<MerchantDetailScreen />} />
                <Route path="new" element={<MerchantDetailScreen />} />
                <Route path=":id" element={<MerchantDetailScreen />} /> */}

              {isAccessible('SETTINGS_TV_POST') && (
                <Route path="tv-post/*" element={<TvPostRoutes />}>
                  {/* <Route index element={<TvPostRoutes />} /> */}
                  {/* <Route path=":id/:action" element={<TvPostDetail />} />
                <Route path="new" element={<TvPostDetail />} />
                <Route path=":id" element={<TvPostDetail />} /> */}
                </Route>
              )}
              {isAccessible('SETTINGS_PRODUCTS') && (
                <Route path="products/*" element={<ProductRoutes />}>
                  {/* <Route index element={<ProductHome />} />
                <Route path=":id/:action" element={<ProductDetail />} />
                <Route path="new" element={<ProductDetail />} />
                <Route path=":id" element={<ProductDetail />} /> */}
                </Route>
              )}
              {(isAccessible('SETTINGS_IMPORTS')) && (
                <Route path="imports/*" element={<ImportsModule />} />
              )}
              {/* <Route  > */}
              {/* <Route path='pages/privacy-policy' element={<PrivacyPolicyEdit/>}  /> */}
              {/* </Route> */}
              <Route path="settings" element={<Settings />}>
                {(isAccessible('SETTINGS_SYSTEM')) && (
                  <Route path="system" element={<SystemSettings />} />
                )}
                {isAccessible('SETTINGS_CHANNELS') && (
                  <Route path="channels" element={<ChannelsPage />} />
                )}
                {isAccessible('SETTINGS_HEROS') && (
                  <Route path="heros" element={<HerosPage />} />
                )}
                {isAccessible('SETTINGS_TAGS') && (
                  <Route path="tags/*" element={<TagsPage />} />
                )}
                {isAccessible('SETTINGS_TAGS') && (
                  <Route path="categories" element={<Categories />} />
                )}
                {isAccessible('SETTINGS_LOGS') && (
                  <Route path="audit-logs" element={<AuditLogsPage />} />
                )}
              </Route>
              {isAccessible('SETTINGS_TERMS_OF_SERVICE') && (
                <Route path="pages/:name" element={<EditPage />} />
              )}
            </Route>
            <Route path="/help/*" element={<ViewPage />} />
            <Route path="terms-of-use" element={<ViewPage />} />
            <Route path="privacy-policy" element={<ViewPage />} />
            {/* <Route path='/watch-history' element={<FeedLogs />} />   */}
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        ) : (
          <UnsupportedScreen />
        )}
      </div>
    </BrowserRouter>
  );
}
