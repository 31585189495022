import { actions as dashboardActions } from 'app/containers/Dashboard/slice';
import queryString from 'query-string';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL, APP_ANALYTICS_URL, APP_API_URL } from 'utils/constants';
import { getDefaultHeaders, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { UserListItemType } from '../types';
import {
    selectEditUser, selectImpressionsList, selectImpressionsQuery, selectPostsQuery, selectQuery
} from './selector';
import { actions } from './slice';

const moduleUrl = API_URL + "/users";
// console.log("moduleUrl", moduleUrl)

export function* update(action) {
  const {
    firstName,
    lastName,
    // password,
    email,
    user_type,
    dob,
  } = yield select(selectEditUser);
  let user: any = {
    firstName: firstName.value,
    lastName: lastName.value,
    // mobileNumber: mobileNumber.value,
    email: email.value,
    user_type: user_type.value,
    dob: dob.value,
  };
  try {
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify(user),
    };
    const response: UserListItemType = yield request(
      `${API_URL}/users/${action?.payload.id}`,
      options,
    );
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Data updated successfully',
        variant: 'success',
      }),
    );
    if (!response.email) yield put(actions.updateFailed(response));
    yield put(actions.updateSuccess(response));
    yield call(action?.payload?.callback);
    // if (response && response) {
    //   throw response?.message;
    // }
  } catch (e: any) {
    const message = JSON.parse(e.message)?.message;
    yield put(actions.updateFailed(message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
    // yield put(
    //   dashboardActions.toggleSnackbar({
    //     open: true,
    //     message,
    //     variant: 'error',
    //   }),
    // );
    // console.log(e, 'something went wrong');
  }
}

export function* handleBlock(action) {
  try {
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
    };
    const response: UserListItemType = yield request(
      `${API_URL}/users/${action?.payload}/unblock`,
      options,
    );

    yield put(actions.handleBlockSuccess(response));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Data updated successfully',
        variant: 'success',
      }),
    );
  } catch (e: any) {
    const message = JSON.parse(e.message)?.message;
    yield put(actions.updateFailed(message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
    // yield put(
    //   dashboardActions.toggleSnackbar({
    //     open: true,
    //     message,
    //     variant: 'error',
    //   }),
    // );
    // console.log(e, 'something went wrong');
  }
}

export function* create() {
  yield delay(500);
}

export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${API_URL}/users?${queries}`, options);
    // const newResponse = response.items?.reduce((p, c, i) => [...p, { ...c, mobileNumber: '9876542', dob: '1997-06-21T11:24:11.000Z' }], [])
    // console.log(newResponse, 'newResponse')
    yield put(actions.getUsersListSuccess(response));
    // console.log(response.items, 'usersList here');
  } catch (e: any) {
    yield put(dashboardActions.openSnackbar(e?.message));
    // console.log(e);
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}

export function* getNextPage(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    if (action && action.payload) {
      const { q, distributorId, user_type, orderDirection, orderField } =
        yield select(selectQuery);
      const requestQuery = {
        ...(distributorId !== '' && {
          distributorId,
        }),
        ...(user_type !== '' && {
          user_type,
        }),
        ...(q !== '' && {
          q,
        }),
        ...(orderDirection !== '' && {
          orderDirection,
        }),
        ...(orderField !== '' && {
          orderField,
        }),
      };
      // console.log(requestQuery);

      const queries = queryString.stringify({
        ...requestQuery,
      });
      const response = yield request(
        action?.payload + `${queries && '&' + queries}`,
        options,
      );
      // console.log(response, 'next items here');
      yield put(actions.getNextPageItemsSuccess(response));
    }
  } catch (e) {
    // console.log(e, 'something went wrong');
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}

export function* deleteRow(action) {
  yield delay(500);
  try {
    const { id, callBack } = action.payload;
    const options = {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${moduleUrl}/${id}`, options);
    // console.log("hello delete row")
    if (response && response.message === 'MESSAGES.DELETE_SUCCESS') {
      yield put(actions.deleteSuccess(id));
      yield call(callBack);
    }
  } catch (e: any) {
    yield put(actions.deleteFailed(e.message));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}

export function* getFeed() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectPostsQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(`${API_URL}/user-posts?${queries}`, options);
    if (response && response.items) {
      yield put(actions.getFeedSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getWatchHistory() {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectPostsQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(`${API_URL}/watch-history?${queries}`, options);
    if (response && response.items) {
      yield put(actions.getWatchHistorySuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNextWatchHistory(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const { q, userId, status, orderDirection, orderField } = yield select(
      selectPostsQuery,
    );
    const requestQuery = {
      ...(q !== '' && {
        q,
      }),
      ...(orderDirection !== '' && {
        orderDirection,
      }),
      ...(orderField !== '' && {
        orderField,
      }),
    };
    // console.log(requestQuery);

    const queries = queryString.stringify({
      ...requestQuery,
    });
    const response = yield request(
      action.payload + `${queries && '&' + queries}`,
      options,
    );

    if (response && response.items) {
      yield put(actions.getNextWatchHistorySuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNextPageFeed(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const { q, userId, status, orderDirection, orderField } = yield select(
      selectPostsQuery,
    );
    const requestQuery = {
      ...(userId !== '' && {
        userId,
      }),
      ...(status !== '' && {
        status,
      }),
      ...(q !== '' && {
        q,
      }),
      ...(orderDirection !== '' && {
        orderDirection,
      }),
      ...(orderField !== '' && {
        orderField,
      }),
    };
    // console.log(requestQuery);

    const queries = queryString.stringify({
      ...requestQuery,
    });
    const response = yield request(
      action.payload + `${queries && '&' + queries}`,
      options,
    );

    if (response && response.items) {
      yield put(actions.getNextPageFeedSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getImpressions() {
  try {
    const options = {
      method: 'GET',
      // headers: getDefaultHeaders(),
    };
    const query = yield select(selectImpressionsQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(
      `${APP_ANALYTICS_URL}/impressions?${queries}`,
      options,
      false,
    );
    if (response && response.items) {
      yield put(actions.getImpressionsSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getNextPageImpressions(action) {
  try {
    const options = {
      method: 'GET',
      // headers: getDefaultHeaders(),
    };
    const { impressionableId, userId, skip, limit } = yield select(
      selectImpressionsQuery,
    );
    const { items } = yield select(selectImpressionsList);
    const requestQuery = {
      ...(impressionableId !== '' && {
        impressionableId,
      }),
      ...(userId !== '' && {
        userId,
      }),
      skip: items.length,
      limit,
    };

    const queries = queryString.stringify({
      ...requestQuery,
    });
    const response = yield request(
      APP_ANALYTICS_URL + `/impressions?${queries && '&' + queries}`,
      options,
      false,
    );

    if (response && response.items) {
      yield put(actions.getNextPageImpressionsSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* useUserSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getNextPage);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.handleBlock.type, handleBlock);
  //

  // feed logs
  yield takeLatest(actions.getFeed.type, getFeed);
  yield takeLatest(actions.getNextPageFeed.type, getNextPageFeed);

  // watch history
  yield takeLatest(actions.getWatchHistory.type, getWatchHistory);
  yield takeLatest(actions.getNextWatchHistory.type, getNextWatchHistory);


  // impressions
  yield takeLatest(actions.getImpressions.type, getImpressions);
  yield takeLatest(actions.getNextPageImpressions.type, getNextPageImpressions);

}
