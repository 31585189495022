import { ISettingOptionItem } from 'app/containers/FeedConfiguration/redux/types';
import { FormElement } from 'app/containers/types';

export type SystemSettingsForm = {
  label: FormElement;
  placeholder: FormElement;
  name: FormElement;
  value: FormElement;
  type: FormElement;
  validator: any;
};

export type AppVersionResponse = {
  web: Array<string>;
  tv: Array<string>;
};

export const defaultAppVersions: AppVersionResponse = {
  web: [],
  tv: [],
};

export interface SettingsField extends ISettingOptionItem {
  label: string;
  select: boolean;
  validator: any;
}

export const initialForm: SystemSettingsForm = {
  label: { value: '' },
  placeholder: { value: '' },
  name: { value: '' },
  value: { value: '' },
  type: { value: '' },
  validator: null,
};

export type SystemSettingsState = {
  loading: boolean;
  form: SystemSettingsForm;
  isOpen: boolean;
  versions: AppVersionResponse;
};

export const initialState: SystemSettingsState = {
  form: initialForm,
  loading: false,
  isOpen: false,
  versions: defaultAppVersions,
};
