import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './types';

export const selectDomain = (state: RootState) =>
  state.systemSettingsState || initialState;

export const selectSettingsForm = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectSettingsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsOpen = createSelector(
  [selectDomain],
  state => state.isOpen,
);

export const selectVersions = createSelector(
  [selectDomain],
  state => state.versions,
);
