import InputField from 'app/components/InputField';
import { FormError } from 'app/containers/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Modal,
  Typography
} from '@mui/material';

import { selectCategoryError, selectCategoryLoading, selectForm } from '../selector';
import { actions } from '../slice';
// import { HeroListItem } from '../types';
import { validate } from '../validator';
import { useStyles } from './styles';

type Props = {
  open: boolean;
  handleClose: any;
  style?: object;
  isEdit?: boolean;
};

const CreateCategoryModal = ({ open, handleClose, style, isEdit }: Props) => {
  const { createCategory } = useSelector(selectForm);

  const styles = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(selectCategoryLoading);
  const error = useSelector(selectCategoryError);
  const [errors, setErrors] = useState<FormError[]>([]);

  useEffect(() => {
    if (open == false) {
      setErrors([]);
    }
  }, [open]);

  const closeModal = () => {
    // dispatch(actions.resetForm());
    handleClose();
  };

  const handleInputChange = e => {
    setErrors([]);
    const { checked, name, type } = e.target;
    const value = type === 'checkbox' ? checked : e.target.value;
    dispatch(
      actions.setForm({
        name: `${name}`,
        value,
      }),
    );
  };

  const handleSubmit = async e => {
    dispatch(actions.clearErrorMessage());
    let errors = validate(createCategory);
    let assetErrors: Array<any> = [];

    if (assetErrors && assetErrors.length) {
      errors = [...assetErrors, ...errors];
    }

    if (errors.length) {
      setErrors(errors);
      return false;
    }
    e?.preventDefault();

    if (isEdit) {
      dispatch(
        actions.update({ id: createCategory.id.value, callBack: closeModal }),
      );
    } else {
      dispatch(actions.create({ callBack: closeModal }));
      // dispatch(actions.createCategorySuccess(hero));
    }
  };

  const validateField = e => {
    const errors = validate(createCategory).filter(
      error => error.name === e.target.name,
    );
    if (errors.length) {
      const key = `createCategory`;
      dispatch(actions.setFormErrors({ key, errors }));
      return;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Box className={styles.root}>
          <Box className={styles.titleDiv}>
            <Typography className={styles.title}>
              {isEdit ? 'Edit' : `Create new`} category
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <InputField
            isEdit={isEdit}
            editable
            onBlur={validateField}
            label={'Category Name'}
            backgroundColor={'#F7F7FA'}
            placeholder={'Enter Category Name'}
            fullWidth={true}
            name="name"
            error={createCategory.name.error}
            value={createCategory.name.value}
            onChange={handleInputChange}
            isRequired={true}
          />
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              <Typography>{error}</Typography>
            </Alert>
          )}
          {errors.length > 0 && (
            <>
              {errors.map(error => (
                <Alert severity="error" sx={{ my: 2 }} key={error.error}>
                  <Typography>{error.error}</Typography>
                </Alert>
              ))}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', mb: 2, mr: 2 }}>
          <Button
            onClick={handleClose}
            className={styles.cancleButton}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={styles.createButton}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : isEdit ? (
              'Save'
            ) : (
              'Create'
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCategoryModal;
