import RouteTitle from 'app/components/RouteTitle';
import Snackbar from 'app/components/Snackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Box } from '@mui/material';

import Content from './components/content';
import DashboardHome from './components/Home';
import Sidebar from './components/Sidebar';
import Topbar from './components/topbar';
import { useDashboardSaga } from './saga';
import { selectMenuOpen } from './selector';
import { actions, reducer, sliceKey } from './slice';

type Props = {};

const DashboardPage = (props: Props) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const isSidebarOpen = useSelector(selectMenuOpen);
  const dispatch = useDispatch();
  const createMatch = useMatch('/dashboard');

  const toggleMenu = () => {
    dispatch(actions.toggleMenu());
  };

  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  useInjectSaga({
    key: sliceKey,
    saga: useDashboardSaga,
  });

  useEffect(() => {
    if (createMatch) {
      dispatch(
        actions.setRoute('Dashboard'),
      );
      return;
    }
  }, [createMatch]);

  return (
    <Box
      display="flex"
      alignItems={'center'}
      sx={{ backgroundColor: '#F7F7FA' }}
    >
      <Sidebar setShowSidebar={toggleMenu} showSidebar={isSidebarOpen} />
      <Content>
        <Topbar showSidebar={showSidebar} />

        {/* child routes will be rendered in the <Outlet /> */}
        <Box>
          <Outlet />
        </Box>
        <Snackbar />
      </Content>
    </Box>
  );
};

export default DashboardPage;
