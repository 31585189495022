import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    // display:'flex',
    padding: '0px 30px 10px 30px',
    // margin: '0px 30px',
  },
  dateDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
  },
  statusMenu: {
    marginLeft: 21,
    "& .MuiOutlinedInput-root": {
      background: "#FFF",
      width: 184,
      borderRadius: "6px"
    }
  },
  dateDivRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 0px 0px 0px'
  },
  page: {
    position: "relative",
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 50,
    marginBottom: 24
  },
  ml25: {
    marginLeft: "25px !important",
  },
  search: {
    background: "#F5F5F5",
    border: "none",
    borderRadius: 7,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    }
  },
  dataTableDiv: {
    borderRadius: 6,
    maxHeight: 'calc(100vh - 172px)',
    border: "1px solid #D5D8DE",
    overflowY: "auto",
    marginTop: '14px',
    // borderTop:'0.5px solid #9CA2B0',
    "&::-webkit-scrollbar": {
      width: 4,
      height: 5,
      padding: '5px 0px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#D9D9D9',
      // marginTop:500,
      // boxShadow: 'inset 4px 4px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#959595',
      borderRadius: 20,
      // border: '1px solid #3C4858',
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#959595',
    }
  },
  addBtn: {
    fontSize: 14,
    color: "#333333",
    border: '1px solid #D5D8DE',
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  datePicker1: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1,
    right: '200px'
  },
  datePicker: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1
  },
  dateButton: {
    color: '#333333',
    border: '1px solid #D5D8DE',
    backgroundColor: '#F7F7FA',
    minWidth: '180px',
    fontSize: '14px',
    minHeight: '45px'
  },
}))