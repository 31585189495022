import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#FAA61A',
    fontFamily: 'Poppins',
  },
  titleDiv: { display: 'flex', justifyContent: 'space-between' },
  statusDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0px',
  },
  modalLable: {
    marginTop: '24px',
    marginBottom: '12px',
  },
  logoButton: {
    padding: '20px 15px',
    cursor: 'auto',
    borderRadius: '4px',
    // maxHeight: 40,
    border: '0.5px solid',
  },
  editLogoButton: {
    fontSize: '1.6vh',
    padding: '13px 15px',
    color: '#333333',
    borderColor: '#D5D8DE',
    backgroundColor: '#F7F7FA',
    fontWeight: '500',
    cursor: 'pointer',
    borderRadius: '4px',
    borderStyle: 'dashed',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: '#D5D8DE',
      backgroundColor: '#F7F7FA',
    },
  },
  setLogoButton: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.6vh',
    padding: '20px 15px',
    color: '#333333',
    borderColor: '#D5D8DE',
    backgroundColor: '#F7F7FA',
    fontWeight: '500',
    alignItems: 'start',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
    borderRadius: '4px',
    borderStyle: 'dashed',
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: '#D5D8DE',
      backgroundColor: '#F7F7FA',
    },
  },
  logoText: { fontSize: 13, fontWeight: '400', textAlign: 'center' },
  uploadImageText: {
    textDecoration: 'underline',
    fontSize: '14px',
    fontFamily: 'Poppins',
  },

  cancleButton: {
    color: '#222',
    border: '1px solid',
    height: '40px',
    width: '100px',
  },

  createButton: {
    color: '#fff',
    height: '40px',
    width: '100px',
    marginLeft: '10px',
  },

  imageSizeText: { fontSize: '10px', color: '#929CB9' },

  imageDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // backgroundColor:'#aba'
  },

  root: {
    width: '500px',
  },
}));
