import { makeStyles } from '@mui/styles';

export const useSystemTopBarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    borderBottom: '1px solid #000',
    position: 'sticky',
    top: 0,
    left: 0,
  },
}));
