import FormFieldWithLabel from 'app/components/FormFieldWithLabel';
import { Select } from 'app/components/Select';
import { selectSystemSettingList } from 'app/containers/FeedConfiguration/redux/selector';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close } from '@mui/icons-material';
import {
    Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, MenuItem,
    Typography
} from '@mui/material';

import { selectSettingsForm, selectVersions } from '../../selector';
import { actions } from '../../slice';

type Props = {
  onClose: any;
  dialogProps: DialogProps;
  label?: any;
  field?: any;
};

const EditDialog = (props: Props) => {
  const { name, value, placeholder, label, type, validator } =
    useSelector(selectSettingsForm);
  const items = useSelector(selectSystemSettingList);
  const dispatch = useDispatch();

  const handleChange = e => {
    const { value } = e.target;
    dispatch(actions.updateFormValue({ name: 'value', value }));
  };
  const handleUpdateSettings = () => {
    const item= items.find(
      item => item.name === name.value
    );

    dispatch(actions.updateSetting({...item, value: value.value }));
  };

  const formLabel = label.value ? label.value : 'Enter value';
  const versions = useSelector(selectVersions);
  console.log({versions:versions[type.value]})

  return (
    <Dialog
      {...props.dialogProps}
      onClose={() => {
        dispatch(actions.resetForm());
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 24, color: '#FAA61A' }}>
          Edit Default Value
        </Typography>
        <IconButton onClick={props.onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormFieldWithLabel
          inputProps={{
            fullWidth: true,
            sx: { width: 452 },
            onChange: handleChange,
            label: props.label,
            name: name.value,
            value: value.value,
            error: !!value.error,
            helperText: value.error,
          }}
          label={formLabel}
        >
          {type.value && (
            <Select
              fullWidth
              width={452}
              name={name.value}
              value={value.value}
              onChange={handleChange}
              placeholder={label.value}
              // label={props.label}
              error={!!value.error}
              helperText={value.error}
              menuItems={
                versions[type.value] &&
                versions[type.value].map((version: any) => (
                  <MenuItem key={version} value={version}>
                    {version}
                  </MenuItem>
                ))
              }
            />
          )}
        </FormFieldWithLabel>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ color: '#FFF' }}
          onClick={handleUpdateSettings}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
