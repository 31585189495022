import React from 'react';

import SettingsField from '../../SettingsField';
import SettingsSection from '../../SettingsSection';

type Props = {
  onEdit: any;
};
const headings = [{ name: 'Action' }, { name: 'Version' }, { name: 'Default value' }];
const VersionManagement = ({ onEdit }: Props) => {
  return (
    <>
      <SettingsSection
        heading={'Latest supported tv app version'}
        headings={headings}
      >
        <SettingsField
          name="TV_APP_VERSION"
          label="Tv app version"
          onEdit={onEdit}
          select='tv'
          // renderLabel={false}
          value="1.0.43"
        // fullWidthValue={false}
        />
      </SettingsSection>
      <SettingsSection
        heading="Latest supported mobile app version"
        headings={headings}
      >
        <SettingsField
          // renderLabel={false}
          onEdit={onEdit}
          name="MOBILE_APP_VERSION"
          label="Web app version"
          value="1.0.43"
          select='web'
        // fullWidthValue={false}
        />
      </SettingsSection>
    </>
  );
};

export default VersionManagement;
