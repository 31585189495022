import FileDetails from 'app/components/FileDetails';
import { Select } from 'app/components/Select';
import { selectFileForUpload, selectImportsLoading } from 'app/containers/Imports/redux/selector';
import { ImportForm } from 'app/containers/Imports/redux/types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentScanner } from '@mui/icons-material';
import { Box, Button, LinearProgress, MenuItem, Typography } from '@mui/material';

import { actions } from '../../../redux/slice';
import { useImportFormStyles } from '../styles';

type Props = {
  form: ImportForm;
  uploadProgress?: number;
  error?: any;
  setError?: any;
};

const moduleList = [
  {
    value: 'PRODUCT',
    label: 'Product',
  },
  {
    value: 'MERCHANT',
    label: 'Merchant',
  },
  {
    value: 'POST',
    label: 'Post',
  },
];

const Form = (props: Props) => {
  const dispatch = useDispatch();
  const { form } = props;
  const styles = useImportFormStyles();
  const isLoading = useSelector(selectImportsLoading);
  const fileForUpload = useSelector(selectFileForUpload);
  const handleFileSelection = e => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      dispatch(actions.selectFileToUpload(file));
    }
  };

  const handleChoose = () => {
    props?.setError('');
    document.getElementById('excelSelector')?.click();
  };

  const handleFormFieldChange = e => {
    const { name, value } = e.target;
    dispatch(actions.setFormField({ name, value }));
  };

  const resetUploadForm = () => {
    dispatch(actions.resetForm());
  };

  return (
    <form style={{ minWidth: 500, padding: '16px' }}>
      <Box>
        <Select
          error={!!form.type.error}
          helperText={form.type.error}
          name="type"
          value={form.type.value}
          onChange={handleFormFieldChange}
          fullWidth
          placeholder="Select Module"
          menuItems={moduleList.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
          sx={{ background: '#FFF' }}
        />
      </Box>

      <input
        type="file"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        style={{ display: 'none', visibility: 'hidden' }}
        id="excelSelector"
        onChange={handleFileSelection}
      />
      {fileForUpload && isLoading && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
              mb: 2,
            }}
          >
            <Typography>Uploading {props?.uploadProgress} %</Typography>
            <LinearProgress
              variant="determinate"
              value={props?.uploadProgress}
            />
          </Box>
        </>
      )}
      {fileForUpload ? (
        <FileDetails
          className={styles.fileDetails}
          fileSize={fileForUpload.size || 0}
          fileName={fileForUpload.name || ''}
          onRemove={resetUploadForm}
          onEdit={handleChoose}
        />
      ) : (
        <Button
          variant="outlined"
          onClick={handleChoose}
          fullWidth
          className={styles.fileSelectButton}
        >
          <Box sx={{ color: '#929CB9' }}>
            <DocumentScanner />
          </Box>
          <Box className={styles.imageDiv}>
            <Typography className={styles.uploadImageText}>Browse</Typography>
            <Typography className={styles.imageSizeText}>
              .xls or .xlsx file
            </Typography>
          </Box>
        </Button>
      )}
      {props?.error && (
        <Box sx={{ mx: 2 }}>
          <Typography sx={{ color: '#FF0000', fontSize: 12 }}>
            {props?.error}
          </Typography>
        </Box>
      )}
    </form>
  );
};

export default Form;
