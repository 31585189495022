import React from 'react';
import { Outlet } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { useMerchantSaga } from './redux/saga';
import { reducer, sliceKey } from './redux/slice';

type Props = {};

const MerchantModule = (props: Props) => {
    // merchant
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: useMerchantSaga });
  return <Outlet />;
};

export default MerchantModule;
