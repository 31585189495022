import { makeStyles } from '@mui/styles';

export const useSettingSectionTableStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'separate',
    borderRadius: 6,
    // '& thead tr th:first-child': {
    //   borderTopLeftRadius: 6,
    //   borderTopRightRadius: 6,
    // },
    '& tbody tr td': {
      borderBottom: 'none',
    },
    '& tbody tr td:first-child': {
      borderLeft: '1px solid #D5D8DE',
    },
    '& tbody tr td:last-child': {
      borderRight: '1px solid #D5D8DE',
    },
    '& tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: '6px',
    },
    '& tbody tr:last-child td': {
      borderBottom: '1px solid #D5D8DE'
    },
    '& tbody tr:last-child td:last-child': {
      borderBottomRightRadius: '6px',
      maxWidth: 380,
    }
  },
  tableHead: {
    borderTop: '1px solid #D5D8DE',
    '& tr': {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '& th': {
      borderTop: '1px solid #D5D8DE',
    },
    '& th:first-child': {
      borderTopLeftRadius: 6,
      borderLeft: '1px solid #D5D8DE',
      maxWidth: '65px',
      width: '65px',
    },
    '& th:last-child': {
      borderTopRightRadius: 6,
      borderRight: '1px solid #D5D8DE',
      maxWidth: 380,
    },
  },
}));
