import DataTable from 'app/components/DataTable';
import DropDownComponent from 'app/components/DropDownComponent';
import Page from 'app/components/Page';
import PopoverDatePicker from 'app/components/PopoverDatepicker';
import TableLoader from 'app/components/TableLoader';
import Truncate from 'app/components/Truncate';
import AreaChart from 'app/containers/Dashboard/components/Home/components/Chart/AreaChart';
import HorizontalBarChart from 'app/containers/Dashboard/components/Home/components/Chart/HorizontalBarChart';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useResolvedPath } from 'react-router';
import { ctaOptions } from 'utils/constants';
import { getFormattedDateTime } from 'utils/helpers';

import { Box, Button, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';

import {
    getTotalNumberOfUsers, selectAvgTimeStateAverage, selectAvgTimeStateCat,
    selectAvgTimeStateUserCount, selectCTACount, selectCTAName, selectDashboardGraphs,
    selectMostViewCount, selectMostViewName, selectPostLoading, selectPostsList, selectQuery,
    selectTagCount, selectTagName, selectWhishlistCount, selectWhishlistName
} from '../../selector';
import { actions } from '../../slice';
import search from './assets/search.png';
import VerticalBarChart from './components/Chart/VerticalBarChart';
import { useStyles } from './styles';

type Props = {};
type CategoriesLabels = {title: string, poster?: string}
function round(num: number): number {
  return Math.round(num * 100) / 100;
}

const DashboardHome = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [dateRangeState, setDateRangeState] = useState<Array<{ startDate: Date | null; endDate: Date | null; key: string }>>([{ startDate: null, endDate: null, key: 'selection' }]);
  const [sortingValue, setSortingValue] = useState("All");
  const {
    createMin,
    createMax,
    q,
    orderDirection
  } = useSelector(selectQuery);
  const loading = useSelector(selectPostLoading);
  const { avgTimeStats } = useSelector(selectDashboardGraphs);
  const catAvgTimeState = useSelector(selectAvgTimeStateCat);
  const userCount = useSelector(selectAvgTimeStateUserCount);
  const seriesAvgTimeState = useSelector(selectAvgTimeStateAverage);
  const totalNumberOfUsers = useSelector(getTotalNumberOfUsers)
  const dataCtaName = useSelector(selectCTAName)
  const dataCtaCount = useSelector(selectCTACount)
  const dataWishlistName = useSelector(selectWhishlistName)
  const dataWishlistCount = useSelector(selectWhishlistCount)
  const dataMostViewedName = useSelector(selectMostViewName)
  const dataMostViewedCount = useSelector(selectMostViewCount)
  const dataTagName = useSelector(selectTagName)
  const dataTagView = useSelector(selectTagCount)

  const { items } = useSelector(selectPostsList);
  let containerRef: HTMLDivElement | null;
  const [activeButton, setActiveButton] = useState('yesterday');

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    handleBtnYesterday()
  }, [])

  useEffect(() => {
    const [{ startDate, endDate }] = dateRangeState;

    const minCreateDate = getFormattedDateTime(startDate?.toISOString(),
      'yyyy-MM-dd',
    );
    const maxCreateDate = getFormattedDateTime(endDate?.toISOString(),
      'yyyy-MM-dd',
    );
    if (startDate && endDate) {
      dispatch(
        actions.setQuery({
          name: 'createMin',
          value: minCreateDate,
        }),
      );
      dispatch(
        actions.setQuery({
          name: 'createMax',
          value: maxCreateDate,
        }),
      );
    }
  }, [dateRangeState]);

  useEffect(() => {
    if (createMin !== "" && createMax !== "") {
      dispatch(actions.getDashboardStats());
    }
  }, [
    createMin,
    createMax,
    q,
    orderDirection,
    dispatch,
  ]);

  useEffect(() => {
    if (createMin !== "" && createMax !== "") {
      dispatch(actions.getPostList());
    }
  }, [
    createMin,
    createMax,
    q,
    orderDirection,
    dispatch,
  ]);

  const handleDateClear = () => {
    if (createMin !== '') {
      setDateRangeState([{ startDate: null, endDate: null, key: '' }])
      dispatch(actions.setQuery({ name: 'createMin', value: '' }));
    }

    if (createMax !== '') {
      setDateRangeState([{ startDate: null, endDate: null, key: '' }])
      dispatch(actions.setQuery({ name: 'createMax', value: '' }));
    }
  };

  const handleOkayDate = () => {
    setActiveButton("anyDate")
  };

  const handleBtnYesterday = () => {

    setActiveButton("yesterday")
    var yesterday = moment().subtract(1, 'days').toDate()
    setDateRangeState([{ startDate: yesterday, endDate: yesterday, key: '' }])
  };

  const handleBtn3Days = () => {

    setActiveButton("threeDays")
    var date = moment().toDate()
    var threeDaysAgoDate = moment().subtract(3, 'days').toDate()
    setDateRangeState([{ startDate: threeDaysAgoDate, endDate: date, key: '' }])
  };

  const handleBtnLastWeek = () => {

    setActiveButton("lastWeek")
    var startOfWeek = moment().subtract(1, 'weeks').startOf('week').toDate();
    var endOfWeek = moment().subtract(1, 'weeks').endOf('week').toDate();
    setDateRangeState([{ startDate: startOfWeek, endDate: endOfWeek, key: '' }])
  };

  const handleBtnThisMonth = (e) => {

    setActiveButton("thisMonth")
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setDateRangeState([{ startDate: firstDay, endDate: lastDay, key: '' }])
  };

  const handleChange = e => {
    const { label, value } = e.target;
    setSortingValue(value)
    dispatch(actions.setQuery({ name: 'orderDirection', value: value === "All" ? "" : value }));
    dispatch(actions.setQuery({ name: 'orderField', value: value === "All" ? "" : 'selector.impressionsCount' }));
    console.log(label, value, 'name, value')
  };

  const handleSearchChange = e => {
    const { value } = e.target;
    dispatch(actions.setQuery({ name: 'q', value: value }));
  };

  const colors = [
    "#33b2df",
    "#546E7A",
    "#d4526e",
    "#13d8aa",
    "#A5978B",
    "#2b908f",
    "#f9a3a4",
    "#90ee7e",
    "#f48024",
    "#69d2e7"
  ];

  const columns = [

    {
      id: 'keyImage',
      label: 'Img',
      sortValue: 'selector.id',
      minWidth: 80,
      format: value => <div
        style={{
          height: '50px',
          width: '50px',
          backgroundColor: '#C4C4C4',
          borderRadius: '6px',
          backgroundImage: `url('${value.video?.productVideo?.thumbUrl}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      ></div>,//<Truncate value={value?.name} />,
    },
    {
      id: 'name',
      label: 'TV Post Name',
      sortValue: 'selector.name',
      minWidth: 300,
      format: value => <Truncate value={value?.name} limit={100} />,
    },
    {
      id: 'count',
      label: 'Impressions',
      sortValue: 'selector.count',
      minWidth: 400,
      rowAlign: 'left',
    }
  ];

  return (
    <>
      <Page className={styles.pagePadding}>
        <Container
          maxWidth={false}
          className={clsx(styles.pageSpacing)}
        >
          <Grid item xs={12}>
            <Box className={styles.statsHeader}>
              <Box display={'flex'} alignItems="center">
                <Typography className={styles.dateRangeTitle}>
                  {"Change Default Date Range"}
                </Typography>
              </Box>
              <Box display={'flex'} className={styles.dateRangeBox}>
                <Button name="yesterday" className={activeButton === "yesterday" ? `${styles.activeDateRangeButton}` : `${ styles.dateRangeButton }`} onClick={handleBtnYesterday}>
                  Yesterday
                </Button>
                <Button name="threeDays" className={activeButton === "threeDays" ? `${styles.activeDateRangeButton}` : `${styles.dateRangeButton}`} onClick={handleBtn3Days}>
                  3 Days
                </Button>
                <Button name="lastWeek" className={activeButton === "lastWeek" ? `${styles.activeDateRangeButton}` : `${styles.dateRangeButton}`} onClick={handleBtnLastWeek}>
                  Last week
                </Button>
                <Button name="thisMonth" className={activeButton === "thisMonth" ? `${styles.activeDateRangeButton}` : `${styles.dateRangeButton}`} onClick={handleBtnThisMonth}>
                  This month
                </Button>
                <PopoverDatePicker
                  className={styles.dateRangeButton}
                  anyDate={activeButton}
                  classNameStyled={styles.activeDateRangeButton}
                  title={activeButton === "anyDate" ? "" : "Any date"}
                  value={value}
                  setValue={setValue}
                  onChange={setDateRangeState}
                  date={dateRangeState}
                  clearDates={handleDateClear}
                  onOkayDates={handleOkayDate} />
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "5px" }}>
            <Grid
              item
              sm={4}
            >
              <HorizontalBarChart colors={colors} series={dataCtaCount} categories={dataCtaName}  titleOfChart={'TOP 10 most clicked CTA on TV Posts '} />
            </Grid>
            <Grid
              item
              sm={4}
            >
              <HorizontalBarChart colors={colors} series={dataWishlistCount} categories={dataWishlistName}  titleOfChart={'TOP 10 most added TV Posts to Wishlist '} />
            </Grid>
            <Grid
              item
              sm={4}
            >
              <HorizontalBarChart colors={colors} series={dataMostViewedCount} categories={dataMostViewedName}  titleOfChart={'TOP 10 most viewed TV Posts '} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              sm={8}
            >
              <AreaChart colors={undefined} hr={round(avgTimeStats.totalAvgTimeSpent) + " min"} totalNumberOfUsers={totalNumberOfUsers} series={seriesAvgTimeState} categories={catAvgTimeState} usersCount={userCount} />
            </Grid>
            <Grid
              item
              sm={4}
            >
              <VerticalBarChart colors={colors} series={dataTagView} categories={dataTagName}  titleOfChart={'Top 10 most clicked Tags'} />
            </Grid>
          </Grid>
          <Grid container className={styles.container}>
            <Grid item xs={12}>
              <Box className={styles.statsHeader}>
                <Box display={'flex'} alignItems="center">
                  <Typography className={styles.impressionTitle}>
                    {"Impressions"}
                  </Typography>
                  <DropDownComponent
                    formControlProps={{ className: styles.dropDown }}
                    size="medium"
                    value={sortingValue}
                    handleChange={handleChange}
                    title={'Sorting'}
                    menuItems={[
                      { label: 'All', value: 'All' },
                      { label: 'Ascending', value: 'ASC' },
                      { label: 'Descending', value: 'DESC' },
                    ].map(filter => (
                      <MenuItem value={filter.value} key={filter.value}>
                        {filter.label}
                      </MenuItem>
                    ))}
                  />
                </Box>
                <Box display={'flex'} alignItems="center" className={styles.statsHeader}>
                  <TextField
                    className={styles.searchField}
                    InputProps={{
                      startAdornment: <img src={search} style={{ width: 16, height:16, marginRight: 10 }} />,//<SearchIcon style={{ color: "#8F8E8E" }} />,
                    }}
                    onChange={handleSearchChange}
                    name="search"
                    placeholder="Search for TV Post"
                    label={''}
                    fullWidth={true}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
            >
              <Box className={styles.dataTable}>
                <InfiniteScroll
                  loader={<TableLoader />}
                  useWindow={false}
                  getParentScroller={() => containerRef}
                >
                  <DataTable
                    loading={loading}
                    setQuery={setPageQuery}
                    rows={items}
                    columns={columns}
                    orderDirection={orderDirection}
                  />
                </InfiniteScroll>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default DashboardHome;
