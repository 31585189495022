import { StyledTableCell, StyledTableRow } from 'app/components/Table';
import React from 'react';
import { getLogTime } from 'utils/helpers';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  logs: Array<any>;
  columnValues: Array<any>;
};
const useStyles = makeStyles({
  tableRow: {
    border: '1px solid #00000010 !important',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableCellWithLast: {
    paddingTop: 0,
  },
  tableCellWithLastBold: {
    paddingTop: 0,
    fontWeight: '700',
  },
});

const List = ({ logs, columnValues }: Props) => {
  const styles = useStyles();
  return (
    <>
      {logs && logs.length > 0 && (
        logs.map((log, index) => {
          return (
            <React.Fragment key={`message-${index}`}>
              {log.data.map(l => {
                return (
                  <StyledTableRow className={styles.tableRow}>
                    <StyledTableCell>{log.id}:{getLogTime(l.time) }</StyledTableCell>
                    <StyledTableCell className={styles.tableCell}>
                      {l.message}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </React.Fragment>
          );
        })
      )
      //  : (
      //   <StyledTableRow className={styles.tableRow}>
      //     <StyledTableCell
      //       className={styles.tableCellWithLast}
      //       colSpan={columnValues.length}
      //     >
      //       No Logs
      //     </StyledTableCell>
      //   </StyledTableRow>
      // )
      }
    </>
  );
};

export default List;
