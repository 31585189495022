import { DynamicAutoComplete } from 'app/components/DynamicAutoComplete';
import InputField from 'app/components/InputField';
import { TagForm } from 'app/containers/Tags/types';
import React from 'react';
import { API_URL } from 'utils/constants';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

type Props = {
  form: TagForm;
  onChange: any;
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
};

const Form = (props: Props) => {
  const loading = props.loading ? props.loading : false;
  return (
    <Box p={2}>
      <form onSubmit={props.onSubmit}>
        <InputField
          name="name"
          fullWidth
          label="Tag Name"
          size="small"
          placeholder="Enter Tag Name"
          value={props.form.name.value}
          onChange={props.onChange}
          error={props.form.name?.error}
          sx={{ mb: 2 }}
          isRequired={true}
        />
        <Typography sx={{ mb: 1 }}>Category*</Typography>
        <DynamicAutoComplete
          label="Select Category"
          error={props.form.categoryId?.error}
          name="categoryId"
          onChange={e => props.onChange({ target: e })}
          selected={props.form.categoryId.value}
          url={`${API_URL}/categories?`}
        />
        {/* {props.form.categoryId?.error && (
          <>
            <span style={{ color: '#FF0000' }}>
              {props.form.categoryId.error}
            </span>
          </>
        )} */}
        <Box sx={{ textAlign: 'right' }} mt={3}>
          <Button
            disabled={loading}
            variant="outlined"
            color="secondary"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            sx={{ color: '#FFF', ml: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
