import { Tag } from 'app/containers/Tags/types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Delete, Edit } from '@mui/icons-material';
import { Fade, IconButton } from '@mui/material';
import { Box } from '@mui/system';

import { actions } from '../../slice';
import { Category } from '../../types';
import { TagDeleteHandler } from '../CategoryRow';
import { useCategoryTagStyles } from './style';

interface CategoryTagProps extends Tag {
  category: Category;
  onDelete: TagDeleteHandler;
}

const CategoryTag = (props: CategoryTagProps) => {
  const [isActionsVisible, setActionsVisible] = useState(false);
  const styles = useCategoryTagStyles();
  const dispatch = useDispatch();
  const toggleOptions = value => {
    setActionsVisible(value);
  };
  const openEditTag = () => {
    dispatch(
      actions.setEditTag({ ...props, categoryId: props.category } as any),
    );
  };
  const openDeleteTag = () => {
    props.onDelete(props);
  };
  return (
    <li
      className={styles.root}
      onMouseEnter={() => toggleOptions(true)}
      onMouseLeave={() => toggleOptions(false)}
    >
      <Link to={``}>{props.name}</Link>
      <Fade in={isActionsVisible}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ p: 0, color: '#FAA61A' }} onClick={openEditTag}>
            <Edit />
          </IconButton>
          <IconButton sx={{ p: 0, color: '#FF666E' }} onClick={openDeleteTag}>
            <Delete />
          </IconButton>
        </Box>
      </Fade>
    </li>
  );
};

export default CategoryTag;
