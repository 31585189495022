import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { Typography } from '@mui/material';

import { useTopBarTabStyles } from './styles';

type Props = { title: ReactNode; active?: boolean; onChange?: any };

const TopBarTab = ({ title, active, onChange }: Props) => {
  const styles = useTopBarTabStyles();
  return (
    <Typography
      className={clsx({
        [styles.root]: true,
        [styles.active]: active,
      })}
      onClick={onChange}
    >
      {title}
    </Typography>
  );
};

export default TopBarTab;
