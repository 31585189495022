import React from 'react';
import { Outlet } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { useFeedConfigurationSaga } from '../FeedConfiguration/redux/saga';
import { reducer, sliceKey } from '../FeedConfiguration/redux/slice';

type Props = {};

const Search = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useFeedConfigurationSaga });
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Search;
