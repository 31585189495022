import {
    selectFeedSettingList, selectSystemSettingList
} from 'app/containers/FeedConfiguration/redux/selector';
import {
    DefaultSettingOption, ISettingOptionItem
} from 'app/containers/FeedConfiguration/redux/types';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Edit } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow } from '@mui/material';

type Props = {
  actionIcon?: ReactNode;
  name?: string;
  label?: string;
  renderLabel?: boolean;
  value?: any;
  fullWidthValue?: boolean;
  onEdit?: any;
  select?: any;
  validator?: any;
};

const SettingsField = (props: Props) => {
  const items = useSelector(selectSystemSettingList);
  const { renderLabel = true, validator } = props;
  const select: any = props?.select ? props.select : null;
  const [field, setField] = useState<ISettingOptionItem>(DefaultSettingOption);
  const actionIcon = props.actionIcon ? props.actionIcon : <Edit />;
  const fullWidthValue = props.fullWidthValue === undefined ? true : false;
  const onEdit = useCallback(() => {
    const { label } = props;
    props?.onEdit({ label, ...field, select, validator });
  }, [field]);

  useEffect(() => {
    if (props.name) {
      const field = items.find(
        item => item.name === props.name,
      );
      if (field) {
        setField(field);
      }
    }
  }, [props.name, items]);

  return (
    <TableRow>
      <TableCell
      // sx={{ ...(!fullWidthValue && { background: '#FFF' }) }}
      >
        <IconButton onClick={onEdit} sx={{ color: '#FAA61A',minWidth:'50px' }}>
          {actionIcon}
        </IconButton>
      </TableCell>
      {props.label && renderLabel && (
        <TableCell sx={{ borderRight: '1px solid #D5D8DE' }}>
          {props.label}
        </TableCell>
      )}
      <TableCell
        sx={{
          background: '#FFF',
          ...(fullWidthValue && { width: 386 }),
        }}
      >
        {field.value}
      </TableCell>
    </TableRow>
  );
};

export default SettingsField;
