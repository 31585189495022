import React, { useState } from 'react';

import { Box } from '@mui/material';

import TopBarTab from '../TopbarTab';
import { useSystemTopBarStyles } from './styles';

type Props = {
  active: number;
  setActive: any;
};

const tabs = [
  'Default Values',
  'System Parameters',
  'Version Management',
  'Feed configuration',
];

const TopBar = ({ active, setActive }: Props) => {
  const styles = useSystemTopBarStyles();
  const tabLinks = tabs.map((tab, index) => (
    <TopBarTab
      onChange={() => {
        setActive(index);
      }}
      key={`Tab-${index}`}
      active={index === active}
      title={tab}
    />
  ));

  return <Box className={styles.root}>{tabLinks}</Box>;
};

export default TopBar;
