import { Info } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextField } from 'app/components/TextField';
import React from 'react';
import { useStyles } from './styles';

type Props = {
  name: string;
  label: any;
  description: any;
  value: any;
  onChange: any;
  error?: any;
  fieldText?: any;
};

const FeedConfigurationField = (props: Props) => {
  const styles = useStyles();
  return (
    <Box mt={3.2}>
      <Box className={styles.labelRoot}>
        <Typography className={styles.fieldLabel}>{props.label}</Typography>
        <Info sx={{ fontSize: 12, mt: '2px' }} />
        <Typography className={styles.descriptionLabel} sx={{ mt: '2px' }}>
          {props.description}
        </Typography>
      </Box>
      <Box sx={{ pt: '12px', display: 'flex' }}>
        <TextField
          width={"245px"}
          size="small"
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          className={styles.input}
          error={!!props.error}
          helperText={props.error}
        />
        {props.fieldText && (
          <Typography sx={{ alignSelf: 'flex-end', mb: -0.5, ml: 1 }}>
            {props.fieldText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FeedConfigurationField;
