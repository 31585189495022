
import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../types';

const selectDomain = (state: RootState) => state.userDevices || initialState;

export const selectQuery = createSelector(
  [selectDomain],
  state => state.query,
);

export const selectDeviceList = createSelector([selectDomain], state => state.list);
export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
