import { makeStyles } from '@mui/styles';

export const useSettingsSectionStyles = makeStyles(() => ({
  heading: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '21px',
    color: '#1C1C1C',
    marginBottom: 14,
    minWidth: '100px'
  },
}));
