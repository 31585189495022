import { FormElement } from 'app/containers/types';

export interface ISettingOptionItem {
  id: number;
  category: string;
  name: string;
  value: any;
  createdAt: string;
  updatedAt: string;
}

export const DefaultSettingOption: ISettingOptionItem = {
  id: 0,
  category: '',
  createdAt: '',
  name: '',
  updatedAt: '',
  value: '',
};

export interface IFeedConfigurationForm {
  ALL_ADDED_FREQUENCY_BY_VIEW: FormElement;
  ALL_ADDED_FREQUENCY_BY_WISHLIST: FormElement;
  ALL_ADDED_FREQUENCY_BY_CTA: FormElement;
  USER_ADDED_FREQUENCY_BY_VIEW: FormElement;
  USER_ADDED_FREQUENCY_BY_WISHLIST: FormElement;
  USER_ADDED_FREQUENCY_BY_CTA: FormElement;
  TIME_TO_WATCH_TO_IMPLY_LIKE: FormElement;
  VIDEO_WATCHED_TO_IMPLY_ENGAGED: FormElement;
  PCT_ENGAGED: FormElement;
  PCT_NEW: FormElement;
  FEED_TTL: FormElement;
  FEED_PAGING_SIZE: FormElement;
  FEED_SIZE: FormElement;
  FEED_ENABLED: FormElement;
  FEED_RANDOMIZE: FormElement;
}

export const defaultConfigForm: IFeedConfigurationForm = {
  ALL_ADDED_FREQUENCY_BY_VIEW: { value: '' },
  ALL_ADDED_FREQUENCY_BY_WISHLIST: { value: '' },
  ALL_ADDED_FREQUENCY_BY_CTA: { value: '' },
  USER_ADDED_FREQUENCY_BY_VIEW: { value: '' },
  USER_ADDED_FREQUENCY_BY_WISHLIST: { value: '' },
  USER_ADDED_FREQUENCY_BY_CTA: { value: '' },
  TIME_TO_WATCH_TO_IMPLY_LIKE: { value: '' },
  VIDEO_WATCHED_TO_IMPLY_ENGAGED: { value: '' },
  PCT_ENGAGED: { value: '' },
  PCT_NEW: { value: '' },
  FEED_TTL: { value: '' },
  FEED_PAGING_SIZE: { value: '' },
  FEED_SIZE: { value: '' },
  FEED_ENABLED: { value: '' },
  FEED_RANDOMIZE: { value: '' },
};

export interface IFeedConfigResponse {
  items: Array<ISettingOptionItem>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    last: string;
    next: string;
    previous: string;
  };
}

export interface IFeedConfigurationState {
  form: IFeedConfigurationForm;
  list: IFeedConfigResponse;
  loading: boolean;
  error: any;
}

export const initialState: IFeedConfigurationState = {
  form: defaultConfigForm,
  loading: false,
  error: '',
  list: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 100,
      totalItems: 0,
      totalPages: 0,
    },
    links: {
      first: '',
      last: '',
      next: '',
      previous: '',
    },
  },
};
