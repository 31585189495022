import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "2px solid #9CA2B020"
  },
  selectLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: '#3C4858',
    marginBottom: 0,
    paddingBottom: 0,
    lineHeight: 'unset',
  }
}));
