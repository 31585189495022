import DataTable from 'app/components/DataTable';
import ExportExcellButton from 'app/components/ExportExcellButton';
import { StyledTableCell, StyledTableRow } from 'app/components/Table';
import TableLoader from 'app/components/TableLoader';
import { selectSearch } from 'app/containers/Dashboard/selector';
import React, { useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getFormattedDateTime } from 'utils/helpers';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { actions as dashboardActions } from '../../../../../Dashboard/slice';
import { selectLoading, selectPostsQuery, selectUserPostList } from '../../redux/selector';
import { actions } from '../../redux/slice';
import TagList from './components/TagList';

const useStyles = makeStyles({
  tableRoot: {
    maxHeight: 'calc(100vh - 148px)',
    overflowY: 'auto',
  },
  tableRow: {
    border: '1px solid #00000010 !important',
  },
  tableCell: {
    borderTop: '1px solid #00000010 !important',
    paddingBottom: 0,
  },
});

type Props = {};

const FeedLogs = (props: Props) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const searchText = useSelector(selectSearch);
  const { items, links } = useSelector(selectUserPostList);
  const loading = useSelector(selectLoading);
  const { orderDirection, orderField, q, status, userId } =
    useSelector(selectPostsQuery);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(dashboardActions.setRoute('User Feed Logs'));
    if (id) {
      dispatch(actions.setPostsQuery({ name: 'userId', value: id }));
    }
  }, []);

  useEffect(() => {
    dispatch(actions.setPostsQuery({ name: 'q', value: searchText }));
  }, [searchText]);

  useEffect(() => {
    if (userId) {
      dispatch(actions.getFeed());
    }
  }, [userId, q, status, orderField, orderDirection]);

  const columns = [
    {
      id: 'key',
      label: 'ID',
      sortValue: 'selector.id',
      minWidth: 20,
      format: value => (
        <Link target={'_blank'} to={`/tv-post/${value?.id}/view`}>
          {value?.key}
        </Link>
      ),
    },
    {
      id: 'name',
      label: 'Name',
      sortValue: 'selector.name',
      minWidth: 100,
      format: value => value.name || '-',
    },
    {
      id: 'productId',
      label: 'Product Id',
      sortValue: 'product.id',
      minWidth: 20,
      format: value => (
        <Link
          target={'_blank'}
          to={`/products/${value?.product?.id}/view`}
        >
          {value?.product?.key}
        </Link>
      ),
    },
    {
      id: 'createdAt',
      label: 'Created At',
      sortValue: 'selector.createdAt',
      minWidth: 100,
      format: value => getFormattedDateTime(value.createdAt) || '-',
    },
    {
      id: 'updatedAt',
      label: 'Updated At',
      sortValue: 'selector.updatedAt',
      minWidth: 100,
      format: value => getFormattedDateTime(value.updatedAt) || '-',
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'right',
      format: value => (
        <Link to={`/tv-users/feed/${id}/events/${value.id}`}>
          {' '}
          View Impressions
        </Link>
      ),
    },
  ];

  const setQuery = (name: string, value: any) => {
    dispatch(actions.setPostsQuery({ name, value }));
  };

  const hasMoreItems = !!links.next;

  const getNextPage = () => {
    //   console.log('reqChangeActions.getNextPageItems(links.next)==');
    if (!loading) {
      dispatch(actions.getNextPageFeed(links.next));
    }
  };

  // const renderRows = useCallback(() => {
  //   return items.flatMap((item: any) => [
  //     { ...item, tagComponent: <TagList tags={item.tags} /> },
  //   ]);
  // }, [items]);

  const handleRowClick = post => {
    navigate(`/tv-users/users/${id}/events/${post.id}`);
  };

  const renderRows = columnValues => (
    <>
      {items.map((row: any, index) => {
        return (
          <React.Fragment key={`${row.id}-FeedLog-${index}`}>
            <StyledTableRow
              tabIndex={-1}
              onDoubleClick={() => handleRowClick(row)}
              className={styles.tableRow}
            >
              {columnValues.map((column, index) => {
                const value = row[column.id];
                return (
                  <React.Fragment key={column.id + '_' + index + row.id}>
                    <StyledTableCell
                      dir={column.ltr}
                      key={column.id + '_' + index}
                      align={column.align}
                      className={styles.tableCell}
                      style={{
                        ...(column.maxWidth && {
                          maxWidth: column.maxWidth,
                        }),
                        textAlign: column?.rowAlign ?? 'left',
                        color:
                          column.label.includes('status') &&
                            row.status === 'inActive'
                            ? '#B3B3B3'
                            : '#333333',
                        ...(column.minWidth && {
                          minWidth: column.minWidth,
                        }),
                      }}
                    >
                      {column.format ? column.format(row) : value}
                    </StyledTableCell>
                  </React.Fragment>
                );
              })}
            </StyledTableRow>
            <TagList tags={row.tags} columnValues={columnValues} />
          </React.Fragment>
        );
      })}
    </>
  );
  const styles = useStyles();

  return (
    <div className={styles.tableRoot}>
      <div style={{display: 'flex', flexDirection: 'row' }}>
      <ExportExcellButton type="user-posts" params={{userId: id || '', type: 'USER'}} />
      <ExportExcellButton type="user-posts" params={{userId: id || '', type: 'ALL'}} />
      </div>

      <InfiniteScroll
        loader={<TableLoader />}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        useWindow={false}
      >
        <DataTable
          loading={loading && items.length === 0}
          orderDirection={orderDirection}
          orderField={orderField}
          columns={columns}
          rows={items}
          renderRows={renderRows}
          setQuery={setQuery}
        />
      </InfiniteScroll>
    </div>
  );
};

export default FeedLogs;
