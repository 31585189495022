import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { UserState } from '../types';

const selectDomain = (state: RootState) => state.userState || UserState;

export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectUserPostList = createSelector(
  [selectDomain],
  state => state.userPostList,
);
export const selectImpressionsList = createSelector(
  [selectDomain],
  state => state.impressionsList,
);
export const selectPostsQuery = createSelector(
  [selectDomain],
  state => state.postsQuery,
);

export const selectWatchHistoryList = createSelector(
  [selectDomain],
  state => state.watchHistory,
);


export const selectImpressionsQuery = createSelector(
  [selectDomain],
  state => state.impressionsQuery,
);
export const selectList = createSelector([selectDomain], state => state.list);
export const selectQuery = createSelector([selectDomain], state => state.query);
export const selectEditUser = createSelector(
  [selectDomain],
  state => state.form.user,
);
export const selectError = createSelector([selectDomain], state => state.error);
