import { makeStyles } from '@mui/styles';

export const useTopBarTabStyles = makeStyles(() => ({
  root: {
    color: '#000',
    padding: '4px 8px',
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px',
    cursor: 'pointer',
  },
  active: {
    backgroundColor: '#000000',
    color: '#FFF',
  },
}));
