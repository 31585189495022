export const APP_API_URL =
  process.env.REACT_APP_API_URL || 'https://dev.shopitv.zangula.net/api';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development';
export const APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';
export const API_URL = IS_DEVELOPMENT
  ? 'http://localhost:3000/api'
  : APP_API_URL;
export const LOGIN_HEADER_HEIGHT = 96;
export const DASHBOARD_MENU_WIDTH = 256;
export const DASHBOARD_TOP_BAR_HEIGHT = 74;
export const DASHBOARD_MENU_CLOSE_WIDTH = 96;
export const DASHBOARD_TOP_MENU_CLOSE_WIDTH = 48;
export const DEFAULT_PAGE_LIMIT = 10;
export const APP_ANALYTICS_URL =
  process.env.REACT_APP_ANALYTICS_URL ||
  'https://analytics.dev.shopitv.zangula.net';
export const APP_ENV = process.env.REACT_APP_API_ENV || 'LOCAL';

export const statusFilterOptions = [
  { label: 'All', value: 'All' },
  { label: 'Active', value: 'TRUE' },
  { label: 'In-active', value: 'FALSE' },
];

export const defaultCurrencyOptions = [
  { label: '₪', value: 'ILS' },
  { label: '$', value: 'USD' },
  { label: '€', value: 'EUR' },
];

export const ctaOptions = [
  { key: 'APPLY_NOW', value: 'Apply Now' },
  { key: 'BOOK_NOW', value: 'Book Now' },
  { key: 'BUY_TICKETS', value: 'Buy Tickets' },
  { key: 'CALL_NOW', value: 'Call Now' },
  { key: 'CONTACT_US', value: 'Contact Us' },
  { key: 'DONATE_NOW', value: 'Donate Now' },
  { key: 'DOWNLOAD', value: 'Download' },
  { key: 'GET_OFFER', value: 'Get Offer' },
  { key: 'GET_QUOTE', value: 'Get Quote' },
  { key: 'GET_SHOWTIME', value: 'Get Showtimes' },
  { key: 'INSTALL_NOW', value: 'Install Now' },
  { key: 'LEARN_MORE', value: 'Learn more' },
  { key: 'WISHLIST', value: 'Wishlist' },
  { key: 'LISTEN_NOW', value: 'Listen Now' },
  { key: 'OPEN_LINK', value: 'Open Link' },
  { key: 'ORDER_NOW', value: 'Order Now' },
  { key: 'PLAY_GAME', value: 'Play Game' },
  { key: 'REQUEST_TIME', value: 'Request Time' },
  { key: 'SEE_MENU', value: 'See Menu' },
  { key: 'SEND_MESSAGE', value: 'Send Message' },
  { key: 'SEND_WHATSAPP_MSG', value: 'Send WhatsApp Message' },
  { key: 'SHOP_NOW', value: 'Shop Now' },
  { key: 'SIGN_UP', value: 'Sign Up' },
  { key: 'SUBSCRIBE', value: 'Subscribe' },
  { key: 'USE_APP', value: 'Use App' },
  { key: 'VIEW_EVENT', value: 'View Event' },
  { key: 'SHOW_MORE', value: 'Show More' },
  { key: 'FOLLOW', value: 'Follow' },
  { key: 'GET_A_COUPON', value: 'Get A Coupon' },
  { key: 'I_WANT_IT', value: 'I want it!' },
  { key: 'GET_A_LOOK', value: 'Get a Look' },
  { key: 'GET_A_DISCOUNT', value: 'Get a discount' },
  { key: 'JOIN_FOR_FREE', value: 'Join for free ' },
  { key: 'TRY_FOR_FREE', value: 'Try for free' },
  { key: 'SIGN_ME_UP ', value: 'Sign me up ' },
  { key: 'RESERVE_YOUR_SEAT', value: 'Reserve your seat' },
  { key: 'SAVE_ME_A_SPOT', value: 'Save me a spot' },
  { key: 'WRITE_A_REVIEW', value: 'Write a review' },
  { key: 'WHATSAPP', value: 'Whatsapp' },
];

export const ctaValueFinder = (key: any): any => {
  const value = ctaOptions.find(options => {
    return options.key === key
  })
  return value
}

export const round = (num: number): number => {
  return Math.round(num * 100) / 100;
}

export const validFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
export const ctaIconLinks = {
  AFFILIATION:
    'https://d3d3zq6xhke4er.cloudfront.net/dev/assets/2022-12-05/v8pl/20221205T040222537ZAffiliation.png',
  BUY_NOW:
    'https://d3d3zq6xhke4er.cloudfront.net/dev/assets/2022-12-05/jM2F/20221205T040422681ZBuyNow.png',
  FORM: 'https://d3d3zq6xhke4er.cloudfront.net/dev/assets/2022-12-05/Bfpi/20221205T040454747ZForm.png',
  MESSAGE:
    'https://d3d3zq6xhke4er.cloudfront.net/dev/assets/2022-12-05/d3cm/20221205T040646906ZMessage.png',
  PHONE:
    'https://d3d3zq6xhke4er.cloudfront.net/dev/assets/2022-12-05/fNh0/20221205T040708739ZPhone.png',
};

export const ctaIconOptions = [
  { value: 'AFFILIATION', label: 'Affiliation' },
  { value: 'BUY_NOW', label: 'Buy Now' },
  { value: 'FORM', label: 'Form' },
  { value: 'MESSAGE', label: 'Message' },
  { value: 'PHONE', label: 'Phone' },
];

export const userRoles = [
  { value: '', label: '‎' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Account manager', label: 'Account manager' },
  { value: 'Enterprise', label: 'Enterprise' },
  { value: 'Merchant', label: 'Merchant' },
  { value: 'Viewer', label: 'Viewer' },
];

export const srtLanguageOptions = [
  { value: '', label: '‎' },
  { label: 'Hebrew', value: 'HEBREW' },
  // { label: 'English', value: 'ENGLISH' },
];

export const defaultBackgroundPalette = [
  '#FAA61A',
  '#1F0C34',
  '#000000',
  '#4B4088',
  '#4D40E3',
  '#C23579',
];

export const defaultCardBackgroundPalette = [
  '#FFF',
  '#1F0C34',
  '#000000',
  '#4B4088',
  '#4D40E3',
  '#C23579',
];


