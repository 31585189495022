import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrap: { height: 48, width: "160px"  },
  wrapWithSpacing: { height: 48, width: "160px" , marginRight: '20px',  marginLeft: '20px' },
  buttonRoot: {
    background: '#FFF',
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    // background: "linear-gradient(270deg, #E6244D 0.93%, #F5A42B 101.49%), linear-gradient(90.62deg, #FF6460 1.94%, #FF4D92 97.46%)",
    // "-webkit-background-clip": 'text',
    color: '#E6244D',
    // backgroundClip: 'text',
    // textFillColor: "transparent",
  },
  buttonContainer: {
    // background:"#FFF",
    background:
      'linear-gradient(270deg, #E6244D 0.93%, #F5A42B 101.49%) no-repeat border-box, linear-gradient(90.62deg, #FF6460 1.94%, #FF4D92 97.46%) no-repeat border-box',
    padding: '1px',
    borderRadius: '6px',
  },
  mediumBtn: {
    height: 48, width: "280px"
  },
});
