import React from 'react'

type Props = {}

const DeviceDetail = (props: Props) => {
    // console.log('device detail page')
    return (
        <div>DeviceDetail</div>
    )
}

export default DeviceDetail