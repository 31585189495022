import { AlertColor } from '@mui/material';

import { Product } from '../TvPost/redux/responseTypes';

export interface DashboardState {
  menuOpen: boolean;
  loading: boolean;
  error: string | null;
  currentRoute: string;
  snackbar: {
    open: boolean;
    message: string;
    variant: AlertColor;
    translate?: boolean;
  };
  list: {
    items: Array<DashboardPostsApi>;
  };
  totalNumberOfUsers: number;
  graphs: {
    avgTimeStats: AvgTimeState;
    ctaStats: CTAState[];
    wishlistStats: WishlistState[];
    mostViewedStats: MostViewedState[];
    tagStats: TagStates[];
  };
  search: string;
  query: {
    createMin: string;
    createMax: string;
    q: string;
    orderDirection: string;
  };
}

export interface DashboardPostsApi {
  id: string;
  status: boolean;
  isDemoContent: boolean;
  approvalStatus: string;
  productId: string;
  productVideoId: string;
  thankyouMessage: string;
  thankyouBtn: string;
  cta: string;
  smsText: string;
  ctaLink: string;
  fomoScore: string;
  rating: string;
  name: string;
  shortDescription: string;
  longDescription: string;
  link?: null;
  salePriceText: string;
  salePrice: string;
  priceText: string;
  promotionText: string;
  infoStartAt: number;
  infoStartDuration: number;
  infoCloseDuration: number;
  maxVideoDuration: number;
  price: string;
  engagementScore: string;
  impressionsCount: string;
  createdAt: string;
  updatedAt: string;
  product: Product;
  video?: null;
  key: string;
  count: number;
}

export interface TagStates {
  id: string,
  name: string,
  categoryId: string,
  createdAt: string;
  updatedAt: string;
  count: number;
}

export interface AllTagStats {
  id: string,
  cta: string,
  wishlist: string,
  view: string | null,
  sum: string,
  tagId: string,
}

export interface AvgTimeState {
  avgSpentTimeByDate: AvgSpentTimeByDate[],
  totalAvgTimeSpent: number
}

export interface CTAState {
  id: string,
  name: string,
  cta: string,
  count: number
}

export interface WishlistState {
  id: string,
  name: string,
  count: number
}

export interface MostViewedState {
  id: string,
  name: string,
  count: number
}

export const defaultCTAState: CTAState = {
  id: '',
  name: '',
  cta: '',
  count: 0
}

export const defaultWishlistState: WishlistState = {
  id: '',
  name: '',
  count: 0
}

export const defaultMostViewedState: MostViewedState = {
  id: '',
  name: '',
  count: 0
}

export const defaultAvgTimeState: AvgTimeState = {
  avgSpentTimeByDate: [],
  totalAvgTimeSpent: 0
}

export interface AvgSpentTimeByDate {
  _id: string;
  usersCount: number;
  avg: number;
}

export interface TotalNumberOfUsersState {
  totalUsers: number
}

export type InitialDashboardState = DashboardState;
