import { getSignedUrl, uploadUsingSignedURL } from 'app/components/commonSaga';
import queryString from 'query-string';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { getDefaultHeaders, removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { actions as dashboardActions } from '../Dashboard/slice';
import { SignedURLResponse } from '../types';
import { selectChannelForm, selectChannelsQuery } from './selector';
import { actions } from './slice';

// import { request } from 'utils/request';

/**
 * Github repos request/response handler
 */

export function* getChannelsList(action) {
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const query = yield select(selectChannelsQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const response = yield request(`${API_URL}/channels?${queries}`, options);
    if (response && response.items) {
      yield put(actions.getChannelListSuccess(response));
    } else {
      yield put(actions.getChannelListFailed('Something went wrong'));
    }
  } catch (e) {
    yield put(actions.getChannelListFailed('Something went wrong'));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'success',
      }),
    );
  }
}

export function* updateChannelRequest(action) {
  try {
    const { image, channelName, viewOrder, description, status, logoUrl } = yield select(
      selectChannelForm,
    );
    let signedURLResponse: SignedURLResponse;
    let filename = image?.fileName;
    let file = image?.file;
    let thumb = image?.thumb;
    let fileType = image?.fileType;
    let channel: any = {
      name: channelName.value,
      viewOrder: viewOrder.value,
      description: description.value,
      status: status.value,
      image: null,
    };
    if (filename && logoUrl.value !== '') {
      if (logoUrl.value !== image.thumb) {
        const { fileName } = image;
        signedURLResponse = yield getSignedUrl(filename, image.fileType);
        filename = new Date().toISOString() + ` ${fileName}`;
        file = signedURLResponse.key;
        fileType = image.fileType;
        thumb = signedURLResponse.previewUrl;
        if (signedURLResponse.url) {
          yield uploadUsingSignedURL(
            signedURLResponse.url,
            image.fileType,
            image.file,
          );
        }
      }

      channel.image = {
        fileName: filename,
        fileType,
        file,
        thumb,
      };
    }
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify(channel),
    };
    const response = yield request(
      `${API_URL}/channels/${action.payload.id}`,
      options,
    );
    console.log('updated', response);
    yield put(actions.updateChannelSuccess(response));
    yield call(action.payload?.callBack);
  } catch (e: any) {
    // yield put(dashboardActions.toggleSnackbar({
    //   open: true,
    //   message: 'Something went wrong ' + JSON.stringify(e),
    //   variant: 'success',
    // }));
    try {
      const error = JSON.parse(e.message);
      yield put(actions.createChannelFailure(error.message));
    } catch (error) {
      yield put(actions.createChannelFailure('Something went wrong'));
    }
  }
}

export function* deleteChannel(action) {
  try {
    const options = {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/channels/${action.payload.id}`,
      options,
    );
    console.log('delete response', response);
    if (response && response.message === 'MESSAGES.DELETE_SUCCESS') {
      yield put(actions.deleteChannel(action.payload.id));
      yield call(action.payload?.callBack);
    }
  } catch (e) {
    console.log(e, 'Something went wrong');
    yield put(dashboardActions.toggleSnackbar({
      open: true,
      message: 'Something went wrong ' + JSON.stringify(e),
      variant: 'success',
    }));
  }
}

export function* createChannelSaga(action) {
  yield delay(500);

  try {
    // const response = yield call(request, `${API_URL}/`, options);
    const { image, channelName, description, status, logoUrl } = yield select(
      selectChannelForm,
    );

    const { fileName, fileType, file, thumb } = image;
    const filename = new Date().toISOString() + ' ' + fileName;

    let signedUrl: SignedURLResponse;
    let channel: any = {
      name: channelName.value,
      description: description.value,
      status: status.value,
    };
    if (filename && logoUrl.value !== '') {
      signedUrl = yield getSignedUrl(filename, fileType);
      console.log(signedUrl, 'signed url response');

      if (signedUrl.url) {
        const uploadResponse = yield uploadUsingSignedURL(
          signedUrl.url,
          fileType,
          file,
          action.payload.onUploadProgress,
        );
        console.log(uploadResponse, 'uploaded successfully');
      }
      channel.image = {
        fileName: filename,
        fileType,
        file: signedUrl.key,
        thumb: signedUrl.previewUrl,
      };
    }
    const options = {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(channel),
    };
    const response = yield request(`${API_URL}/channels`, options);
    if (response.id) {
      console.log(response);
      yield put(actions.createChannelSuccess(response));
      yield call(action.payload?.callBack);
    }

    yield put(actions.createChannelFailure(response.message));
    // return false;
  } catch (e: any) {
    // yield put(dashboardActions.toggleSnackbar({
    //   open: true,
    //   message: 'Something went talha444 ' + JSON.stringify(e),
    //   variant: 'success',
    // }));
    try {
      const error = JSON.parse(e.message);
      yield put(actions.createChannelFailure(error.message));
    } catch (error) {
      yield put(actions.createChannelFailure('Something went wrong'));

    }
  }
}

export function* getNextPageChannels(action) {
  try {
    if (action.payload) {
      const options = {
        method: 'GET',
        headers: getDefaultHeaders(),
      };
      const { status, orderField, orderDirection, q } = yield select(
        selectChannelsQuery,
      );
      const requestQuery = {
        ...(q !== '' && {
          q,
        }),
        ...(orderField !== '' && {
          orderField,
        }),
        ...(orderDirection !== '' && {
          orderDirection,
        }),
        ...(status !== '' && {
          status,
        }),
      };
      const queries = queryString.stringify({
        ...requestQuery,
      });
      const response = yield request(
        action?.payload + `${queries && '&' + queries}`,
        options,
      );
      if (response.items) {
        yield put(actions.getNextPageChannelsSuccess(response));
      }
      console.log(response, 'Response here');
    } else {
      yield put(actions.getNextPageChannelsFailure());
    }
  } catch (e) {
    console.log(e, 'error here');
    yield put(actions.getNextPageChannelsFailure());
    yield put(dashboardActions.toggleSnackbar({
      open: true,
      message: 'Something went wrong ' + JSON.stringify(e),
      variant: 'success',
    }));
  }
}

// export function* createChannel() {
//   yield takeLatest(actions.create)
// }

export function* createChannelRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.createChannel.type, createChannelSaga);
  yield takeLatest(actions.updateChannelRequest.type, updateChannelRequest);
  yield takeLatest(actions.deleteChannelRequest.type, deleteChannel);
  yield takeLatest(actions.getChannelsList.type, getChannelsList);
  yield takeLatest(actions.getNextPageChannels.type, getNextPageChannels);
}
