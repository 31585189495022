import { getSignedUrl, uploadUsingSignedURL } from 'app/components/commonSaga';
import { SignedURLResponse } from 'app/containers/types';
import queryString from 'query-string';
import { put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { removeBlankProperties } from 'utils/helpers';
import { request } from 'utils/request';

import { actions as dashboardActions } from '../../Dashboard/slice';
import {
    selectFileForUpload, selectImportForm, selectImportLogs, selectImportsList
} from './selector';
import { actions } from './slice';
import { CreateImportResponse, ImportForm, ImportsList, LogList } from './types';

export function* getImportsList(action) {
  console.log('working');
  try {
    const options = {
      method: 'GET',
    };
    const { query }: ImportsList = yield select(selectImportsList);
    const queryProperties = removeBlankProperties(query);
    const queries = queryString.stringify(queryProperties);
    const response: ImportsList | any = yield request(
      `${API_URL}/imports?${queries}`,
      options,
    );

    if (response && response.items) {
      yield put(actions.getListSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* createImport(action) {
  try {
    const form: ImportForm = yield select(selectImportForm);
    const fileForUpload: null | File = yield select(selectFileForUpload);
    const newImport: any = {
      type: form.type.value,
    };
    let signedURLResponse: SignedURLResponse;
    let fileName: any;
    let file: any;
    let thumb: any;
    let fileType: any;
    if (fileForUpload) {
      fileName = Date.now() + ' ' + fileForUpload.name;
      fileType = fileForUpload.type;
      signedURLResponse = yield getSignedUrl(fileName, fileType);
      thumb = signedURLResponse.previewUrl;
      file = signedURLResponse.key;
      if (signedURLResponse.url) {
        yield uploadUsingSignedURL(
          signedURLResponse.url,
          fileForUpload.type,
          fileForUpload,
          action.payload.uploadProgress ? action.payload.uploadProgress : null,
        );
      }
      newImport.doc = {
        type: fileForUpload.type,
        fileName,
        originalName: fileForUpload.name,
        fileType,
        thumb,
        file,
      };
    }
    const options = {
      method: 'POST',
      body: JSON.stringify(newImport),
    };
    const response: CreateImportResponse = yield request(
      `${API_URL}/imports`,
      options,
    );
    if (response && response.id) {
      yield put(actions.createImportSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNextPage(action) {
  try {
    const options = {
      method: 'GET',
    };
    if (action && action.payload) {
      const { query }: ImportsList = yield select(selectImportsList);
      const { q, orderDirection, orderField } = query;
      const requestQuery = {
        ...(q !== '' && {
          q,
        }),
        ...(orderDirection !== '' && {
          orderDirection,
        }),
        ...(orderField !== '' && {
          orderField,
        }),
      };
      console.log(requestQuery);

      const queries = queryString.stringify({
        ...requestQuery,
      });
      const response = yield request(
        action?.payload + `${queries && '&' + queries}`,
        options,
      );
      console.log(response, 'next items here');
      yield put(actions.getNextPageItemsSuccess(response));
    }
  } catch (e) {
    console.log(e, 'something went wrong');
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'Something went wrong ' + JSON.stringify(e),
        variant: 'error',
      }),
    );
  }
}
export function* getImportLogsById(action) {
  try {
    const options = {
      method: 'GET',
    };
    const { query }: LogList = yield select(selectImportLogs);
    const queryProperties = removeBlankProperties(query);
    const queries = queryString.stringify(queryProperties);
    const response: LogList = yield request(
      `${API_URL}/imports/${action.payload.id}/logs?${queries && queries}`,
      options,
    );
    if (response && response.items) {
      yield put(actions.getImportLogByIdSuccess(response));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* processImport(action) {
  try {
    const options = {
      method: 'POST',
    };
    const response: any = yield request(
      `${API_URL}/imports/${action.payload}/process`,
      options,
    );
    if (response === true) {
      yield put(
        dashboardActions.toggleSnackbar({
          open: true,
          message: 'Starting Import processing ',
          variant: 'success',
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function* useImportsSaga() {
  yield takeLatest(actions.getList.type, getImportsList);
  yield takeLatest(actions.getNextPageItems, getNextPage);
  yield takeLatest(actions.createImport.type, createImport);
  yield takeLatest(actions.getImportLogById.type, getImportLogsById);
  yield takeLatest(actions.processImport.type, processImport);
}
