import DataTable from 'app/components/DataTable';
import DeleteModal from 'app/components/DeleteModal';
import DropDownComponent from 'app/components/DropDownComponent';
import GradientButton from 'app/components/GradientButton';
import Loader from 'app/components/Loader';
import TableLoader from 'app/components/TableLoader';
import Truncate from 'app/components/Truncate';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, MenuItem, SelectChangeEvent, Tooltip } from '@mui/material';

import { selectSearch } from '../Dashboard/selector';
import { actions as dashboardActions } from '../Dashboard/slice';
import CategoryRow from './components/CategoryRow';
import CreateCategoryModal from './components/CreateCategoryModal';
import TagDetails from './components/TagDetails';
import {
    selectCategoryList, selectCategoryLoading, selectCategoryQuery, selectForm, selectOpenTagForm
} from './selector';
import { actions } from './slice';
import { useStyles } from './styles';

type Props = {};

const Categories = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createCategory } = useSelector(selectForm);
  const [open, setOpen] = useState(false);
  const { items, links } = useSelector(selectCategoryList);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    dispatch(actions.resetForm());
    dispatch(actions.clearErrorMessage());
  };
  const [isEdit, setIsEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isTagDeleteOpen, setTagDeleteOpen] = useState<any>(null);
  const searchField = useSelector(selectSearch);
  const { status, q, orderDirection, orderField } =
    useSelector(selectCategoryQuery);
  const loading = useSelector(selectCategoryLoading);
  const openTagForm = useSelector(selectOpenTagForm);
  const handleChange = (event: SelectChangeEvent) => {
    dispatch(actions.setQuery({ name: 'status', value: event.target.value }));
  };

  const addCategory = () => {
    handleOpen();
    setIsEdit(false);
  };
  const editCategory = value => {
    console.log(value);
    dispatch(actions.setEditCategory(value));
    handleOpen();
    setIsEdit(true);
  };

  const openDeleteCategoryModal = value => {
    dispatch(actions.setEditCategory(value));
    setDeleteOpen(true);
  };

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    dispatch(actions.resetCategoryForm());
    // dispatch(actions.setEditCategoryEmpty());
  };

  const handleDelete = () => {
    if (createCategory.id) {
      dispatch(
        actions.delete({
          id: createCategory.id.value,
          callBack: handleDeleteClose,
        }),
      );
    }
  };
  useEffect(() => {
    dispatch(actions.setQuery({ name: 'q', value: searchField }));
  }, [searchField]);

  useEffect(() => {
    dispatch(actions.getList());
  }, [status, q, orderField, orderDirection]);

  const hasMoreItems = !!links.next;

  const getNextPage = () => {
    dispatch(actions.getNextPage(links.next));
  };

  useEffect(() => {
    dispatch(dashboardActions.setRoute('Tags & Categories'));
  }, []);

  const handleRender = useCallback(() => {
    return (
      <>
        {items.map(category => (
          <CategoryRow
            onEdit={editCategory}
            onCategoryDelete={openDeleteCategoryModal}
            onTagDelete={setTagDeleteOpen}
            key={`category-${category.id}`}
            {...category}
          />
        ))}
      </>
    );
  }, [items]);

  const handleTagDeleteClose = () => {
    setTagDeleteOpen(null);
  };

  const addTag = () => {
    dispatch(actions.setOpenTagForm());
  };

  const handleTagDelete = () => {
    dispatch(
      actions.deleteTag({ ...isTagDeleteOpen, callBack: handleTagDeleteClose }),
    );
  };

  useEffect(() => {
    console.log(`open`, openTagForm);
  }, [openTagForm]);

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <TagDetails />
        <CreateCategoryModal
          isEdit={isEdit}
          open={open}
          handleClose={handleClose}
        />
        <DeleteModal
          open={isTagDeleteOpen}
          loading={loading}
          handleClose={handleTagDeleteClose}
          onSuccess={handleTagDelete}
          title={`Are you sure yo want to remove ${isTagDeleteOpen?.name}`}
          buttonText="Yes, remove"
        />
        <DeleteModal
          loading={loading}
          open={deleteOpen}
          handleClose={handleDeleteClose}
          onSuccess={handleDelete}
          title={`Are you sure you want to remove ${createCategory?.name.value}`}
          buttonText="Yes, remove"
        />

        <GradientButton
          sx={{
            color: '#FAA61A',
            borderColor: '#FAA61A',
            height: '48px',
            display: 'flex',
            backgroundColor: '#fff',
            '&:hover': {
              background: 'rgba(255,255,255,0.5)',
            },
            mr: 1,
          }}
          onClick={addTag}
          variant="outlined"
        >
          Add New Tag
        </GradientButton>
        <Box sx={{ ml: 2.2 }}></Box>
        <GradientButton
          sx={{
            color: '#FAA61A',
            borderColor: '#FAA61A',
            height: '48px',
            display: 'flex',
            backgroundColor: '#fff',
            '&:hover': {
              background: 'rgba(255,255,255,0.5)',
            },
          }}
          onClick={addCategory}
          variant="outlined"
        >
          Add New Category
        </GradientButton>
        {/* <Gradiant className={classes.herosButton} onClick={addCategory}>
          Add new category
        </Gradiant> */}
      </div>
      <Loader
        className={classes.dataTableDiv}
        loading={loading && !open && !deleteOpen}
      />

      {(!loading || open || deleteOpen) && (
        <div className={classes.dataTableDiv}>
          <InfiniteScroll
            loader={<TableLoader />}
            hasMore={hasMoreItems}
            loadMore={getNextPage}
            useWindow={false}
          >
            {handleRender()}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default Categories;
