import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { FormError } from '../types';
import { AppVersionResponse, initialState, SettingsField } from './types';

export const systemSettingSlice = createSlice({
  name: 'systemSettingsState',
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = !state.loading;
    },
    toggleOpen: state => {
      state.isOpen = !state.isOpen;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ name: string; value: any }>,
    ) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, '');
    },
    updateSettings: (state, action) => {
      state.loading = true;
    },
    updateSetting: (state, action) => {
      state.loading = true;
    },
    updateSettingsSuccess: (state, action) => {
      state.loading = false;
      state.form = initialState.form;
      console.log('complete');
    },
    setFieldValue: (state, action: PayloadAction<SettingsField>) => {
      state.form.label.value = action.payload.label;
      state.form.name.value = action.payload.name;
      state.form.value.value = action.payload.value;
      state.form.type.value = action.payload.select;
      if (action.payload?.validator) {
        state.form.validator = action.payload.validator;
      }
    },
    resetForm: state => {
      state.form = initialState.form;
    },
    getAppVersions: state => {
      state.loading = false;
    },
    getAppVersionsSuccess: (
      state,
      action: PayloadAction<AppVersionResponse>,
    ) => {
      state.loading = true;
      state.versions = action.payload;
    },
    setFormErrors: (state, action: PayloadAction<Array<FormError>>) => {
      action.payload.forEach(error => {
        set(state.form, error.name, error.error);
      });
    },
  },
});
export const { name: sliceKey, actions, reducer } = systemSettingSlice;
