import { FormElement } from 'app/containers/types';

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type UserListItemType = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  mobileNumber: string | null;
  countryCode: string | null;
  language: string | null;
  email: string | null;
  isActive: boolean;
  role: string | null;
  lastLogin: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  dob: string | null;
  ip: string | null;
  mac: string | null;
  loginAttempts: number | null;
  blockedUntil: string | null;
};

export interface NewUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  user_type: string;
  isActive: any;
}

export type NewUserResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type UserForm = {
  id: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  dob: FormElement;
  isActive: FormElement;
  mobileNumber: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  ip: FormElement;
  mac: FormElement;
  loginAttempts: FormElement;
  blockedUntil: FormElement;

  user_type: FormElement;
};

export type UserStateType = {
  impressionsQuery: {
    skip: number;
    limit: number;
    // q: string;
    // status: string;
    // orderField: string;
    // orderDirection: string;
    userId: string;
    impressionableId: string;
  };
  postsQuery: {
    page: number;
    limit: number;
    q: string;
    status: string;
    orderField: string;
    orderDirection: string;
    userId: string;
  };
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    user: UserForm;
  };
  watchHistory: {
    items: Array<UserListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  },
  userPostList: {
    items: Array<UserListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  list: {
    items: Array<UserListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  impressionsList: {
    items: Array<any>;
    total: number;
    limit: number;
    skip: number;
  };
};

export const UserState: UserStateType = {
  impressionsQuery: {
    userId: '',
    skip: 0,
    limit: 10,
    impressionableId: '',
  },
  postsQuery: {
    userId: '',
    page: 1,
    q: '',
    limit: 10,
    orderDirection: '',
    orderField: '',
    status: '',
  },
  query: {
    user_type: '',
    distributorId: '',
    limit: 15,
    page: 1,
    q: '',
    orderField: '',
    orderDirection: '',
    updateMax: '',
    updateMin: '',
    createMax: '',
    createMin: '',
  },
  userPostList: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  watchHistory: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  impressionsList: {
    items: [],
    limit: 10,
    total: 0,
    skip: 0,
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  loading: false,
  form: {
    user: {
      id: { value: '' },
      firstName: { value: '' },
      lastName: { value: '' },
      mobileNumber: { value: '' },
      createdAt: { value: '' },
      dob: { value: '' },
      email: { value: '' },
      updatedAt: { value: '' },
      user_type: { value: '' },
      ip: { value: '' },
      mac: { value: '' },
      loginAttempts: { value: '' },
      blockedUntil: { value: '' },
      isActive: { value: '' },
    },
  },
  error: '',
};

export type ContainerState = UserStateType;
