import { selectUser } from 'app/containers/Auth/selectors';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { actions as auditLogActions } from '../../../AuditLogs/redux/slice';
import { actions as channelActions } from '../../../Channels/slice';
import { actions as heroActions } from '../../../Heros/slice';
import { actions as productActions } from '../../../Products/redux/slice';
import { actions as tagActions } from '../../../Tags/slice';
import { actions as tvPostActions } from '../../../TvPost/redux/slice';
import { actions } from '../../slice';

type Props = {
  name: string;
  path: string;
  target?: string;
  icon?: any;
  isDifferent?: boolean;
  end?: boolean;
  names?: object[];
  displayName?: string;
  disabled?: boolean;
  data: Array<any>;
  id: number | string;
  parent: number | string;
  permissionName?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    textDecoration: 'none',
    color: '#3C4858',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  active: {
    borderRadius: 6,
    // boxShadow: '0px 4px 4px rgba(239, 99, 40, 0.35)',
    background: '#F7F7FA',
    color: '#FAA61A!important',
    '& *': {
      color: '#FAA61A!important',
    },
    '& img': {
      color: '#3D3F47!important',
      // filter: 'brightness(1) invert(100%) saturate(4) hue-rotate(180deg)',
    },
  },
  icon: {
    color: '#8F8E8E',
    marginRight: 18,
    background: 'transparent',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    width: '100%',
    // backgroundColor: "#333",
  },
  isDifferent: {
    paddingLeft: 17,
  },
  isSettingDropDown: {
    height: '40px',
    marginLeft: '30px',
  },
  diffIcon: {
    // marginRight: 18,
  },
  link: {
    background: 'transparent',
  },
  parent: {
    padding: '14px 20px',
  },
  accordionContainer: {
    '&.MuiPaper-root.MuiAccordion-root': {
      margin: 0,
    },
    '&.MuiPaper-root.MuiAccordion-root:before': {
      display: 'none',
      backgroundColor: 'transparent!important',
    },
  },
  accordionSummary: {
    margin: 0,
    backgroundColor: '#fff',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expandedAccordion: {
    color: '#FAA61A',
  },
}));

const AccordinComponent = props => {
  const { data, name, icon, displayName, disabled, end, id, isActive } = props;

  const styles = useStyles();
  const links = data.filter(link => link?.parent === id);
  const activeParent = data.find(item => item?.id === isActive?.parent);
  const parentParent = data.find(item => item?.id === activeParent?.parent);
  // console.log({ isActive },'isActive')
  const active = isActive?.parent === id || id === parentParent?.id;
  return (
    <Accordion
      className={styles.accordionContainer}
      elevation={0}
      sx={{ backgroundColor: '#fff', border: 0 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          content: active ? styles.expandedAccordion : styles.accordionSummary,
          expanded: active ? styles.expandedAccordion : styles.accordionSummary,
        }}
        sx={{
          margin: 0,
        }}
        style={{
          justifyContent: 'space-between',
          backgroundColor: 'transparent',
        }}
      >
        <div
          className={clsx({
            [styles.icon]: true,
            [styles.diffIcon]: true,
          })}
        >
          {icon && <>{icon}</>}
        </div>
        <Typography sx={{ mr: 6, fontSize: 14, whiteSpace: 'nowrap' }}>
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        {links.map(x => (
          <NestedLink key={x.id} {...x} data={data} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const NestedLink = (props: Props) => {
  const {
    name,
    data,
    id,
    path,
    disabled,
    icon,
    target,
    parent,
    permissionName = '',
  } = props;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const links = data.filter(link => link.parent === id);
  const toggle = links.length > 0;
  const { t } = useTranslation();
  const styles = useStyles();
  const { pathname } = useLocation();
  const [pathSplit] = pathname.split('/').splice(-1);
  const [pathSplit1, pathSplit2] = pathname.split('/').splice(-2);
  const activeItem =
    data?.find(item => item?.path.includes(pathSplit1)) ||
    data.find(item => `${item.path}/${pathSplit}` === pathname) ||
    data.find(item => `${item.path}/${pathSplit1}/${pathSplit2}` === pathname);

  const [parentId, setParentId] = useState<number>(activeItem?.parent || 0);
  const parentItem = data.find(item => item?.parent === activeItem?.id);
  const isAccessible = (name: string): boolean => {
    if(user.role !== 'MERCHANT_USER') {
      return true;
    }
    if (user.role === 'MERCHANT_USER' && name == 'SETTINGS_SYSTEM') {
      return false
    }
    if (user.role === 'MERCHANT_USER' && name == 'SETTINGS_IMPORTS') {
      return false
    }
    if (user.role === 'MERCHANT_USER' && name == 'TV_USERS_SETTINGS') {
      return false
    }
    if (user.role === 'MERCHANT_USER' && name == 'SETTINGS_LOGS') {
      return false
    }
    const hasPermission = user.permissionGroups.filter(
      permission => permission.permissions[name] !== 'NO_ACCESS',
    );
    return permissionName === '' || hasPermission.length > 0;
  };
  const isActive = data?.find(item => item?.path === pathname) || {
      ...data?.find(item => `${item.path}/${pathSplit}` === pathname),
      path: pathname,
      id: activeItem?.id,
      parent: parentId || parentItem?.parent || activeItem?.patent,
    } || {
      ...data.find(
        item => `${item.path}/${pathSplit1}/${pathSplit2}` === pathname,
      ),
      path: pathname,
      id: activeItem?.id,
      parent: parentId || parentItem?.parent || activeItem?.patent,
    };

  const [isActiveItem, setIsActive] = useState(isActive);

  useEffect(() => {
    setIsActive(prev => data.find(item => item.path === pathname) ?? prev);
  }, [data, pathname]);

  // console.log({isActiveItem,activeItem,parentItem,pathname:pathname.includes(pathSplit1)},'parentItem')

  useEffect(() => {
    setParentId(parentItem?.parent);
  }, [parentItem?.parent]);
  // const patentId =
  const changeStates = () => {
    dispatch(actions.setSearch(''));
    // dispatch(
    //   tvPostActions?.setQuery({
    //     name: 'status',
    //     value: '',
    //     isFromNavigation: true,
    //   }),
    // );
    // dispatch(
    //   tvPostActions?.setQuery({
    //     name: 'isDemoContent',
    //     value: '',
    //     isFromNavigation: true,
    //   }),
    // );
    dispatch(
      productActions.setQuery({
        name: 'minPrice',
        value: '',
        isFromNavigation: true,
      }),
    );
    dispatch(
      productActions.setQuery({
        name: 'maxPrice',
        value: '',
        isFromNavigation: true,
      }),
    );
    dispatch(
      channelActions.setQuery({
        name: 'status',
        value: '',
        isFromNavigation: true,
      }),
    );
    dispatch(
      heroActions.setQuery({
        name: 'status',
        value: '',
        isFromNavigation: true,
      }),
    );
    dispatch(
      auditLogActions.setQuery({
        name: 'level',
        value: '',
        isFromNavigation: true,
      }),
    );
    dispatch(
      tagActions.setQuery({
        name: 'categoryId',
        value: 'all',
        isFromNavigation: true,
      }),
    );
  };

  return (
    <>
      {toggle ? (
        isAccessible(permissionName) && (
        <>
          {/* <NavLink to={path}> */}
          <AccordinComponent key={id} isActive={isActiveItem} {...props} />
          </>
        )
      ) : disabled ? (
        isAccessible(permissionName) && (
          <div
            className={clsx({
              [styles.root]: true,
            })}
          >
            <MenuItem
              disabled={disabled}
              className={clsx({
                [styles.wrapper]: true,
                [styles.isDifferent]: false,
              })}
            >
              <div
                className={clsx({
                  [styles.icon]: true,
                  [styles.diffIcon]: false,
                })}
              >
                {icon && <>{icon}</>}
              </div>
              <Typography sx={{ color: '#8F8E8E', fontSize: 14 }}>
                {t(`${name}`)}
              </Typography>
            </MenuItem>
          </div>
        )
      ) : (
        isAccessible(permissionName) && (
          <NavLink
            target={target}
            to={disabled ? '/tv-users' : path}
            className={prop => {
              // if (prop?.isActive) {
              //   dispatch(actions.setRoute(displayName));
              // }
              return clsx({
                [styles.root]: true,
                [styles.active]: prop.isActive,
              });
            }}
            onClick={changeStates}
          >
            <MenuItem
              className={clsx({
                [styles.wrapper]: true,
                [styles.isDifferent]: true,
                [styles.parent]: parent === 0,
              })}
            >
              <div
                className={clsx({
                  [styles.icon]: true,
                  [styles.diffIcon]: true,
                })}
              >
                {icon}
              </div>
              <Typography sx={{ fontSize: 14, color: '#8F8E8E' }}>
                {t(`${name}`)}
              </Typography>
            </MenuItem>
          </NavLink>
        )
      )}
    </>
  );
};

export default NestedLink;
