import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  cellRoot: {
    textAlign: 'center',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    background: "#FFF",
  },
}));
