import { selectToken } from 'app/containers/Auth/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_URL } from 'utils/constants';

import { Box, Button } from '@mui/material';

import excellImage from './assets/excellIcon.png';
import { useStyles } from './styles';

type Object = { [key: string]: string }

type Props = {
    type: 'devices' | 'products' | 'merchants' | 'posts' | 'users' | 'user-posts' | 'merchant-users',
    params?: Object,
    route?: string
}

const ExportExcellButton = (props: Props) => {
    const token = useSelector(selectToken)
    const urlRoute = props.route?props.route:'download';
    const styles = useStyles()
    const params = props.params || {}
    params['authToken'] =  token
    return (
        <a href={`${API_URL}/${props.type}/${urlRoute}?${new URLSearchParams(params).toString()}`} target='_blank' >
            <Box  className={styles.box}>
                <img src={excellImage} className={styles.image} />
                {/* Excell */}
            </Box>
        </a>
    )
}

export default ExportExcellButton