import {  Typography } from '@mui/material';
import React from 'react';
import { StyledTableCell, StyledTableRow } from 'app/components/Table';
import { makeStyles } from '@mui/styles';
type Props = {
  tags: Array<any>;
  columnValues: Array<any>;
};
const useStyles = makeStyles({
  tableRow: {
    border: '1px solid #00000010 !important',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
   tableCellWithLast: {
    paddingTop: 0,
  },
  tableCellWithLastBold: {
    paddingTop: 0,
    fontWeight: '700',
  }
});

const TagList = ({tags, columnValues}: Props) => {
  const styles = useStyles();
  return (
    <>
    {tags && tags.length > 0 ? (
      tags.map(tag => {
        return (
          <React.Fragment key={`${tag.id}`}>
            <StyledTableRow className={styles.tableRow} >
              <StyledTableCell className={styles.tableCell} >

              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >

              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >
                CTA
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >
                Wishlist
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >
                View
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >
                Sum
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow className={styles.tableRow} >
              <StyledTableCell className={styles.tableCell} >
                 {tag.name}
              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >
                All Stats
              </StyledTableCell>
              {(tag.allTagStats.length ?  tag.allTagStats : [{cta: 0, wishlist: 0 , view: 0, sum: 0}]).map((allStat, allStatIndex) => (
                <React.Fragment
                  key={`allStat-${tag.id}-${allStatIndex}`}
                >
                  <StyledTableCell className={styles.tableCell} >
                   {allStat.cta}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCell} >
                   {allStat.wishlist}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCell} >
                    {allStat.view}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCell} >
                    {allStat.sum}
                  </StyledTableCell>
                </React.Fragment>
              ))}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className={styles.tableCell} >

              </StyledTableCell>
              <StyledTableCell className={styles.tableCell} >
                User Stats
              </StyledTableCell>
              {(tag.userTagStats.length ?  tag.userTagStats : [{cta: 0, wishlist: 0 , view: 0, sum: 0}] ).map((allStat, allStatIndex) => (
                <React.Fragment
                  key={`userStat-${tag.id}-${allStatIndex}`}
                >
                  <StyledTableCell className={styles.tableCell} >
                    {allStat.cta}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCell} >
                     {allStat.wishlist}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCell} >
                     {allStat.view}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCell} >
                     {allStat.sum}
                  </StyledTableCell>
                </React.Fragment>
              ))}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className={styles.tableCell} >

              </StyledTableCell>
              <StyledTableCell className={styles.tableCellWithLastBold} >
                Sum
              </StyledTableCell>
                <React.Fragment
                  key={`userStat-${tag.id}-sum`}
                >
                  <StyledTableCell className={styles.tableCellWithLastBold} >
                    {Number(tag.allTagStats[0]?.cta || 0) +  Number(tag.userTagStats[0]?.cta || 0)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCellWithLastBold} >
                     {Number(tag.allTagStats[0]?.wishlist || 0) + Number(tag.userTagStats[0]?.wishlist || 0)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCellWithLastBold} >
                     {Number(tag.allTagStats[0]?.view || 0) +  Number(tag.userTagStats[0]?.view || 0)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.tableCellWithLastBold} >
                     {Number(tag.allTagStats[0]?.sum || 0 )+  Number(tag.userTagStats[0]?.sum || 0)}
                  </StyledTableCell>
                </React.Fragment>
            </StyledTableRow>
          </React.Fragment>
        );
      })
    ) : (
      <StyledTableRow  className={styles.tableRow}>
        <StyledTableCell
          className={styles.tableCellWithLast}
          colSpan={columnValues.length}
        >
          No Tags
        </StyledTableCell>
      </StyledTableRow>
    )}
    </>
  );
};

export default TagList;
