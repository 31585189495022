import { MerchantObject } from 'app/containers/Merchants/redux/types';
import {
    defaultLinks, defaultListFilterQuery, defaultListMeta, FormElement, ListFilterQuery, ListType
} from 'app/containers/types';

export type Currency = {
  label: string;
  value: string;
};
export type Currencies = Array<Currency>;
export const currencyOptions: Currencies = [];

export interface UploadFromUrlResponse {
  type: string;
  sourceUrl: string;
  fileType: string;
  id: string;
  fileSize: string;
  url: string;
  isVideo: boolean;
  isImage: boolean;
  thumbUrl: string;
}

export interface UploadStatusResponse {
  type: string;
  id: string;
  fileName: null | string;
  originalName: string;
  fileType: string;
  fileSize: null | string;
  thumb: string;
  file: string;
  sourceUrl: string;
  fileProcessed: string;
  url: string;
  playlist: string;
  isVideo: boolean;
  isImage: boolean;
  thumbUrl: string;
}

export interface ProductResponse {
  id: string;
  rating: string;
  name: string;
  merchantId: string;
  heroId: string;
  shortDescription: string;
  longDescription: string;
  status: boolean;
  promotionText: string;
  salePriceText: string;
  salePrice: string;
  salePriceCurrency: string;
  priceText: string;
  price: string;
  priceCurrency: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  image: Image;
  merchant: Merchant;
  hero: Hero;
  productNotes: string;
  sku: string;
}

export interface Image {
  type: string;
  id: string;
  fileName: string;
  fileType: string;
  thumb: string;
  file: string;
  url: string;
}

export interface Merchant {
  id: string;
  productCount: string;
  userId: string;
  businessName: string;
  description: string;
  businessType: string;
  createdAt: string;
  updatedAt: string;
}

export interface Hero {
  id: string;
  name: string;
  productCount: string;
  description: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Product {
  id: FormElement;
  rating: FormElement;
  promotionText: FormElement;
  name: FormElement;
  shortDescription: FormElement;
  longDescription: FormElement;
  productImageUrl: FormElement;
  starRating: FormElement;
  merchantId: FormElement;
  heroId: FormElement;
  salePrice: FormElement;
  salePriceCurrency: Currency & FormElement;
  price: FormElement;
  priceCurrency: Currency & FormElement;
  assetUrl: FormElement;
  salePriceText: FormElement;
  priceText: FormElement;
  status: FormElement;
  updatedAt: FormElement;
  createdAt: FormElement;
  merchant: Merchant;
  hero: Hero;
  sku: FormElement;
  productNotes: FormElement;
  productImage: {
    type: string;
    id: number;
    fileName: string;
    fileType: string;
    thumb: string;
    file: any;
    url: string;
    error?: any;
    value: string;
  };
}

export interface SetVideoForm {
  id: FormElement;
  name: FormElement;
  assetUrl: FormElement;
  applicableTvPost: FormElement;
  status: FormElement;
  eligibleProductDetails: FormElement;
  video: Video;
  index: FormElement;
  isUploading: FormElement;
  checkStatusTime: FormElement;
}
export type ProductFormModeal =
  | undefined
  | 'PRODUCT_VIDEO'
  | 'PRODUCT_TAG'
  | 'PRODUCT_TEXT';
export interface ProductForm {
  model: ProductFormModeal;
  setVideo: SetVideoForm;
  setProduct: Product;
  videos: ProductVideosResponse;
  tags: ProductTagsResponse;
  channels: ProductChannelsResponse;
  setSrt: SetSRT;
  text: TextData;
}
export interface SrtItem {
  id?: string;
  language: string;
  asset: SubtitleAsset;
  createdAt: string;
  updatedAt: string;
  isDeleted?: boolean;
}

export interface SetSRT {
  id: FormElement;
  open: boolean;
  list: ListType<SrtItem>;
  file: FormElement;
  language: FormElement;
  forUpload: File | any;
  editIndex?: number;
}
export const defaultSrtListFilters: SrtListFilters = {
  limit: 15,
  orderDirection: '',
  orderField: '',
  q: '',
  page: 1,
};
export const defaultSetSrt: SetSRT = {
  open: false,
  list: {
    items: [],
    links: defaultLinks,
    meta: defaultListMeta,
    query: defaultSrtListFilters,
  },
  language: { value: '' },
  file: { value: '' },
  forUpload: {},
  id: {value: undefined}
};

export interface SrtListFilters extends ListFilterQuery { }

export const defaultSrtList: ListType<SrtItem> = {
  items: [],
  links: defaultLinks,
  meta: defaultListMeta,
  query: defaultSrtListFilters,
};

export interface ProductState {
  loading: boolean;
  isSingedUrlGenerating: boolean;
  currencyOptions: Currencies;
  error: string | null;
  errorTabsIndex?: number[];
  list: {
    items: Array<ProductResponse>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  form: ProductForm;
  forUpload: Video;
  query: {
    limit: number;
    page: number;
    q: string;
    orderField: string;
    orderDirection: string;
    status: string;
    minPrice: string;
    maxPrice: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
}

export interface ProductVideosResponse {
  items: ProductVideo[];
  meta: Meta;
  links: Links;
}

export interface ProductVideo {
  id: string;
  order: number;
  productId?: string;
  eligibleProductDetails: boolean;
  applicableTvPost: boolean;
  status: boolean;
  createdAt?: string;
  updatedAt?: string;
  video: Video;
  subtitles: SrtItem[];
}

export interface SubtitleAsset {
  type: string;
  id: string;
  fileSize: any;
  fileName: string;
  fileType: string;
  file: any;
  url: string;
}

export interface Video {
  type: string;
  id: string;
  fileSize: any;
  fileName: string;
  fileType: string;
  thumb: string;
  file: any;
  url: string;
  playlist: string;
  thumbUrl: string;
  isRemote?: boolean;
  sourceUrl?: boolean;
}

export interface Meta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface Links {
  first: string;
  previous: string;
  next: string;
  last: string;
}

export interface ProductTagsResponse {
  items: ProductTag[];
  meta: Meta;
  links: Links;
}

export interface ProductTag {
  id: string;
  productId: string;
  tagId: string;
  createdAt: string;
  tag: Tag;
  isDeleted?: boolean;
}

export interface Tag {
  id: string;
  name: string;
  categoryId: string;
  createdAt: string;
  category: Category;
  updatedAt: string;
  deletedAt: any;
}

export interface Category {
  id: string;
  name: string;
  categoryId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface ProductChannelsResponse {
  items: ProductChannel[];
  meta: Meta;
  links: Links;
}

export interface ProductChannel {
  id: string;
  createdAt: string;
  channel: Channel;
  isDeleted?: boolean;
}

export interface Channel {
  id: string;
  name: string;
  description: string;
  status: boolean;
  productCount: string;
  createdAt: string;
  updatedAt: string;
}

export interface Tag {
  id: string;
  name: string;
  categoryId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  category: Category;
}

export interface Category {
  id: string;
  name: string;
  deletedAt: any;
  createdAt: string;
  updatedAt: string;
}

export type TextData = {
  id: FormElement;
  productId: FormElement;
  label: FormElement;
  shortText: FormElement;
  longText: FormElement;
  createdAt: FormElement;
};

export type TextListItem = {
  label: string | null;
  shortText: string | null;
  longText: string | null;
  createdAt: string | null;
};

export type TextDataList = ListType<TextListItem>;
export const defaultTextDataList: TextDataList = {
  items: [],
  meta: defaultListMeta,
  links: defaultLinks,
  query: defaultListFilterQuery,
};
