import { Tag } from '../Tags/types';
import { FormElement } from '../types';

export interface Category {
  id: any;
  name: string;
  ecommerceId: null | string;
  createdAt: Date;
  updatedAt: null | Date;
  tags?: Array<Tag>;
}

export interface CategoryForm {
  id: FormElement;
  name: FormElement;
  ecommerceId: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
}

export type TagForm = {
  id: FormElement;
  name: FormElement;
  categoryId: FormElement;
  status: FormElement;
};

export const defaultTagForm: TagForm = {
  id: { value: '' },
  name: { value: '' },
  categoryId: { value: '' },
  status: { value: '' },
};

export type CategoryPageForm = {
  createCategory: CategoryForm;
  createTag: TagForm;
  tag: Tag;
};
export const defaultTag: Tag = {
  categoryId: '',
  id: '',
  name: '',
  status: false,
};

export interface CategoryState {
  loading: boolean;
  error: string;
  openTagForm: boolean;
  form: CategoryPageForm;
  categoryList: {
    items: Array<Category>;
    meta: {
      currentPage: number;
      itemCount: number;
      itemsPerPage: number;
      totalItems: number;
      totalPages: number;
    };
    links: {
      first: string;
      last: string;
      next: string;
      previous: string;
    };
  };
  query: {
    limit: number;
    page: number;
    q: string;
    orderField: string;
    orderDirection: string;
    status: string;
  };
}
