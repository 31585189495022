import IOSSwitch from 'app/containers/Dashboard/components/IOSSwitch';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box, Button, Container, FormControlLabel, Grid, Switch, Tooltip, Typography
} from '@mui/material';

import { selectFeedConfigForm } from '../../redux/selector';
import { actions } from '../../redux/slice';
import FeedConfigurationField from '../FeedConfigurationField';
import SectionHeading from '../SectionHeading';
import { useStyles } from './styles';
import { validate } from './validator';

type Props = {};

const Form = (props: Props) => {
  const styles = useStyles();
  const form = useSelector(selectFeedConfigForm);
  const dispatch = useDispatch();

  const handleChange = e => {
    const { name, value } = e.target;
    if (value && !(Number(value) > -1)) {
      return;
    }
    dispatch(
      actions.setForm({
        name,
        value,
      }),
    );
    dispatch(
      actions.setSettings({
        name,
        value,
      }),
    );
  };

  // const getFieldItem = (key: string, name: string): ISettingOptionItem => {
  //   const item = getSettingValue(items, name, key);
  //   return item;
  // };

  const handleSubmit = e => {
    e?.preventDefault();
    const errors = validate(form);
    if (errors.length) {
      dispatch(actions.setFormErrors({ key: '', errors }));
      return;
    }
    dispatch(actions.updateConfiguration(''));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        maxWidth={false}
        sx={{ display: 'flex', flexDirection: 'column', pb: 1, px: 0 }}
        style={{ padding: '8px' }}
      >
        <Box className={styles.headerRoot}>
          <Box>
            <Typography>Feed Configuration Details</Typography>
            <FormControlLabel
              sx={{ ml: 0 }}
              label={`Feed Rules ${
                form.FEED_ENABLED.value === `1` ? 'Enabled' : 'Disabled'
              } `}
              control={
                <Tooltip
                  title={`Click to ${
                    form.FEED_ENABLED.value === `1` ? 'Disable' : 'Enable'
                  }`}
                >
                  <Box sx={{ ml: 1 }}>
                    <IOSSwitch
                      name="FEED_ENABLED"
                      checked={form.FEED_ENABLED.value == '1'}
                      value={form.FEED_ENABLED.value == '1' ? '0' : '1'}
                      onChange={handleChange}
                    />
                  </Box>
                </Tooltip>
              }
              labelPlacement="start"
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            {form.FEED_ENABLED.value == '0' && (
              <FormControlLabel
                sx={{ ml: 0 }}
                label={`Random Feed`}
                control={
                  <Tooltip
                    title={`Click to ${
                      form.FEED_RANDOMIZE.value === `1` ? 'Disable' : 'Enable'
                    }`}
                  >
                    <Box sx={{ ml: 1 }}>
                      <IOSSwitch
                        name="FEED_RANDOMIZE"
                        checked={form.FEED_RANDOMIZE.value == '1'}
                        value={form.FEED_RANDOMIZE.value == '1' ? '0' : '1'}
                        onChange={handleChange}
                      />
                    </Box>
                  </Tooltip>
                }
                labelPlacement="start"
              />
            )}
          </Box>

          {/* <Select
            placeholder="Filter by Configuration"
            width={193}
            noDefaults
            labelClass={styles.selectLabel}
            menuItems={[<MenuItem value="">All</MenuItem>]}
          /> */}
        </Box>

        <Grid container>
          <Grid item xs={12} md={6}>
            <SectionHeading style={{ marginTop: 20 }} label="Feed Settings" />
            <FeedConfigurationField
              name="FEED_SIZE"
              description={'how many TVPosts are in the feed'}
              label="Feed size"
              value={form.FEED_SIZE.value}
              error={form.FEED_SIZE.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="FEED_PAGING_SIZE"
              description={'how many tvpost a user client fetch per call'}
              label="Feed paging size"
              value={form.FEED_PAGING_SIZE.value}
              error={form.FEED_PAGING_SIZE.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="FEED_TTL"
              description={'time to live'}
              label="Feed ttl"
              value={form.FEED_TTL.value}
              error={form.FEED_TTL.error}
              onChange={handleChange}
              fieldText="minutes"
            />
            <SectionHeading style={{ marginTop: 30 }} label="Post Settings" />

            <FeedConfigurationField
              name="PCT_NEW"
              description={`how many posts in the feed will be NEW content in the system`}
              label="Pct_new"
              value={form.PCT_NEW.value}
              error={form.PCT_NEW.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="PCT_ENGAGED"
              description={
                <>
                  how many posts in the feed are similar to what the
                  <br />
                  user engaged with
                </>
              }
              label="Pct_engaged"
              value={form.PCT_ENGAGED.value}
              onChange={handleChange}
              error={form.PCT_ENGAGED.error}
            />
            <FeedConfigurationField
              name="VIDEO_WATCHED_TO_IMPLY_ENGAGED"
              description={
                <>
                  how many Seconds video watched to
                  <br />
                  consider an engagement
                </>
              }
              label="Number of Seconds Video Watched"
              value={form.VIDEO_WATCHED_TO_IMPLY_ENGAGED.value}
              error={form.VIDEO_WATCHED_TO_IMPLY_ENGAGED.error}
              onChange={handleChange}
              fieldText="seconds"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionHeading
              style={{ marginTop: 20 }}
              label="Frequency Settings"
            />

            <FeedConfigurationField
              name="TIME_TO_WATCH_TO_IMPLY_LIKE"
              description={
                <>
                  how long the user watched a TV Post
                  <br />
                  can be considered user liked the video
                </>
              }
              label="TimeWatchToImplyLike"
              value={form.TIME_TO_WATCH_TO_IMPLY_LIKE.value}
              error={form.TIME_TO_WATCH_TO_IMPLY_LIKE.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="USER_ADDED_FREQUENCY_BY_CTA"
              description={
                <>
                  how much to increase
                  <br />
                  Frequency because of CTA
                </>
              }
              label="User_Added_Frequency_by_cta"
              value={form.USER_ADDED_FREQUENCY_BY_CTA.value}
              error={form.USER_ADDED_FREQUENCY_BY_CTA.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="USER_ADDED_FREQUENCY_BY_WISHLIST"
              description={
                <>
                  how much to increase Frequency
                  <br />
                  because of post added to wish list
                </>
              }
              label="User_Added_Frequency_by_wishlist"
              value={form.USER_ADDED_FREQUENCY_BY_WISHLIST.value}
              error={form.USER_ADDED_FREQUENCY_BY_WISHLIST.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="USER_ADDED_FREQUENCY_BY_VIEW"
              description={
                <>
                  how much to increase
                  <br />
                  Frequency because of long view
                </>
              }
              label="User_Added_Frequency_by_view"
              value={form.USER_ADDED_FREQUENCY_BY_VIEW.value}
              error={form.USER_ADDED_FREQUENCY_BY_VIEW.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="ALL_ADDED_FREQUENCY_BY_CTA"
              description={
                <>
                  how much to increase Frequency
                  <br />
                  because of CTA
                </>
              }
              label="All_Added_Frequency_by_cta"
              value={form.ALL_ADDED_FREQUENCY_BY_CTA.value}
              error={form.ALL_ADDED_FREQUENCY_BY_CTA.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="ALL_ADDED_FREQUENCY_BY_WISHLIST"
              description={
                <>
                  how much to increase Frequency
                  <br />
                  because of post added to wish list
                </>
              }
              label="All_Added_Frequency_by_wishlist"
              value={form.ALL_ADDED_FREQUENCY_BY_WISHLIST.value}
              error={form.ALL_ADDED_FREQUENCY_BY_WISHLIST.error}
              onChange={handleChange}
            />
            <FeedConfigurationField
              name="ALL_ADDED_FREQUENCY_BY_VIEW"
              description={
                <>
                  how much to increase Frequency
                  <br />
                  because of long view
                </>
              }
              label="All_Added_Frequency_by_view"
              value={form.ALL_ADDED_FREQUENCY_BY_VIEW.value}
              error={form.ALL_ADDED_FREQUENCY_BY_VIEW.error}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right', pr: 3 }}>
            <Button variant="outlined" type="submit" sx={{ color: '#333' }}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default Form;
