import React from 'react';
import Chart from 'react-apexcharts';

import { Box, Typography } from '@mui/material';

import { MAX_HEIGHT } from '../constants';

const AreaChart = ({
    colors,
    series,
    usersCount,
    categories,
    hr,
    totalNumberOfUsers
}) => {
    const chartOptions = {
        series: [
            {
                data: series,
            },
        ],
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    },
                },
            },
            ...(colors && {
                colors,
            }),
            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 4,
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                categories,
                labels: {
                    style: {
                        color: '#999999',
                        fontSize: '12px',
                    },
                },
            },
            grid: {
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            yaxis: {
                axisTicks: {
                    show: true,
                },
                labels: {
                    style: {
                        color: '#999999',
                        fontSize: '12px',
                    },
                },
            },
            noData: {
                text: "No data found",
                align: "center",
                verticalAlign: "middle",
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class="box" style="width:180px; height: 40px; text-align: center;">'
                        + '<h4 style="color:#4B4088;">' + usersCount[dataPointIndex] + " users" + '</h4>' + '</div>'
                        + '<div class="box" style="width:180px; height: 60px; text-align: center;">'
                        + "Average Time: " + series[seriesIndex][dataPointIndex] + " min " + '</br>' + categories[dataPointIndex] + '</div>'

                    );
                }
            }
        },
    };
    return (
        <div style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: '0px 4px 29px rgba(28, 31, 61, 0.1)' }} >
            <h4 style={{ paddingTop: 15, paddingLeft: 15, fontSize: 17, color: '#4B4088' }}>User's Dashboard</h4>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'}}>
            <Box display={'flex'} alignItems="center">
            <Typography style={{paddingLeft: 15, marginTop: -20, fontSize: 17, fontWeight:500 }}>
                Average Time:
            </Typography>
            <Typography style={{ paddingLeft: 15, marginTop: -20, fontSize: 17, color: '#4B4088', fontWeight: 700 }}> {hr} </Typography>
            </Box>
            <Box display={'flex'} alignItems="center" sx={{marginRight: '15px'}}>
                <Typography style={{ marginRight: 15, marginTop: -20, fontSize: 17, fontWeight: 500 }}>
                    Number of Registered Users:
                </Typography>
                <Typography style={{ marginRight: 15, marginTop: -20, fontSize: 17, color: '#4B4088', fontWeight: 700 }}> {totalNumberOfUsers} </Typography>
                </Box>
            </Box>
            <Chart
                options={chartOptions.options}
                series={chartOptions.series}
                type="area"
                height={MAX_HEIGHT}
            />
        </div>
    );
};

export default AreaChart;
