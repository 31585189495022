import { IListType } from 'app/containers/types';
import set from 'lodash/set';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from '../types';

export const userSlice = createSlice({
  name: 'userDevices',
  initialState,
  reducers: {
    getList: state => {
      state.loading = true;
    },
    getListSuccess: (state, action: PayloadAction<IListType>) => {
      state.loading = false;
      state.list = action.payload;
    },
    getListFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    handleBlock: (state, action) => {
      state.loading = true;
    },
    handleBlockSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const index = state.list.items.findIndex(u => u.id == action.payload.id);
      if (index > -1) {
        state.list.items[index].isBlocked = action.payload.isBlocked;
        state.list.items[index].updatedAt = action.payload.updatedAt;
      }
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      // console.log(name, value, 'name, value')
      state.query.page = 1;
      set(state.query, name, value);
    },
    getNextPage: (state, action) => {
      state.loading = true;
    },
    getNextPageSuccess: (state, action: PayloadAction<IListType>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.links = action.payload.links;
    },
    getNextPageFailed: (state, action) => {
      state.loading = false;
    },
    handleDelete: (state, action) => {
      state.loading = false;
    },
    handleDeleteSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.filter(x => x.id !== action.payload);
    },
    handleDeleteFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const { actions, name: sliceKey, reducer } = userSlice;
