import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { BaseTextFieldProps, TextField, Typography } from '@mui/material';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { makeStyles } from '@mui/styles';

type Props = {
  onChange: any;
  isEdit?: boolean | undefined;
  defaultValue?: string | null;
  label: string | ReactNode;
  placeholder?: string;
  fullWidth: boolean;
  size?: 'small' | 'medium' | undefined;
  lightPlaceholder?: boolean;
  isDisable?: boolean;
  mt?: string;
  mb?: string;
  minWidth?: string;
  backgroundColor?: string;
  value?: any;
  name?: any;
  editable?: any;
  inputProps?: any;
  onBlur?: any;
  error?: any;
  mainStyle?: any;
  type?: string;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  labelFontWeight?: number | string;
  maxLength?: number;
  maxRows?: number;
  className?: any;
  labelStyleProps?: TypographyStyleOptions;
  height?: string;
  isRequired?:boolean;
};

const useStyles = makeStyles(() => ({
  inputRoot: {
    position: 'relative',
  },
  labelWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputField: {
    maxHeight: '78px',
  },
  charCount: {
    position: 'absolute',
    right: 5,
    top: 0
  },
  linesCount: {
    position: 'absolute',
    top: 0
  },
}));

const InputField = (props: Props) => {
  const labelFontWeight = props.labelFontWeight ? props.labelFontWeight : 500;
  const styles = useStyles();
  const rows = (String(props?.value) || "").split('\n')
  return (
    <div className={styles.inputRoot} style={props.mainStyle}>
      <div className={styles.labelWrap} style={props.mainStyle}>
        {props.label && (
          <Typography
            sx={{
              mt: props.mt ?? '10px',
              mb: props.mb ?? '8px',
              fontWeight: labelFontWeight,
              ...props.labelStyleProps
            }}
          >
            {props.label}{props.isRequired?"*":""}
          </Typography>
        )}
        {props.maxLength && (
          <Typography
            sx={{
              mt: props.mt ?? '10px',
              mb: props.mb ?? '8px',
              fontSize: '14px',
            }}
            className={styles.charCount}
          >
            chars count : {props?.value.length || 0}/{props.maxLength}
          </Typography>
        )}

        {props.maxRows && (
          <Typography
            sx={{
              mt: props.mt ?? '10px',
              mb: props.mb ?? '8px',
              fontSize: '14px',
              right: !props.maxLength ? 0 : 150
            }}
            className={styles.linesCount}
          >
            lines count : {rows?.length || 0}/{props.maxRows}
          </Typography>
        )}

      </div>
      <TextField
        type={props.type}
        disabled={props.isDisable}
        inputProps={{ maxLength: props.maxLength, ...props.inputProps }}
        size={props.size}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={!!props.error}
        helperText={props.error}
        multiline={props.multiline}
        defaultValue={props.defaultValue}
        rows={props.rows}
        sx={{
          input: {
            '&::placeholder': {
              color: props.lightPlaceholder ? '#CCCDCF' : '#CCCDCF',
              opacity: '100%',
              fontWeight: '400',
            },
            backgroundColor: props.backgroundColor ?? '#ffffff',
            height: props.height
          },
          ...props.sx,
        }}
        className={clsx(styles.inputField, props.className)}
        // defaultValue={props.isEdit ? props.defaultValue : null}
        placeholder={props.placeholder}
        value={props.value}
        name={props.name}
      />

      {/* {!!props.error && <Typography sx={{ marginTop: 0, color: '#ff0000' }}>{props.error}</Typography>} */}
    </div>
  );
};
export default InputField;
