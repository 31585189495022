import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './types';

const selectDomain = (state: RootState) =>
  state.feedConfiguration || initialState;

export const selectFeedConfigForm = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectFeedSettingList = createSelector(
  [selectDomain],
  state => state.list.items.filter( f =>  ['FEED', 'FREQUENCY', 'POST'].includes(f.category)),
);

export const selectSystemSettingList = createSelector(
  [selectDomain],
  state => state.list.items,
);
