import set from 'lodash/set';
import { useId } from 'react';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { FormError } from '../types';
import { ChannelListItem, ChannelState, CreateChannel } from './types';

// The initial state of the GithubRepoForm container
export const initialState: ChannelState = {
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalPages: 0,
      currentPage: 1,
    },
    links: {
      first: '',
      last: '',
      next: '',
      previous: '',
    },
  },
  form: {
    createChannel: {
      id: Math.floor(Math.random() * 90000) + 10000,
      channelName: {
        value: '',
      },
      viewOrder: {
        value: '',
      },
      description: {
        value: '',
      },
      status: {
        value: false,
      },
      logoUrl: {
        value: '',
      },
      image: {
        file: '',
        fileName: '',
        fileType: '',
        thumb: '',
        fileSize: '',
      },
    },
  },
  error: '',
  loading: false,
  query: {
    page: 1,
    limit: 10,
    orderDirection: '',
    orderField: '',
    q: '',
    status: '',
  },
};

const channelSlice = createSlice({
  name: 'channelState',
  initialState,
  extraReducers: () => {},
  reducers: {
    setForm: (
      state,
      action: PayloadAction<{
        formName: string;
        name: string;
        value: any;
      }>,
    ) => {
      const { formName, name, value } = action.payload;
      set(state.form[formName], `${name}.value`, value);
      set(state.form[formName], `${name}.error`, '');
    },
    deleteChannelImage: state => {
      state.form.createChannel.image = initialState.form.createChannel.image;
    },
    createChannel: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    createChannelSuccess: (state, action: PayloadAction<ChannelListItem>) => {
      state.loading = false;
      state.error = '';
      if (
        !state.query.status ||
        state.query.status === action.payload.status.toString().toUpperCase()
      ) {
        state.list.items = [action.payload, ...state.list.items];
      }
      const resetForm: CreateChannel = {
        id: null,
        channelName: {
          value: '',
        },
        viewOrder: {
          value: '',
        },
        logoUrl: {
          value: '',
        },
        status: {
          value: false,
        },
        description: {
          value: '',
        },
        image: {
          file: '',
          fileName: '',
          fileType: '',
          thumb: '',
          fileSize: '',
        },
      };
      state.form.createChannel = resetForm;
    },
    clearErrorMessage: state => {
      state.error = '';
    },
    createChannelFailure: (state, action) => {
      state.loading = false;
      if (action.payload === 'CHANNELS.ERRORS.MAX_ACTIVE_REACHED') {
        state.error = 'You should deactivate 1 channel first.';
      } else {
        state.error = action.payload;
      }
    },
    clearLogo: state => {
      state.form.createChannel.logoUrl.value = '';
    },
    setEditChannel: (state, action: PayloadAction<ChannelListItem>) => {
      const payload = action.payload;
      const { channelName, description, status, logoUrl, viewOrder } =
        state.form.createChannel;
      channelName.value = payload.name;
      viewOrder.value = payload.viewOrder;
      description.value = payload.description;
      status.value = payload.status;
      logoUrl.value = payload.image?.url;
      state.form.createChannel.id = payload.id;
      if (payload.image) {
        state.form.createChannel.image = payload.image;
      }
    },
    updateEditChannel: (
      state,
      action: PayloadAction<{ channel: ChannelListItem; index: number }>,
    ) => {
      const { channel, index } = action.payload;
      // console.log(channel);
      state.list.items[index] = channel;
      const resetForm: CreateChannel = {
        id: Math.floor(Math.random() * 90000) + 10000,
        channelName: {
          value: '',
        },
        viewOrder: {
          value: '',
        },
        logoUrl: {
          value: '',
        },
        status: {
          value: false,
        },
        description: {
          value: '',
        },
        image: {
          file: '',
          fileName: '',
          fileType: '',
          thumb: '',
          fileSize: '',
        },
      };
      state.form.createChannel = resetForm;
    },
    filterStatus: (state = initialState, action) => {},
    deleteChannel: (state, action: PayloadAction<ChannelListItem>) => {
      state.loading = false;
      state.list.items = state.list.items.filter(x => x.id !== action.payload);
      state.form.createChannel = initialState.form.createChannel;
    },
    setFileDetails: (
      state,
      action: PayloadAction<{ name: string; type: string; file: any }>,
    ) => {
      state.form.createChannel.image.fileName = action.payload.name;
      state.form.createChannel.image.fileType = action.payload.type;
      state.form.createChannel.image.file = action.payload.file;
    },
    getChannelsList: state => {
      state.loading = true;
      state.error = '';
    },
    getChannelListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = '';
      state.list = action.payload;
    },
    getChannelListFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.list = initialState.list;
        state.query.page = 1;
      }
      set(state.query, name, value);
    },
    getNextPageChannels: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    resetChannelForm: state => {
      state.loading = false;
      state.form = initialState.form;
      state.error = '';
    },
    getNextPageChannelsFailure: state => {
      state.loading = false;
    },
    getNextPageChannelsSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    updateChannelRequest: (state, action) => {
      state.loading = true;
    },
    updateChannelSuccess: (state, action) => {
      state.loading = false;
      const index = state.list.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (index >= 0) {
        state.list.items[index] = action.payload;
      }
    },
    deleteChannelRequest: (state, action) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[] | FormError;
      }>,
    ) {
      if (Array.isArray(action.payload.errors)) {
        action.payload.errors.forEach(error => {
          set(
            state.form,
            `${action.payload.key}.${error.name}.error`,
            error.error,
          );
        });
      } else {
        set(
          state.form,
          `${action.payload.key}.error`,
          action.payload.errors.error,
        );
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = channelSlice;
