export interface FormElement<T = any> {
  value: T;
  error?: string;
}

export interface IBaseListFilter {
  limit: number;
  page: number;
  q: string | null;
}

export interface IListMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}
export interface IListLink{
  first: string;
  last: string;
  next: string;
  previous: string;
}

export interface IListType {
  items: Array<any>;
  meta: IListMeta;
  links: IListLink;
}

export interface RootQuery {
  limit: number;
  orderField: string | null;
  orderDirection: string | null;
}

export interface FormError {
  name: string;
  error: string;
}

export interface SignedURLResponse {
  key: string;
  previewUrl: string;
  url: string;
}


export interface ListType<T> {
  items: Array<T>;
  links: ListLinkType;
  meta: ListMetaType;
  query?: ListFilterQuery | any;
}

export const defaultListFilterQuery: ListFilterQuery = {
  limit: 10,
  orderDirection: '',
  orderField: '',
  q: '',
  page: null,
};

export interface ListFilterQuery {
  limit: number;
  q: string;
  page?: null | number | string;
  orderField: StringField;
  orderDirection: StringField;
}

export type StringField = null | string;

export interface ListMetaType {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export const defaultLinks: ListLinkType = {
  first: '',
  last: '',
  next: '',
  previous: '',
};

export const defaultListMeta: ListMetaType = {
  totalItems: 0,
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
};

export interface ListLinkType {
  next: string;
  previous: string;
  first: string;
  last: string;
}