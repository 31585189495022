import { FormError } from '../types';
import { CategoryForm } from './types';

export const validate = (form: CategoryForm): FormError[] => {
  let errors: FormError[] = [];
  // if(!form.)
  if (!form.name.value) {
    errors.push({
      name: 'name',
      error: "Category name can't be empty",
    });
  }

  if (form.name.value.length > 32 ) {
    errors.push({
      name: 'name',
      error: "Category name can't be longer than 32 characters",
    });
  }
  return errors;
};
