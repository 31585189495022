import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.heroState || initialState;

export const selectForm = createSelector(
  [selectDomain],
  heroState => heroState.form,
);
export const selectHerosList = createSelector(
  [selectDomain],
  heroState => heroState.heroList,
);

export const selectHerosQuery = createSelector(
  [selectDomain],
  state => state.query,
);

export const selectHerosLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectHerosError = createSelector([selectDomain], state => state.error);
