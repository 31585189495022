import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { FormError } from '../types';
import { HeroListItem, HeroState } from './types';

// The initial state of the GithubRepoForm container
export const initialState: HeroState = {
  heroList: {
    items: [],
    links: {
      first: '',
      last: '',
      next: '',
      previous: '',
    },
    meta: {
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      itemCount: 0,
    },
  },
  form: {
    createHero: {
      id: {
        value: new Date().toISOString(),
      },
      heroName: {
        value: '',
      },
      status: {
        value: true,
      },
      logoUrl: {
        value: '',
      },
      viewOrder: {
        value: '',
      },
      image: {
        type: '',
        fileName: '',
        fileType: '',
        thumb: '',
        url: '',
        file: '',
      },
    },
  },
  query: {
    page: 1,
    limit: 10,
    q: '',
    orderField: '',
    orderDirection: '',
    status: '',
  },
  error: '',
  loading: false,
};

const heroSlice = createSlice({
  name: 'heroState',
  initialState,
  extraReducers: () => {},
  reducers: {
    create: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    createHeroSuccess: (state, action: PayloadAction<HeroListItem>) => {
      state.loading = false;
      state.error = '';
      if (
        !state.query.status ||
        state.query.status === action.payload.status.toString().toUpperCase()
      ) {
        state.heroList.items = [action.payload, ...state.heroList.items];
      }
      state.form.createHero = {
        ...initialState.form.createHero,
        id: {
          value: new Date().toISOString(),
        },
      };
    },
    resetForm: state => {
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>,
    ) {
      action.payload.errors.forEach(error => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error,
        );
      });
    },
    clearErrorMessage: state => {
      state.error = '';
    },
    createHeroFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      if (action.payload === 'CHANNELS.ERRORS.MAX_ACTIVE_REACHED') {
        state.error = 'You should deactivate 1 hero first.';
      } else {
        state.error = action.payload;
      }
    },
    update: (state, action) => {
      state.loading = true;
    },
    updateSuccess: (state, action) => {
      state.loading = false;
      const { id } = action.payload;
      const index = state.heroList.items.findIndex(x => x.id === id);
      if (index >= 0) {
        state.heroList.items[index] = action.payload;
      }
      state.form.createHero = {
        ...initialState.form.createHero,
        id: {
          value: new Date().toISOString(),
        },
      };
    },
    delete: (state, action) => {
      state.loading = true;
    },
    deleteSuccess: (state, action) => {
      state.loading = false;
      state.heroList.items = state.heroList.items.filter(
        x => x.id !== action.payload,
      );
    },
    getList: state => {
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.heroList = action.payload;
    },
    getListFailed: (state, payload) => {
      state.loading = false;
    },
    getNextPage: (state, action) => {
      // state.loading = true;
    },
    getNextPageSuccess: (state, action) => {
      state.loading = false;
      state.heroList.items = state.heroList.items.concat(action.payload.items);
      state.heroList.meta.currentPage = action.payload.meta.currentPage;
      state.heroList.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setEdithero: (state, action: PayloadAction<HeroListItem>) => {
      state.form.createHero.id.value = action.payload.id;
      state.form.createHero.viewOrder.value = action.payload.viewOrder;
      state.form.createHero.heroName.value = action.payload.name;
      state.form.createHero.status.value = action.payload.status;
      state.form.createHero.logoUrl.value = action.payload.image?.url;
      state.form.createHero.image = action.payload?.image
        ? action.payload?.image
        : {
            file: '',
            fileName: '',
            thumb: '',
            fileType: '',
            url: '',
            type: '',
          };
    },
    setEditHeroEmpty: state => {
      state.form.createHero.heroName.value = '';
      state.form.createHero.logoUrl.value = '';
      state.form.createHero.status.value = false;
    },
    clearLogo: state => {
      state.form.createHero.logoUrl.value = '';
      state.form.createHero.image.url = '';
    },
    deleteHero: (state, action: PayloadAction<string>) => {
      state.heroList.items = state.heroList.items.filter(
        x => x.id !== action.payload,
      );
    },
    filterStatus: (state = initialState, action) => {},
    setForm: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, '');
    },
    updateHero: (state, action: PayloadAction<HeroListItem>) => {
      const index = state.heroList.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (index >= 0) {
        state.heroList.items[index] = action.payload;
      }
      state.form.createHero = {
        ...initialState.form.createHero,
        id: {
          value: new Date().toISOString(),
        },
      };
    },
    setDefaultPage: state => {
      state.query.page = 1;
    },
    setQuery: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        isFromNavigation?: boolean;
      }>,
    ) => {
      const { name, value, isFromNavigation = false } = action.payload;
      if (!isFromNavigation) {
        state.heroList.items = [];
        state.query.page = 1;
      }
      set(state.query, name, value);
    },
    setFileDetails: (
      state,
      action: PayloadAction<{ name: string; type: string; file: any }>,
    ) => {
      state.form.createHero.image.fileName = action.payload.name;
      state.form.createHero.image.fileType = action.payload.type;
      state.form.createHero.image.file = action.payload.file;
    },
  },
});

export const { actions, reducer, name: sliceKey } = heroSlice;
