import DataTable from 'app/components/DataTable';
import DropDownComponent from 'app/components/DropDownComponent';
import TableLoader from 'app/components/TableLoader';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFormattedDateTime } from 'utils/helpers';

import { Box, MenuItem } from '@mui/material';

import { actions as dashboardActions } from '../../../../../Dashboard/slice';
import { selectImpressionsList, selectImpressionsQuery, selectLoading } from '../../redux/selector';
import { actions } from '../../redux/slice';
import { useStyles } from './styles';

type Props = {};

const Impressions = (props: Props) => {
  const { id, postid } = useParams();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { total, items } = useSelector(selectImpressionsList);
  const { userId } = useSelector(selectImpressionsQuery);
  const loading = useSelector(selectLoading);
  const [idValue, setIdValue] = useState("All");

  useEffect(() => {
    dispatch(dashboardActions.setRoute('Impressions'));
  }, []);

  useEffect(() => {
    // if (id) {
    //   dispatch(actions.setImpressionsQuery({ name: 'userId', value: postid }));
    // }
    if (postid) {
      dispatch(actions.setImpressionsQuery({ name: 'postId', value: postid }));
    }
  }, [postid]);

  useEffect(() => {
    dispatch(actions.getImpressions());
  }, [postid, userId]);

  const setQuery = (name, value) => {
    dispatch(actions.setImpressionsQuery({ name, value }));
  };

  const hasMoreItems = total !== items.length;

  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPageImpressions(''));
    }
  };

  const columns = [
    {
      id: '_id',
      label: 'ID',
      sortValue: 'selector._id',
      minWidth: 100,
      format: value => value._id || '-',
    },
    {
      id: 'userId',
      label: 'User ID',
      sortValue: 'selector.userId',
      minWidth: 100,
      format: value => `U${value.userId}` || '-',
    },
    {
      id: 'type',
      label: 'Type',
      sortValue: 'selector.type',
      minWidth: 100,
      format: value => value.type || '-',
    },
    {
      id: 'impressionableId',
      label: 'Type ID',
      sortValue: 'selector.impressionableId',
      minWidth: 100,
      format: value => value.impressionableId || '-',
    },
    {
      id: 'Created At',
      label: 'Created At',
      sortValue: 'selector.createdAt',
      minWidth: 100,
      format: value => getFormattedDateTime(value?.createdAt)
    },
  ];

  const handleChange = e => {
    const { name, value } = e.target;
    setIdValue(value);
    dispatch(actions.setImpressionsQuery({ name: 'userId', value: value === "All" ? "" : value }));
  };

  return (
    <Box sx={{ p: '10px' }}>
      <div className={styles.main}>
        <DropDownComponent
          width="15%"
          title="Filter by Log Level"
          name="userId"
          value={idValue}
          menuItems={[
            { label: 'All', value: 'All' },
            { label: 'User', value: id },
          ].map(filter => (
            <MenuItem value={filter.value} key={filter.value}>
              {filter.label}
            </MenuItem>
          ))}
          handleChange={handleChange}
        />
      </div>
      <div className={styles.root}>
        <InfiniteScroll
          loader={<TableLoader />}
          hasMore={hasMoreItems}
          loadMore={getNextPage}
          useWindow={false}
        >
          <DataTable
            loading={loading}
            columns={columns}
            rows={items}
            setQuery={setQuery}
            orderDirection={''}
            orderField={''}
          />
        </InfiniteScroll>
      </div>
    </Box>
  );
};

export default Impressions;
