import { SnackbarProp, SnackbarProps } from 'app/components/Snackbar';
import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import {
    defaultAvgTimeState, defaultCTAState, defaultMostViewedState, defaultWishlistState,
    InitialDashboardState, TagStates, TotalNumberOfUsersState
} from './types';

export const initialState: InitialDashboardState = {
  menuOpen: true,
  loading: false,
  error: '',
  currentRoute: '',
  snackbar: {
    open: false,
    message: '',
    variant: 'success',
  },
  totalNumberOfUsers: 0,
  graphs: {
    avgTimeStats: defaultAvgTimeState,
    ctaStats: [defaultCTAState],
    wishlistStats: [defaultWishlistState],
    mostViewedStats: [defaultMostViewedState],
    tagStats: [] as TagStates[]
  },
  search: '',
  list: {
    items: [],
  },
  query: {
    createMin: '',
    createMax: '',
    q: '',
    orderDirection: ''
  }
};

export const useDashboardSlice = createSlice({
  name: 'dashboardState',
  initialState,
  reducers: {
    toggleMenu: state => {
      state.menuOpen = !state.menuOpen;
    },
    openSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbar.open = true;
      state.snackbar.message = action.payload;
      state.snackbar.variant = 'error';
    },
    toggleSnackbar: (state, action: PayloadAction<SnackbarProp>) => {
      state.snackbar.open = action.payload.open || false;
      state.snackbar.message = action.payload.message || '';
      state.snackbar.variant = action.payload.variant || 'error';
    },
    closeSnackbar: state => {
      state.snackbar.open = false;
      state.snackbar.message = '';
    },
    getDashboardStats: state => {
      state.loading = true;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.query, name, value);
    },
    setTotalNumberOfUsers: (state, action: PayloadAction<TotalNumberOfUsersState>) => {
      state.totalNumberOfUsers = action.payload.totalUsers;
    },
    setDashboardGraph: (state, action: PayloadAction<{ key; value }>) => {
      const { key, value } = action.payload;
      set(state.graphs, key, value);
    },
    setRoute: (state = initialState, action) => {
      state.currentRoute = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    getPostList: state => {
      state.list = initialState.list;
      state.loading = true;
    },
    getPostListSuccess: (state, action) => {
      state.loading = false;
      state.list.items = action.payload.items;
    },
    getPostListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useDashboardSlice;
