import { makeStyles } from '@mui/styles';

export const useImportFormStyles = makeStyles({
  fileSelectButton: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.6vh',
    padding: '20px 15px',
    color: '#333333',
    borderColor: '#D5D8DE',
    backgroundColor: '#F7F7FA',
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
    borderRadius: '4px',
    borderStyle: 'dashed',
    marginTop: 16,
    '&:hover': {
      borderStyle: 'dashed',
      borderColor: '#D5D8DE',
      backgroundColor: '#F7F7FA',
    },
  },
  fileDetails: {
    marginTop: 16,
  },
  imageDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 16,
    // backgroundColor:'#aba'
  },
  uploadImageText: {
    textDecoration: 'underline',
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  imageSizeText: { fontSize: '10px', color: '#929CB9' },
});
