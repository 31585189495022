import { FormError } from 'app/containers/types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';

import { selectCategoryLoading, selectForm, selectOpenTagForm } from '../../selector';
import { actions } from '../../slice';
import { useStyles } from '../TagDetails/components/style';
import Form from './components/form';
import { validate } from './validator';

type Props = {};

const TagDetails = (props: Props) => {
  const { createTag } = useSelector(selectForm);
  const isEdit = createTag.id.value !== '';
  const open = useSelector(selectOpenTagForm);
  const dispatch = useDispatch();
  const styles = useStyles();
  const loading = useSelector(selectCategoryLoading);

  const toggleTagForm = () => {
    dispatch(actions.setOpenTagForm());
    dispatch(actions.resetForm());
  };

  useEffect(() => {
    console.log(`opened`, open);
  }, [open]);

  const onChange = e => {
    const { name, value } = e.target;

    dispatch(
      actions.setForm({ name, value: value ? value : '', form: 'createTag' }),
    );
  };

  const handleSubmit = e => {
    e?.preventDefault();
    const errors: Array<FormError> = validate(createTag);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors({ key: '', errors }));
      return;
    }
    if (!isEdit) {
      dispatch(actions.createTag(''));
    }
    if (isEdit) {
      dispatch(actions.updateTag(''));
    }
  };

  return (
    <Modal
      open={open}
      onClose={toggleTagForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.root}>
        <Typography className={styles.title}>{`${
          isEdit ? 'Edit' : 'Create New'
        } Tags`}</Typography>
        <IconButton
          onClick={toggleTagForm}
          sx={{ position: 'absolute', right: 10, top: 15 }}
        >
          <CloseIcon />
        </IconButton>
        <Form
          onCancel={toggleTagForm}
          onSubmit={handleSubmit}
          form={createTag}
          loading={loading}
          onChange={onChange}
        />
      </Box>
    </Modal>
  );
};
export default TagDetails;
