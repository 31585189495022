import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './types';

export const selectDomain = (state: RootState) =>
  state.pageState || initialState;

export const selectPageForm = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectPageLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
